import { Button, Col, Form, Modal, Row, Spinner, Table } from "react-bootstrap"
import { NumericInput } from "../../InputViews"
import { RState } from "../../functional/react/RState"
import { ElectricityBuyRequest, ElectricityBuyType, ElectricityBuyTypeSurplus } from "../../model/buyRequest/ElectricityBuyRequest"
import { Draft } from "../../model/utils"
import { Unit, none } from "functional/lib/core"
import { Lens } from "functional/lib/optics/Lens"
import { List } from "functional/lib/List"
import { MONTHS } from "../../Utilities"
import { Affine } from "functional/lib/optics/Affine"
import { Maybe, showIf } from "functional/lib/Maybe"
import firebase from "firebase"
import { matchEnum } from "functional/lib/match"
import { useAsyncFunction } from "../../client/client"
import ReactFileReader from 'react-file-reader'
import { StringChoiceEditor } from "../../components/editors/string"
import { CellCol, FlexCol, FlexRow } from "../../components/Flexbox"
import { FileData } from "../../model/common"
import { IO } from "functional/lib/IO"
import { Async } from "functional/lib/Async"
import { AppCall, useAppAsynchronism } from "../../context/AppCall"
import { Id } from "../../model/Model"
import { electricityFormFieldIds } from "./validation"
import { YearMonthEditor } from "../../components/editors/date"
import { NumberEditor } from "../../components/editors/number"
import { isValidFileType } from "../../client/file"


export const ElectricityProductForm = (
  props: {
    request: RState<Draft<ElectricityBuyRequest>>
  }
) => {

  const { buyType } = RState.destructure(props.request)("buyType")

  return <FlexCol
    alignItems="stretch"
  >
    <FlexRow justifyContent="center">
      <b>Tipo de Compra</b>
    </FlexRow>
    <FlexRow>
      <CellCol
        alignItems="flex-start"
        padding={8}
      >
        <Form.Label>Tipo de Compra</Form.Label>

        <StringChoiceEditor
          state={{
            value: buyType.value.type,
            apply: transform => {
              const value = transform(buyType.value.type)
              return buyType.apply(() =>
                matchEnum(value)<ElectricityBuyType>({
                  mater: { type: "mater" },
                  onsite: { type: "onsite" },
                  surplus: { type: "surplus", demandCurve: [], maxPercentageToHireCurve: [] },
                  thermal: { type: "thermal" },
                  plus: { type: "plus" }
                })
              )
            }
          }}
          options={[
            ["mater", "PPA Mater"],
            ["surplus", "PPA MATER Excedentes"],
            ["onsite", "PPA Solar FV Onsite"],
            ["thermal", "Térmico Onsite"],
            ["plus", "Energía Plus"],
          ]}
        />

      </CellCol>

      <CellCol
        weight={3}
        padding={8}
      />

    </FlexRow>
    <hr />
  </FlexCol>

}



export const ElectricityTotalAmount = (
  props: {
    request: RState<Draft<ElectricityBuyRequest>>
  }
) => {
  
  const fields = RState.destructure(props.request)(
    "buyType", 
    "totalAmount", 
    "supplyStartDate"
  )

  const startMonthIndex = fields.supplyStartDate?.value?.getMonth() ?? 4
  const startYear = fields.supplyStartDate?.value?.getFullYear() ?? 2020
  
  const { input, units } = RState.match(fields.buyType)({

    thermal: buyType => ({
      input: (
        <NumericInput 
          fieldId={electricityFormFieldIds.totalAmount}
          name={'Potencia comprometida de entrega'}
          placeholder={'MW'}
          state={fields.totalAmount}
        />
      ),
      units: 'MW'
    }),

    onsite: buyType => ({
      input: <NumericInput 
        fieldId={electricityFormFieldIds.totalAmount}
        name={'Potencia a contrar'} 
        placeholder={'MWac'}
        state={fields.totalAmount}
      />,
      units: 'MWac'
    }),

    mater: buyType => ({
      input: (
        <NumericInput 
          fieldId={electricityFormFieldIds.totalAmount}
          name={'Energía anual a contratar'} 
          placeholder={'MWh/año'} 
          state={fields.totalAmount}
        />
        ),
        units: 'MWh/año'
      }),

    surplus: buyType => ({
      input: <SupplyTypeEditor
        buyType={buyType}
        startMonthIndex={startMonthIndex}
        startYear={startYear}
      />,
      units: ""
    }),

    plus: buyType => ({
      input: (
        <NumberEditor 
          fieldId={electricityFormFieldIds.totalAmount}
          name={'Potencia a contratar'}
          placeholder={'MW'}
          state={fields.totalAmount}
          min={0}
          decimalPlaces={2}
        />
      ),
      units: "MW"
    }),

  })
  
  return <FlexCol
    alignItems="stretch"
  >
    <hr />

    <FlexRow
      justifyContent="center"
    >
      <b>Cantidad a Contratar</b>
    </FlexRow>
    <br />

    <FlexRow className='justify-content-center'>
      {!fields.buyType.value.type && <small className='text-warning'>Seleccioná un Tipo de Compra</small>}
    </FlexRow>

    <Row className='justify-content-center'>
      <Col xs={6}>
        {input}
      </Col>
      <Col xs={3}>
        {units}
      </Col>
    </Row>

    {
      RState.matchPartial(fields.buyType)({
        surplus: buyType => {

          const { demandCurve } = RState.destructure(buyType)('demandCurve')

          return <>
            <Row className='justify-content-center'>
              <Col xs={3} className={'text-center'}>
                <p className={(!buyType.value.demandCurve.length) ? 'text-danger' : none}>
                  Definir la cantidad de meses
                </p>
                <NumericInput
                  hideLabel={true}
                  name={''}
                  placeholder={''}
                  state={{
                    value: demandCurve.value.length,
                    apply: transform => 
                      demandCurve.apply(it => {
                        const newLength = transform(it.length) ?? 0
                        return [
                          ...it.slice(0, newLength) ?? [],
                          ...List.new(newLength - it.length)(() => none)
                        ]
                      })
                  }}
                />
              </Col>
              <Col xs={3} className={'text-center'}>
                <p 
                  className={(demandCurve.value.length > 0 && !fields.supplyStartDate?.value) ? 'text-danger' : none}
                >
                  Mes de Inicio de Suministro
                </p>
                <YearMonthEditor
                  fieldId={electricityFormFieldIds.supplyStartDate}
                  state={fields.supplyStartDate}
                />
              </Col>
            </Row>
            <br />
          </>
        }
      })
    }

  </FlexCol>
}




const SupplyTypeEditor = (
  props: {
    startMonthIndex: number
    startYear: number
    buyType: RState<Draft<ElectricityBuyTypeSurplus>>
  }
) => {

  const monthNames = followingMonthNames(props.startMonthIndex, props.startYear)

  const {
    demandCurve,
    maxPercentageToHireCurve
   } = RState.destructure(props.buyType)("demandCurve", "maxPercentageToHireCurve")

  return <>
    {props.buyType.value.demandCurve.length > 0 && 
      <Row className='justify-content-center'>
        <Col xs={3}>
        </Col>
        <Col xs={3} className={'text-center'}>
          <b>Demanda Proyectada (MWh/mes)</b>
        </Col>
        <Col xs={3} className={'text-center'}>
          <b>Porcentaje Máximo a Contratar (%)</b>
        </Col>
      </Row>
    }
    <br />
    {props.buyType.value.demandCurve.map((value, index) => 
      <Row className='justify-content-center' key={index}>
        <Col xs={3} className={'text-center'}>
          {monthNames[index]}
        </Col>
        <Col xs={3}>
          <NumericInput
            hideLabel={true}
            key={index}
            name={index.toString()}
            placeholder=' 00.00 Mwh/mes'
            state={RState.applyAffine(demandCurve, Affine.listIndex(index))}
          />
        </Col>
        <Col xs={3}>
          <NumericInput
            hideLabel={true}
            key={index}
            name={index.toString()}
            placeholder=' 00.00 %'
            state={RState.applyAffine(maxPercentageToHireCurve, Affine.listIndex(index))}
          />
        </Col>
      </Row>)
    }
  </>
}

const followingMonthNames = (
  startMonthIndex: number,
  startYear: number,
  count: number= 12
) => 
  List.new(count)(i => 
    [
      MONTHS[(startMonthIndex + i) % 12],
      startYear + Math.floor((startMonthIndex + i) / 12)
    ].join(" ")
  )



export const ElectricityFileLoader = (
  props: {
    request: RState<Draft<ElectricityBuyRequest>>
  }
) => {

  const { fileList } = RState.destructure(props.request)("fileList")

  const fields = Maybe.map(RState.nonNone(fileList))(RState.destructureAll)
/*
  const uploadBuyRequestFiles = async (
    args: {
      fileType: "documents" | "complimentaryInfo" | "offerTemplate"
      file: any,
    }
  ) => {
    // TODO: check file types
    const time = new Date()
    const uploadTask = 
      firebase
        .storage()
        .ref(`BuyRequestsFiles/Electricity/${props.request.value.buyerCompanyId}/${time.toString()}/${args.file[0].name}`)
        .put(args.file[0])

    uploadTask.on(
      'state_changed', 
      snapshot => {
        switch (snapshot.state) {
          case firebase.storage.TaskState.PAUSED: 'paused'// or 'paused'
            break
          case firebase.storage.TaskState.RUNNING: 'running'// or 'running'
            break
        }
      },
      error => {}, 
      async () => {
        const downloadURL = await uploadTask.snapshot.ref.getDownloadURL()

        const fileData = { link: downloadURL, name: args.file[0]?.name }

        matchEnum(args.fileType)({
          documents: fields?.documents?.apply(it => [...it, fileData]),
          complimentaryInfo: fields?.complimentaryInfo?.apply(it => [...it, fileData]),
          offerTemplate: fields?.offerTemplate?.apply(() => fileData)
        })?.()

      }
    )

    return await uploadTask
  }
*/
  const needsCompany = !props.request.value.buyerCompanyId
/*
  const [uploadStatus, callUpload] = useAsyncFunction(uploadBuyRequestFiles)

  const enableUploadButtons = !needsCompany && !uploadStatus.isLoading
*/
  return <FlexCol
    alignItems="stretch"
  >
    

    <FlexRow 
      justifyContent="center"  
    >
      <b>Archivos Asociados al Pedido de Compra</b>
    </FlexRow>



    {needsCompany &&
      <FlexRow 
        justifyContent="center"
      >
        <small className='text-warning'>
          Debes seleccionar una Empresa para cargar los Archivos
        </small>
      </FlexRow>
    }
    <br />

    <FileListEditor
      title='Documentación del Pedido'
      companyId={props.request.value.buyerCompanyId}
      description={
        <small>
          Cargá en esta sección los archivos que contengan la información necesaria para la preparación de las Ofertas. <br /> 
          Por ejemplo: termsheet, anexos técnicos, planos etc. <br />
          <br />
          Podés cargar la cantidad de archivos que consideres necesarios.<br />Se deben cargar de a uno.
        </small>
      }
      fileTypes={['.pdf', '.dwg', '.xls', '.xlsx']}
      state={fields?.documents}
    />

    <hr />

    <FileListEditor
      title='Información Complementaria'
      companyId={props.request.value.buyerCompanyId}
      description={
        <small>
          Cargá otros documentos que puedan ser de utilidad para el análisis del Pedido.
          <br />Por ejemplo: Presentaciones del proyecto o de la empresa compradora, información financiera de la empresa compradora etc.
          <br /><br />Podés cargar la cantidad de Archivos que consideres necesarios.<br />Se deben cargar de a uno.
        </small>
      }
      fileTypes={['.pdf', '.xls', '.xlsx', '.dwg', '.kmz']}
      state={fields?.complimentaryInfo}
    />

    <hr />

    <FileEditor
      title='Template de Oferta'
      companyId={props.request.value.buyerCompanyId}
      description={<small>Cargar el Template de Oferta <b>vacío</b></small>}
      detail={<small>El archivo debe ser <b>.xlsx</b></small>}
      fileTypes={['.xlsx']}
      state={fields?.offerTemplate}
    />

  </FlexCol>
}


export const FileListEditor = (
  props: {
    title: string
    companyId: Id
    description: React.ReactNode
    fileTypes: string[]
    state?: RState<List<FileData>>
  }
) => {

  const call = useAppAsynchronism(
    (args: {
      companyId: Id
      file: FileList
    }) =>
      AppCall.do(async _ => {
        const fileData = await uploadFile(args)()
        props.state?.apply(list => list.concat(fileData))?.()
      })
  )

  const enable = props.companyId !== "" && call.state.type !== "running"

  return <FlexRow
    padding={8}
  >

    {showIf(call.state.type === "running")(
      <Modal show={true} centered>
        <Modal.Body>
          <Row className='justify-content-center text-primary'>
            <Spinner animation="grow" variant="primary" /><h4>Cargando Archivo...</h4>
          </Row>
        </Modal.Body>
      </Modal>
    )}

    <CellCol
      gap={16}
    >
      <h6>{props.title}</h6>
      <ReactFileReader
        fileTypes={props.fileTypes}
        disabled={!enable}
        handleFiles={(e: FileList) => {
          call.run({
            companyId: props.companyId,
            file: e
          })()
        }}
      >
        <Button size='sm' disabled={!enable}>Subir Archivos</Button>
      </ReactFileReader>
      {/*<small className='text-secondary'>Los archivo deben ser <b>.pdf</b></small>*/}
    </CellCol>

    <CellCol
      alignItems="stretch"
      gap={8}
    >
      {props.description}

      <FlexCol
        alignItems="stretch"
        gap={8}
      >
        {(props.state?.value?.length ?? 0) > 0 && 
          props.state?.value?.map((file, index) => 
          <>
            {index > 0 && <hr key={`${index}_separator`} style={{ margin: 0 }}/>}
            <FlexRow 
              key={index}
              justifyContent="space-between"
            >
              <a target="_blank" href={file.link}>{file.name}</a>
              <Button 
                size='sm' 
                variant='outline-danger' 
                onClick={props.state?.apply(list => list.toSpliced(index, 1))}
              > 
                Borrar
              </Button>
            </FlexRow>
          </>
        )
        }
      </FlexCol>
    </CellCol>
  </FlexRow>
}


const FileEditor = (
  props: {
    title: string
    companyId: Id
    description: React.ReactNode
    detail?: React.ReactNode
    fileTypes: string[]
    state?: RState<Maybe<FileData>>
  }
) => {

  const call = useAppAsynchronism(
    (args: {
      companyId: Id
      file: FileList
    }) =>
      AppCall.do(async _ => {
        const fileData = await uploadFile(args)()
        props.state?.apply(() => fileData)?.()
      })
  )

  const enable = props.companyId !== "" && call.state.type !== "running"

  return <FlexRow
    padding={8}
  >

    {showIf(call.state.type === "running")(
      <Modal show={true} centered>
        <Modal.Body>
          <Row className='justify-content-center text-primary'>
            <Spinner animation="grow" variant="primary" /><h4>Cargando Archivo...</h4>
          </Row>
        </Modal.Body>
      </Modal>
    )}

    <CellCol
      gap={16}
    >
      <h6>{props.title}</h6>
      <ReactFileReader
        fileTypes={props.fileTypes}
        disabled={!enable}
        handleFiles={(e: FileList) => {
          call.run({
            companyId: props.companyId,
            file: e
          })()
        }}
      >
        <Button size='sm' disabled={!enable}>Subir Archivos</Button>
      </ReactFileReader>
      {props.detail}
    </CellCol>

    <CellCol
      alignItems="stretch"
      gap={8}
    >
      {props.description}

      <FlexCol
        alignItems="stretch"
        gap={8}
      >
        {Maybe.map(props.state?.value)(file =>  
            <FlexRow 
              justifyContent="space-between"
            >
              <a target="_blank" href={file.link}>{file.name}</a>
              <Button 
                size='sm' 
                variant='outline-danger' 
                onClick={props.state?.apply(() => none)}
              > 
                Borrar
              </Button>
            </FlexRow>
          )
        }
      </FlexCol>
    </CellCol>
  </FlexRow>
}



const uploadFile =
  (
    args: {
      companyId: string
      file: FileList,
    }
  ): Async<FileData> =>
  async () => {

    const file = args.file[0]

    if (!isValidFileType(file.type)) {
      console.error('Invalid file type')
      throw new Error('Invalid file type')
    }

    const fileName = args.file[0].name

    const time = new Date()
    const uploadTask = 
      firebase
        .storage()
        .ref(`BuyRequestsFiles/Electricity/${args.companyId}/${time.toString()}/${fileName}`)
        .put(args.file[0])

    uploadTask.on(
      'state_changed', 
      snapshot => {
        switch (snapshot.state) {
          case firebase.storage.TaskState.PAUSED: 'paused'// or 'paused'
            break
          case firebase.storage.TaskState.RUNNING: 'running'// or 'running'
            break
        }
      },
      error => {}
    )

    const result = await uploadTask

    const downloadURL = await result.ref.getDownloadURL()

    return {
      link: downloadURL,
      name: fileName
    }
  }