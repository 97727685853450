import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded'
import SaveIcon from '@material-ui/icons/Save'
import { Alert, Button, Card, Modal, Spinner } from "react-bootstrap"
import { useNavigate } from "react-router-dom"
import { useCurrentUser } from "../hooks/ConectoHooks"
import '../specialCSS.css'
import { BuyRequestCreateFormStep } from "./BuyRequestForm/BuyRequestCreateFormStep"
import { BuyRequestCreateTermsStep } from "./BuyRequestCreateTermsStep"
import { BuyRequestCreateProcessStep } from "./BuyRequestCreateProcessStep"
import { BuyRequestCreateConfirmStep } from "./BuyRequestCreateConfirmStep"
import { GasBuyRequest } from '../model/buyRequest/GasBuyRequest'
import { ElectricityBuyRequest } from '../model/buyRequest/ElectricityBuyRequest'
import { Unit, none, throws } from 'functional/lib/core'
import { Process } from '../model/Process'
import { buyRequestDraftSave } from '../client/buy-request'
import { RState, useRState } from '../functional/react/RState'
import { CellCol, FlexCol, FlexRow } from '../components/Flexbox'
import { Draft } from '../model/utils'
import { List } from 'functional/lib/List'
import { IO } from 'functional/lib/IO'
import { match, matchEnum } from 'functional/lib/match'
import { Maybe } from 'functional/lib/Maybe'
import { BuyRequest } from '../model/buyRequest/BuyRequest'
import { AppCall, appCallUserData, useAppAsynchronism } from '../context/AppCall'


type Step =
  | {
    type: "BuyRequestForm",
    request: Draft<BuyRequest>
    process?: Draft<Process>
  }
  | {
    type: "Terms",
    request: BuyRequest
    accepted?: boolean
    process?: Draft<Process>
  }
  | {
    type: "Process",
    request: BuyRequest
    process?: Draft<Process>
  }
  | {
    type: "Confirm",
    request: BuyRequest
    process: Process
  }

const stepBack = (step: Step): Step => 
  match(step)<Step>({
    BuyRequestForm: step => step,
    Terms: step => ({
      type: "BuyRequestForm",
      request: step.request,
      accepted: step.accepted,
      process: step.process,
    }),
    Process: step => ({
      type: "Terms",
      request: step.request,
      process: step.process,
      accepted: true
    }),
    Confirm: step => ({
      type: "Process",
      request: step.request,
      process: step.process,
      accepted: true
    })
  })

export const BuyRequestCreateFlow = (
  props: {
    draft: Maybe<Draft<BuyRequest>>
  }
) => {

  const nav = useNavigate()

  const draft = props.draft

  const state = useRState<Step>(() => ({
    type: "BuyRequestForm",
    request: draft ?? initialGasDraft()
  }))

  const back = state.apply(stepBack)

  const step = match(state.value)({

    BuyRequestForm: step =>
      <BuyRequestCreateFormStep
        initialRequest={step.request}
        // browser back
        onBack={() => nav(-1)}
        updateDraft={draft =>
          state.apply(it => ({
            ...it,
            type: "BuyRequestForm",
            request: draft,
          }))
        }
        onNext={request => 
          state.apply(() => ({
            type: "Terms",
            request: request,
            process: step.process
          }))
        }
      />,

    Terms: step =>
      <BuyRequestCreateTermsStep
        request={step.request}
        accepted={step.accepted}
        onBack={back}
        onNext={
          state.apply(() => ({
            type: "Process",
            request: step.request,
            accepted: true,
            process: step.process
          }))
        }
      />,

    Process: step =>
      <BuyRequestCreateProcessStep
        request={step.request}
        initialProcess={step.process ?? initialProcessDraft}
        onBack={back}
        onNext={process => 
          state.apply(() => ({
            type: "Confirm",
            request: step.request,
            process: process,
          }))
        }
      />,

    Confirm: step =>
      <BuyRequestCreateConfirmStep
        request={step.request}
        process={step.process}
        onBack={back}
      />

  })

  return <FlexCol
      alignItems='center'
      style={{
        padding: 16
      }}
  >
      <Card
        style={{
          maxWidth: 1400,
          width: "100%"
        }}
      >
        <Card.Header 
          as="h5" 
          className='text-center'
        >
          <FormHeader
            state={state}
          />
          <hr />
          <StepIndicator
            current={state.value.type}
          />
        </Card.Header>
        <Card.Body>
          {step}
        </Card.Body>
      </Card>
    </FlexCol>
}

const FormHeader = (
  props: {
    state: RState<Step>
  }
) => {


  const navigate = useNavigate()

  const request = props.state.value.request

  const step = props.state.value.type

  const save = useAppAsynchronism(
    (args: { request: Draft<BuyRequest> }) =>
      AppCall.do(async _ => {
        const user = await _(appCallUserData) ?? throws(`No user found`)
        await _(buyRequestDraftSave(args.request, user))
        navigate("/marketplace")
      })
  )

  return <FlexRow>
    <FlexRow
      style={{
        width: 0,
        flexGrow: 1
      }}
      justifyContent='flex-start'
    >
      {step == "BuyRequestForm" ? 
        <Button 
          variant='light' 
          size='sm' 
          onClick={() => navigate("/marketplace")}
        >
          <ArrowBackIosRoundedIcon fontSize='small' />Salir
        </Button> :
        <Button 
          variant="light" 
          size='sm' 
          onClick={props.state.apply(stepBack)}
        >
          <ArrowBackIosRoundedIcon fontSize='small' /> Volver
        </Button>
      }
      &nbsp;
      <Button 
        variant='outline-success' 
        size='sm' 
        onClick={save.run({ request: request })}
      >
        {
          save.state.type === "running" ?
            <Spinner 
              style={{ padding: 2 }}
              animation="border" 
              size="sm"
            /> :
            <SaveIcon fontSize='small' />
        }
        Guardar y Salir
      </Button>
    </FlexRow>
    <CellCol>
      Nuevo Pedido de Compra de {
        matchEnum(props.state.value.request.type)({
          gas: "Gas",
          electricity: "Energía Eléctrica"
        })
      }
    </CellCol>

    <CellCol/>

  </FlexRow>
}

const StepIndicator = (
  props: {
    current: Step["type"]
  }
) => {

  const Indicator = (
    props: {
      text: string
      index: number
      step: Step["type"]
      current: Step["type"]
    }
  ) => {

    const selected = props.current == props.step

    return <FlexCol
      style={{ 
        color: selected ? "black" : "gray",
        width: 0,
        flexGrow: 1
      }}
    >
      <h6>{`${props.index + 1} - ${props.text}`}</h6>
      <h5 style={{
        'width': '100%',
        'textAlign': 'center',
        'borderBottom': selected ? '5px solid #0070FF' : '3px solid #000',
        'lineHeight': '0.1em',
        'margin': '10px 0 20px'
      }}>
        {/*<span className={props.selected ? 'ciculoAzul' : 'ciculo'}>{props.number}</span>*/}
        {/*<Badge pill variant={'primary'} >{props.number}</Badge>*/}
      </h5>
      {/*<hr style={{ 'border': props.selected ? "3px solid #0070FF" : ''}} width={'110%'}/>*/}
    </FlexCol>
  }
    
  const steps: List<[Step["type"], string]> = [
    ["BuyRequestForm", "Parámetros del Pedido"],
    ["Terms", "Términos y Condiciones"],
    ["Process", "Definición Ronda 1"],
    ["Confirm", "Revisión Final"]
  ]

  return <FlexRow
    gap={8}
    alignItems='flex-end'
  >
    {
      steps.map(([step, text], i) =>
        <Indicator
          index={i}
          text={text}
          step={step}
          current={props.current}
        />
      )
    }
  </FlexRow>
}



export const initialGasDraft: IO<Draft<GasBuyRequest>> = 
  () => ({
    type: 'gas',
    id: "",
    acceptsLessVolume: false,
    buyType: {
      type: "annual",
      demandCurve: List.new(12)(() => 0),
      cmdCurve: List.new(12)(() => 0),
    },
    maximumDailyAmount: 0,
    feeType: "seller",
    buyerCompanyId: "",
    takeOrPay: 0,
    deliverOrPay: 0,
    creationDate: none,
    supplyEndDate: none,
    exchangeRate: "BNA billete",
    supplyPointId: "",
    companyImageUrl: none,
    mix: none,
    placerId: none,
    product: "wellhead",
    publicKey: "",
    quality: 9300,
    supplyStartDate: new Date(),
    supplyType: "firm",
    exclusivity: false,
    
    status: {
      type: "pending"
    },
    currentGuestIdList: [],
    discount: none,
    referencePayTermDays: none,
    fileList: [],
    // user: { firstName: null },
  })

export const initialElectricityDraft: Draft<ElectricityBuyRequest> = {
  type: 'electricity',
  id: none,
  buyType: {
    type: "mater"
  },
  feeType: "seller",
  buyerCompanyId: "",
  creationDate: none,
  fileList: {
    anex: [],
    documents: [],
    complimentaryInfo: [],
    offerTemplate: none
  },
  placerId: none,
  publicKey: "",
  status: {
    type: "pending"
  },
  currentGuestIdList: [],
  discount: none,
  // user: { firstName: null },
  supplyPointId: "",
  totalAmount: none,
}

export const initialProcessDraft: Draft<Process> = {
  id: "",
  creationDate: none,
  openDate: none,
  closeDate: none,
  encrypted: true,
  acceptsDifferentPayTerm: false,
  message: none,
  minimumPayTermDays: none,
  guestIdList: [],
  round: 1,
  type: "tender",
  definitionTermDays: 7,
  comment: "",
  currentBestPrice: none,
  maximumPrice: none,
  defineMaxPrice: false,
  defineMinPayTerm: false,
  validTermDays: 7,
  acceptsFileUpload: true

}
