import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'react-bootstrap';
import { useNavigate } from "react-router";


export function Footer() {

    const navigate = useNavigate()

    return (
            <footer className="py-11 bg-primary position-relative">
                <div className="shape-1" style={{height: '150px', overflow: 'hidden'}}>
                <svg viewBox="0 0 500 150" preserveAspectRatio="none" style={{height: '100%', width: '100%'}}>
                    <path d="M0.00,49.98 C150.00,150.00 271.49,-50.00 500.00,49.98 L500.00,0.00 L0.00,0.00 Z" style={{stroke: 'none', fill: '#fff'}} />
                </svg>
                </div>
                <Container  className=" mt-11">
                <Row>
                    <div className="col-6 col-lg-5 col-xl-5 mr-auto mb-12 mb-lg-0">
                        <Row className="mt-5" >
                            <Link className="footer-logo text-white h2 mb-0" to="/">
                                <img src={require(`../assets/images/hero/logoBlanco.png`)} className="img-fluid" alt="..." />
                            </Link>
                        </Row>
                        <Row className='justify-content-center'>
                            <ul className="list-inline mb-0">
                                <li className="list-inline-item"><a className="text-light ic-2x" target="_blank" href="https://www.linkedin.com/company/conecto-energia/"><i className="la la-linkedin" /></a>
                                </li>
                                <li className="list-inline-item"><a className="text-light ic-2x" target="_blank" href="https://wa.me/+5491140718867"><i className="la la-whatsapp" /></a>
                                </li>
                                <li className="list-inline-item"><a className="text-light ic-2x" target="_blank" href="mailto:info@conectoenergia.com"><i className="la la-envelope" /></a>
                                </li>
                                <li className="list-inline-item"><a className="text-light ic-2x" target="_blank" href="tel:+541140718867"><i className="la la-phone" /></a>
                                </li>
                            </ul>
                        </Row>
                    </div>
                    <div className="col-12 col-lg-6 col-xl-7 mr-auto mb-12 mb-lg-0 text-right">
                    <Row>
                        <Col className="col-12 col-sm-4 mt-6 mt-sm-0 navbar-dark">
                            <h5 className="mb-4 text-white">Legal</h5>
                            <ul className="navbar-nav list-unstyled mb-0">
                                <li className="nav-item"><Link className="nav-link" to="/terminos-y-condiciones">Términos y Condiciones</Link>
                                </li>
                            </ul>
                        </Col>
                        <Col className="col-12 col-sm-4 navbar-dark">
                        <h5 className="mb-4 text-white">Páginas</h5>
                        <ul className="navbar-nav list-unstyled mb-0">
                            <li className="mb-3 nav-item"><Link className="nav-link" to="/compradores">Compradores</Link>
                            </li>
                            <li className="mb-3 nav-item"><Link className="nav-link" to="/vendedores">Vendedores</Link>
                            </li>
                            <li className="mb-3 nav-item"><Link className="nav-link" to="/consultores">Consultores</Link>
                            </li>
                        </ul>
                        </Col>
                        <Col className="col-12 col-sm-4 navbar-dark">
                            <h5 className="mb-4 text-white">Tu Cuenta</h5>
                            <ul className="navbar-nav list-unstyled mb-0">
                                <li className="mb-3 nav-item"><Link className="nav-link" onClick={()=> navigate('/login')}>Iniciar sesión</Link>
                                </li>
                                <li className="mb-3 nav-item"><Link className="nav-link" onClick={()=> navigate('/form')}>Crear cuenta</Link>
                                </li>
                            </ul>
                        </Col>
                    </Row>
                    </div>
                </Row>
                </Container>
            </footer>
        );

}

