import { IO } from "functional/lib/IO"
import { Validated, Validator } from "../functional/react/form/Validated"
import { Offer } from "../model/Bid"
import { Process } from "../model/Process"
import { Draft } from "../model/utils"
import { RState, useRState } from "../functional/react/RState"
import { useAppAsynchronism } from "../context/AppCall"
import { bidCreate } from "../client/bid"
import { Maybe, showIf } from "functional/lib/Maybe"
import { useNow } from "../utils/interval"
import { Accordion, Button, Card, Col, Collapse, Form, Row, Spinner, Table } from "react-bootstrap"
import { FlexCol, FlexRow } from "../components/Flexbox"
import { IconButton } from "@material-ui/core"
import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined"
import UnfoldLessOutlinedIcon from "@material-ui/icons/UnfoldLessOutlined"
import { FormReactContext } from "../functional/react/form/FormContext"
import Countdown from "react-countdown"
import { matchPartial } from "functional/lib/match"
import { countDownRenderer } from "./BidForm"
import { Record } from "functional/lib/Record"
import { BuyRequest } from "../model/buyRequest/BuyRequest"
import { useFormState } from "../functional/react/form/state"
import { Asynchronism } from "../functional/react/Asynchronism"
import { Unit } from "functional/lib/core"
import { FormErrors } from "../functional/react/form/FormErrors"
import { FormErrorsViewer } from "../components/Form"
import { BidSuccess } from "./BidComponents"
import ExpandMore from '@material-ui/icons/ExpandMore'
import ExpandLess from '@material-ui/icons/ExpandLess'

const V = Validator


export const bidFormIds = {
  tac: "tac",
  acceptedCommission: "acceptedCommission"
}

export const useBidFormFeatures = <OfferType extends Offer,>(
  args: {
    buyRequest: BuyRequest
    process: Process
    validator: Validator<Draft<OfferType>, OfferType>
    initialDraft: IO<Draft<OfferType>>
  }
) => {

  const call = useAppAsynchronism(bidCreate)

  const form = useFormState<{
    offer: Draft<OfferType>
    acceptedTac: boolean
    acceptedCommission: boolean
  }, {
    offer: OfferType
    acceptedTac: boolean
    acceptedCommission: boolean
  }>({

    validator: V.record({
      offer: args.validator,
      acceptedTac: V.equals(true, bidFormIds.tac, "Debes aceptar los Términos y Condiciones"),
      acceptedCommission: V.equals(true, bidFormIds.acceptedCommission, "Debes aceptar la comisión")
    }),

    initialDraft: () => ({
      acceptedTac: false,
      acceptedCommission: false,
      offer: args.initialDraft()
    })

  })

  const onSubmit = Maybe.do(_ => 
    call.run({
      product: args.buyRequest.type,
      buyRequestId: args.buyRequest.id ?? "",
      offer: _(form.validated?.offer)
    })
  ) ?? form.showErrorsState.apply(() => true)

  const {
    offer: draft,
    acceptedTac,
    acceptedCommission
  } = RState.destructureAll(form.state)

  const enableOffer =
    call.state.type !== 'running' &&
    call.state.type !== "success"

  const showBidForm = useRState(() => true)
  
  const now = useNow()

  const closed = args.process.closeDate < now

  return {
    form,
    draft,
    acceptedTac,
    acceptedCommission,
    onSubmit,
    enableOffer,
    showBidForm,
    closed,
    call
  }

}

export const GenericBidForm = <
 OfferType extends Offer,
>(
  props: {
    buyRequest: BuyRequest
    process: Process

    validator: (draft: Draft<OfferType>) => Validated<OfferType>
    initialDraft: IO<Draft<OfferType>>

    Form: React.ComponentType<{
      buyRequest: BuyRequest
      process: Process
      draft: RState<Draft<OfferType>>
      acceptedTaC: RState<boolean>
      acceptedCommission: RState<boolean>
    }>
  }
) => {

  const {
    form,
    draft,
    acceptedTac,
    acceptedCommission,
    onSubmit,
    enableOffer,
    showBidForm,
    closed,
    call
  } = useBidFormFeatures({
    buyRequest: props.buyRequest,
    process: props.process,
    validator: props.validator,
    initialDraft: props.initialDraft
  })

  if (call.state.type === "success") {
    return <BidSuccess
      onNewOffer={
        IO.sequence([
          acceptedTac.apply(() => false),
          form.showErrorsState.apply(() => false),
          call.reset
        ]) 
      }
    />
  }

  const CollapseIcon =
    showBidForm.value ? ExpandLess : ExpandMore

  return <Card className='shadow'>
    <Card.Header>
      <FlexRow 
        justifyContent='space-between'
        alignItems='center'
      >
        <div
          style={{
            width: 48
          }}
        />
        <FlexCol
          style={{ alignSelf: "center" }}
        >
          Ingresá tu Oferta
        </FlexCol>
        <IconButton
          style={{
            width: 48
          }}
          onClick={showBidForm.apply(it => !it)}
        >
          <CollapseIcon/>
        </IconButton>
        
      </FlexRow>
    </Card.Header>
    <Accordion.Collapse
      eventKey="0"
      in={showBidForm.value}
    >
      <FlexCol
        alignItems="stretch"
        padding={16}
      >
        <FormReactContext.Provider 
          value={form}
        >
          <props.Form
            buyRequest={props.buyRequest}
            process={props.process}
            draft={draft}
            acceptedTaC={acceptedTac}
            acceptedCommission={acceptedCommission}
          />
        </FormReactContext.Provider>
        <br />
        <Row className='justify-content-center'>
          {
            <small 
              className={
                !form.isValid ? 'text-secondary' : 
                'text-success'
              }>
              {!form.isValid ? 'Tu oferta está incompleta' : 'Oferta completa. ¡Podés enviarla!'}
            </small>
          }
        </Row>
        <br />
        {/* <Row className='justify-content-center'>
          {props.buyRequest.feeType == 'none' &&
            <p className='text-secondary'>Este pedido no tiene comisión para el vendedor.</p>}
          {(props.buyRequest.feeType == 'seller' || props.buyRequest.feeType == 'split' || props.buyRequest.feeType == 'buyer') &&
            <h6 className='text-secondary'>Comisión al Vendedor adjudicado: 10.000 USD</h6>}
        </Row> */}

        <BidFormFooter
          process={props.process}
          call={call}
          enableOffer={enableOffer}
          errors={form.errors}
          onSubmit={onSubmit}
          showErrors={form.showErrors}
        />

      </FlexCol>
    </Accordion.Collapse>
  </Card>
}

export const BidFormFooter = (
  props: {
    process: Process
    enableOffer: boolean
    onSubmit: IO<Unit>
    showErrors: boolean
    errors: FormErrors
    call: Asynchronism<any, any>
  }
) => {

  const now = useNow()

  const closed = props.process.closeDate < now

  return <FlexCol>
    
    <BidCountdown
      process={props.process}
    />

    {matchPartial(props.call.state)({
      running: () => <p style={{ color: "blue" }}>Enviando Oferta</p>,
      success: () => <p style={{ color: "green" }}>Oferta enviada con Éxito</p>,
      error: () => <p style={{ color: "red" }}>Error al enviar oferta</p>
    })}

    {
      showIf(!closed)(
        <Button
          style={{
            margin: 16
          }}
          disabled={!props.enableOffer}
          variant={props.enableOffer ? 'primary' : 'outline-primary'}
          onClick={props.onSubmit}
        >
          Enviar Oferta
        </Button>
      )
    }
    {
      showIf(props.showErrors)(
        <FormErrorsViewer
          style={{
            width: "100%"
          }}
          form={{
            isValid: FormErrors.isEmpty(props.errors),
            errors: props.errors,
            showErrors: props.showErrors
          }}
        />
      )
    }
  </FlexCol>
}

export const BidCountdown = (props: { process: Process }) => {

  const now = useNow()

  const closed = props.process.closeDate < now

  return <Row className='justify-content-center'>
    <h4 className='text-danger'>
      {
        closed ? 
          "La Ronda Ha Cerrado":
          <Countdown 
            date={props.process.closeDate}
            renderer={countDownRenderer} 
          />
      }
    </h4>
  </Row>
}