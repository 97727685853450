import { StaticDecode, Type } from "@sinclair/typebox"
import { FileDataT } from "../common"
import { ListT, MaybeT, OptionalTypeTag } from "../utils"
import { BuyRequestBaseFields } from "./BuyRequestBase"
import { ExchangeRateT } from "./GasBuyRequest"


export const ElectricityBuyTypeSurplusT = Type.Object({
  type: Type.Literal("surplus"),
  demandCurve: ListT(Type.Number()),
  maxPercentageToHireCurve: ListT(Type.Number())
})
export type ElectricityBuyTypeSurplus = StaticDecode<typeof ElectricityBuyTypeSurplusT>

export const ElectricityBuyTypeT = Type.Union([
  Type.Object({ type: Type.Literal("thermal") }),
  Type.Object({ type: Type.Literal("mater") }),
  Type.Object({ type: Type.Literal("onsite") }),
  Type.Object({ type: Type.Literal("plus") }),
  ElectricityBuyTypeSurplusT
])
export type ElectricityBuyType = StaticDecode<typeof ElectricityBuyTypeT>

export const BuyRequestFileListT = Type.Object({
  anex: Type.Optional(ListT(FileDataT)),
  documents: ListT(FileDataT),
  complimentaryInfo: ListT(FileDataT),
  offerTemplate: MaybeT(FileDataT),
  termsAndConditions: Type.Optional(FileDataT)
})
export type BuyRequestFileList = StaticDecode<typeof BuyRequestFileListT>

export const ElectricityBuyRequestT = OptionalTypeTag("electricity", {

  ...BuyRequestBaseFields,

  discount: Type.Optional(
    Type.Union([
      Type.Null(),
      Type.Number()
    ])
  ),

  fileList: Type.Optional(BuyRequestFileListT),

  authorizedProductList: Type.Optional(
    ListT(Type.String())
  ),

  totalAmount: Type.Optional(Type.Number()),

  buyType: ElectricityBuyTypeT,

  acceptedCAList: Type.Optional(ListT(Type.String())),

  stopConsults: Type.Optional(Type.Boolean()),

  product: Type.Optional(
    Type.Union([
      Type.Literal("electricity"),
      Type.Literal("mater")
    ])
  ),

  requireCA: Type.Optional(Type.Boolean()),

  exchangeRate: Type.Optional(ExchangeRateT),

  productType: Type.Optional(Type.String())//Type.Optional(Type.Literal("electricity")),

})
export type ElectricityBuyRequest = StaticDecode<typeof ElectricityBuyRequestT>

