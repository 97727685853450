import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline"
import AttachFileIcon from "@material-ui/icons/AttachFile"
import React, { useState } from "react"
import { Collapse, Row, Table } from "react-bootstrap"
import { useBidsList, useCompany, useSellerBidsList } from "../hooks/ConectoHooks"
import { BooleanCheckEditor } from "../components/editors/boolean"
import { dateToBidFormat, validaDateForBid, validaDateForCheck } from "../Utilities"
import { Bid, DecryptedBid, ThermalOffer } from "../model/Bid"
import { Id } from "../model/Model"
import { ElectricityBuyRequest } from "../model/buyRequest/ElectricityBuyRequest"
import { none } from "functional/lib/core"
import { List } from "functional/lib/List"
import { Process } from "../model/Process"
import { RState } from "../functional/react/RState"
import { Maybe } from "functional/lib/Maybe"
import { useAppUser } from "../context/App"

export const ThermalBidTable = (
  props: {

    buyRequest: ElectricityBuyRequest
    process: Process

    assignable: boolean
    // preAssignable: boolean

    selectedBidId?: RState<Maybe<Id>>
    // selectedToPreAssign?: RState<List<Id>>
  }
) => {

  const user = useAppUser()

  const isBuyer = user?.roleList?.includes('buyer') ?? false
  const isSeller = user?.roleList?.includes('seller') ?? false

  const [bidList, loading, error] = 
    (user?.roleList?.includes('buyer')) ? 
      useBidsList(
        'electricity', 
        props.buyRequest.id ?? "", 
        props.process.id ?? ""
      ) : 
      useSellerBidsList(
        'electricity', 
        props.buyRequest.id ?? "", 
        props.process.id ?? "", 
        user?.training ?? false, 
        user?.companyIdList?.[0] ?? ""
      )
  const bidTable = bidList ? bidList.map(bid =>
    <ThermalBidRow
      key={bid.id}
      buyRequest={props.buyRequest}
      process={props.process}
      bid={bid}

      assignable={props.assignable}
      assigned={bid.id === props.selectedBidId?.value}
      setAssigned={() => props.selectedBidId?.apply(() => bid.id)?.()}
      isBuyer={isBuyer}

      // preAssignable={props.preAssignable}
      // selectedToPreAssign={props.selectedToPreAssign}
    />) : null

  return <><Table className='text text-secondary text-center'>
    <thead>
      <tr>
        {isBuyer && <th align="center">Oferente</th>}
        <th align="center">Precio Mensual por Potencia</th>
        <th align="center">Precio Variable por Energía</th>
        <th align="center">Plazo de Contrato</th>
        <th align="center">Plazo de Inicio de Suministro</th>
        <th align="center">Válida hasta</th>
        <th align="center">Archivos</th>
        <th align="center">Hora</th>
        {props.assignable ? <th align="center" className='text-success'>Seleccionar</th> : null}
        {/* {props.preAssignable ? <th align="center" className='text-info'>Seleccionar</th> : null} */}
      </tr>
    </thead>
    {error && <strong>Error: {JSON.stringify(error)}</strong>}
    {loading && <tbody><tr><td>Cargando...</td></tr></tbody>}
    {bidList && <tbody>{bidTable}</tbody>}
  </Table>
    {user?.roleList?.includes('seller') && bidList && bidList.length == 0 && <Row><small>Tus Ofertas enviadas recién estarán visibles cuando la Ronda sea desencriptada</small></Row>}
    {user?.roleList?.includes('buyer') && bidList && bidList.length == 0 && <Row><small>Por el momento esta Ronda no tiene Ofertas</small></Row>}
  </>
}

const ThermalBidRow = (
  props: {
    buyRequest: ElectricityBuyRequest
    process: Process
    bid: Bid

    assignable: boolean
    assigned: boolean
    setAssigned: (value: boolean) => void

    isBuyer: boolean

    // preAssignable: boolean
    // selectedToPreAssign?: RState<List<Id>>
  }
) => {
  const bid = props.bid

  const [open, setOpen] = useState(false)
  const encryptedOffer = bid.encrypted && props.process.type === "tender"
  const decryptedBid = bid as DecryptedBid
  const offer = encryptedOffer ? null : decryptedBid.offer as ThermalOffer
  const encryptedText = "Encriptado"

  const [company, loading, error] = useCompany(bid.encrypted ? none : decryptedBid.sellerCompanyId)


  const orderedDocuments = 
    offer?.decryptedPdfFiles?.toSorted(
      (a, b) =>
        a.name > b.name ?  1 :
        a.name < b.name ? -1 : 
        0
    )

  return <>
    <tr className='text-center' key={bid.id}>
      {props.isBuyer && <td>{bid.encrypted ? encryptedText : company ? company.displayName : ""}</td>}
      <td>{encryptedOffer ? encryptedText : offer?.fixedCost?.toFixed(2)}</td>
      <td>{encryptedOffer ? encryptedText : offer?.variableCost?.toFixed(2)}</td>
      <td>{encryptedOffer ? encryptedText : offer?.term}</td>
      <td>{encryptedOffer ? encryptedText : offer?.startSupplyDays}</td>
      <td className={!bid.encrypted && validaDateForCheck(props.process.closeDate, offer?.validTermDays) ? 'text-danger' : none}>
        {encryptedOffer ? encryptedText : validaDateForBid(props.process.closeDate, offer?.validTermDays)}
      </td>
      <td>{encryptedOffer ? <AttachFileIcon className='text-secondary' /> : <a className='text-secondary'><AttachFileIcon fontSize='small' /><AddCircleOutlineIcon onClick={() => setOpen(!open)} /></a>}</td>
      <td>{dateToBidFormat(bid.date)}</td>
      {props.assignable ? <td>
        <BooleanCheckEditor
          type="checkbox"
          disabled={validaDateForCheck(props.process.closeDate, offer?.validTermDays)}
          value={props.assigned}
          onValueChanged={value => props.setAssigned(value)}
        /></td> : null}
      {/* {props.preAssignable ? <td>
        <BooleanCheckEditor
          type="checkbox"
          value={props.selectedToPreAssign?.value?.includes(bid.id) ?? false}
          disabled={validaDateForCheck(props.process.closeDate, offer?.validTermDays)}
          onValueChanged={(value) => {

            props.selectedToPreAssign?.apply(
              previous =>
                previous.includes(bid.id) ?
                  previous.filter(bidId => bidId !== bid.id) :
                  [...previous, bid.id]
            )?.()

          }}

        /></td> : null} */}
    </tr>
    <Collapse in={open}>
      <tr /* align='center' */>
        <td colSpan={6}>
          <Table size='sm' bordered>
            <tbody>
              {offer?.decryptedXls && <tr>
                <th>Template de Oferta</th>
                <th><a target="_blank" href={offer.decryptedXls.link}>{offer.decryptedXls.name}</a></th>
              </tr>}
              {orderedDocuments && <tr>
                <th>PDF's</th>
                {/*<th>{offer.decryptedPdfFiles.map(file => { return <a target="_blank" href={file.link}>{file.name}</a>})}</th>*/}
                <th>{orderedDocuments.map((file, position) => { return <tr key={position}><a target="_blank" href={file.link}>{file.name}</a></tr> })}</th>
              </tr>
              }
            </tbody>
          </Table>
        </td>
      </tr>
    </Collapse>
  </>
}