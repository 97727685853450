import bsCustomFileInput from 'bs-custom-file-input'
import InfoIcon from '@material-ui/icons/InfoOutlined'
import { IO } from "functional/lib/IO"
import { List } from "functional/lib/List"
import { Maybe, showIf } from "functional/lib/Maybe"
import { Unit, none } from "functional/lib/core"
import { matchPartial } from "functional/lib/match"
import { useEffect } from "react"
import { Button, Col, Form, Row, Spinner, Table, Alert, Accordion } from "react-bootstrap"
import ReactFileReader from 'react-file-reader'
import { NumericInput } from "../../InputViews"
import { BooleanCheckEditor } from "../../components/editors/boolean"
import { buildPathForStorage } from "../../Utilities"
import { fileEncryptAndUpload } from "../../client/file"
import { AppCall, appCallTraining, useAppAsynchronism } from "../../context/AppCall"
import { RState, useRState } from "../../functional/react/RState"
import { MaterOffer } from "../../model/Bid"
import { Id } from "../../model/Model"
import { Process } from "../../model/Process"
import { ElectricityBuyRequest } from "../../model/buyRequest/ElectricityBuyRequest"
import { FileData } from "../../model/common"
import { Draft } from "../../model/utils"
import { DateEditor, NumericVariable } from "../BidComponents"
import { FlexCol, FlexRow } from "../../components/Flexbox"
import { Validator } from "../../functional/react/form/Validated"
import { GenericBidForm, bidFormIds } from "../GenericBidForm"
import { BuyRequest } from '../../model/buyRequest/BuyRequest'
import { YearMonthEditor } from '../../components/editors/date'
import { electricityFormFieldIds } from '../../buy-request-flow/BuyRequestForm/validation'
import { useFieldErrors } from '../../functional/react/form/FormContext'
import { colors } from '@material-ui/core'


const V = Validator

const formIds = {
  variableCost: "variableCost",
  term: "term",
  validTermDays: "validTermDays",
  startSupplyMonths: "startSupplyMonths",
  startSupplyDate: "startSupplyDate",
  xls: "xls",
  pdf: "pdf",
  tac: "tac",
}

export const MaterBidForm = (
  props: {
    buyRequest: ElectricityBuyRequest
    process: Process
  }
) => 
  <GenericBidForm<MaterOffer>
    buyRequest={props.buyRequest}
    process={props.process}
    validator={offerValidator}
    initialDraft={
      () => ({
        type: "mater",
        decryptedXls: none,
        validTermDays: props.process.validTermDays,
        variableCost: none,
        term: none,
        startSupplyDate: none,
        encryptedPdfFiles: [],
        encryptedXls: none,
        decryptedPdfFiles: [],
      })
    }
    Form={MaterBidEditor}
  />



const offerValidator: Validator<Draft<MaterOffer>, MaterOffer> =
  V.record<Draft<MaterOffer>, MaterOffer>({

    variableCost: V.compose(
      V.notNone(formIds.variableCost, "El precio de energía es obligatorio"),
      V.predicate(it => it > 0, formIds.variableCost, "El precio de energía debe ser mayor a 0")
    ),

    term: V.compose(
      V.notNone(formIds.term, "El plazo de contrato es obligatorio"),
      V.predicate(it => it > 0, formIds.term, "El plazo de contrato debe ser mayor a 0")
    ),

    validTermDays: V.compose(
      V.predicate(it => it === none || it > 0, formIds.validTermDays, "El plazo de validez de oferta debe ser mayor a 0")
    ),

    startSupplyMonths: Validator.compose(
      V.notNone(formIds.startSupplyMonths, "El plazo de inicio de suministro es obligatorio"),
      V.predicate(it => it > 0, formIds.startSupplyMonths, "El plazo de inicio de suministro debe ser mayor a 0")
    ),

    startSupplyDate: V.notNone(formIds.startSupplyDate, "La fecha de inicio de suministro es obligatoria"),

    encryptedXls: V.notNone(formIds.xls, "El Template de Oferta es obligatorio"),
  })




const MaterBidEditor = (
  props: {
    buyRequest: BuyRequest
    process: Process
    draft: RState<Draft<MaterOffer>>
    acceptedTaC: RState<boolean>
    acceptedCommission: RState<boolean>
  }
) => {

  useEffect(() => {
    bsCustomFileInput.init()
  }, [])

  const acceptedValidDays = useRState(() => true)

  const fields = RState.destructureAll(props.draft)

  return <FlexCol
    alignItems="stretch"
  >

    <p><b>Precio</b></p>
    <NumericVariable
      fieldId={formIds.variableCost}
      title={'Precio de Energía'}
      placeholder={"0.00 US$/MWh"}
      units={"US$/MWh"}
      min={0}
      max={props.process.maxVariableCost ? props.process.maxVariableCost : 100}
      step={0.01}
      decimalPlaces={2}
      state={fields.variableCost}
    />
    <hr />
    <p><b>Plazo</b></p>
    <NumericVariable
      fieldId={formIds.term}
      title={'Plazo de contrato'}
      placeholder={"Años"}
      units={"Años"}
      min={0}
      max={30}
      step={1}
      decimalPlaces={2}
      state={fields.term}
    />
    <br />
    <Row>
      <Col xs={5}>
        <Form.Label>Fecha de Inicio de Suministro</Form.Label>
      </Col>
      <Col>
        <YearMonthEditor
          fieldId={electricityFormFieldIds.startSupplyDate}
          state={fields.startSupplyDate}
        />
      </Col>
      <Col xs={3}>
      </Col>
    </Row>
    
    {/*<Row className='justify-content-center'>*/}
    {/*    <Col>*/}
    {/*        */}
    {/*        /!*<YearMonthPicker*!/*/}
    {/*        /!*    valueDate={draft.startSupplyMonth}*!/*/}
    {/*        /!*    setDate={value => {modifyDraft({startSupplyMonth: value})}}/>*!/*/}
    {/*    </Col>*/}
    {/*    <Col xs={3}/>*/}
    {/*</Row>*/}
    <hr />
    <p><b>Oferta</b></p>
    <SectionUploadXls
      fieldId={formIds.xls}
      state={fields.encryptedXls}
      buyRequestId={props.buyRequest.id ?? ""}
    />
    <br />
    <hr />
    <p><b>Archivos</b></p>
    <SectionUploadPdfs
      state={fields.encryptedPdfFiles}
      buyRequestId={props.buyRequest.id ?? ""}
    />
    <hr />
    <FlexCol 
    alignItems='stretch'
    gap={6}
    >

    <Accordion defaultActiveKey="1">
      <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <Alert variant="info">
          <Accordion.Header>
              <InfoIcon/> &nbsp; Comisión a pagar a Conecto Energía por el Vendedor Adjudicado: <b>&nbsp; 10.000 USD </b>
          </Accordion.Header>
          <Accordion.Body>
            Mediante el consentimiento a estos Términos y Condiciones Particulares y
            conjuntamente con el envío de su oferta, la Empresa interesada que resulte 
            adjudicada y haya celebrado un contrato de abastecimiento con el Comprador, 
            acepta abonar al Sitio una comisión de <b>USD 10.000</b> (dólares estadounidenses diez mil) 
            en concepto de uso del Sitio la cual será detallada al momento en que la Empresa 
            interesada envíe su oferta. Conecto Energía facturará dicha comisión la momento 
            en que el Comprador y Vendedor suscriban el contrato de abastecimiento, 
            en pesos al tipo de cambio vendedor Banco Nación correspondiente al día 
            anterior a la fecha de emisión de la factura. Dicha factura deberá ser 
            abonada dentro de los 30 días hábiles de recibida la misma.
          </Accordion.Body>
        </Alert>
        </div>
    </Accordion>

      <BooleanCheckEditor
        label={`Acepto el plazo de validez de Oferta de ${props.process.validTermDays} días desde la fecha y hora de cierre de esta Ronda.`}
        state={{
          value: acceptedValidDays.value,
          apply: transform => 
            () => {
              const newValue = transform((fields.validTermDays?.value !== none) ?? false)
              if (newValue) {
                fields.validTermDays?.apply(() => props.process.validTermDays)?.()
              }
              acceptedValidDays.apply(it => !it)()
            }
        }}
      />
      <Form.Check
        type={'checkbox'}
        label={'Modificar el plazo de validez de Oferta'}
        disabled={false}
        checked={!acceptedValidDays.value}
        onChange={event => acceptedValidDays.apply(it => !it)()}
      />
      <NumericInput
        fieldId={formIds.validTermDays}
        style={{
          visibility: acceptedValidDays.value ? "hidden" : "visible"
        }}
        name={'Plazo de validez de Oferta'}
        placeholder={'días'}
        state={fields.validTermDays}
      />
       <BooleanCheckEditor
      fieldId={formIds.tac}
      style={{
        paddingTop: 10,
        paddingBottom: 10,
      }}
      label="Acepto los Términos y Condiciones Particulares del Pedio de Compra"
      state={props.acceptedTaC}
    />
      <BooleanCheckEditor
      fieldId={formIds.tac}
      style={{
        paddingTop: 10,
        paddingBottom: 10,
      }}
      label="Acepto la comisión a pagar por el Vendedor adjudicado"
      state={props.acceptedCommission}
    />
    </FlexCol>

   
    
  </FlexCol>
}


const SectionUploadPdfs = (
  props: {
    buyRequestId: Id
    state: RState<List<FileData>>
  }
) => {

  const removeFile = (filename: string): IO<Unit> =>
    props.state.apply(list => list.filter(it => it.name !== filename))


  const upload = useAppAsynchronism(
    (file: File) =>
      AppCall.do(async _ => {

        const training = await _(appCallTraining)

        const path = buildPathForStorage(
          training, 
          "Electricity", 
          props.buyRequestId, 
          'Encrypted', 
          none
        )()

        const fileData = await fileEncryptAndUpload(
          file,
          path
        )()
        props.state.apply(list => [...list, fileData])()
      })
  )

  const onFilesSelected = (files: FileList): IO<Unit> => 
    props.state.value.find(it => it.name === files[0].name) ?
      () => { alert("El archivo ya fue subido") } :
      upload.run(files[0])

  return <Row>
    <Col sm={4}>
      <br />
      <ReactFileReader
        elementId='pdf'
        fileTypes={".pdf"}
        handleFiles={
          files => {
            onFilesSelected(files)()
          }
        }
      >
        <Button size='sm'>Subir Archivos</Button>
      </ReactFileReader>
      <br />
      <small className='text-secondary'>Los archivo deben ser <b>.pdf</b></small>
    </Col>
    <Col>
      {
        showIf(props.state.value.length > 0)(
          <Table size="sm">
            <tbody>
              {props.state.value.map((fileData) => 
                <tr key={fileData.link}>
                  <td>{fileData.name}</td>
                  <td className='text-danger'>
                    <Button
                      size='sm'
                      variant='outline-danger'
                      onClick={removeFile(fileData.name)}>
                      Borrar
                    </Button>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        )
      }
      
      {
        matchPartial(upload.state)({

          running: state => 
            <Row className='text-secondary justify-content-center'>
              <h5><Spinner animation="grow" /> Encriptando Archivo</h5>
            </Row>,

          error: state => 
            <Row className='text-secondary justify-content-center'>
              <h5>Hubo un error al subir el archivo: {String(state.error)}</h5>
            </Row>

        })

      }
      <br />
      <small>
        Podés cargar la cantidad de PDF's que consideres necesarios.<br />
        Se deben cargar de a uno.
      </small>
    </Col>
  </Row>
}

const SectionUploadXls = (
  props: {
    fieldId?: string
    buyRequestId: Id
    state?: RState<Maybe<FileData>>
  }
) => {


  const handleXlsUpload = (file: File) =>
    AppCall.do(async _ => {
      try {

        const training = await _(appCallTraining)

        const path = buildPathForStorage(
          training, 
          "Electricity", 
          props.buyRequestId, 
          'Encrypted', 
          none
        )()

        const fileData = await fileEncryptAndUpload(
          file,
          path
        )()

        props.state?.apply(() => fileData)?.()
      } catch(reason) {
        if (reason === "file already uploaded") alert("El archivo ya fue subido")
      }
    })

  const upload = useAppAsynchronism(handleXlsUpload)

  const errors = useFieldErrors(props.fieldId)

  return <Row>
    <Col sm={4}>
      <br />
      <ReactFileReader
        elementId='xls'
        fileTypes={".xlsx"}
        handleFiles={it => upload.run(it[0])()}
      >
        <Button size='sm'>Cargar Template de Oferta</Button>
      </ReactFileReader>
      <br />
      <small className='text-secondary'>El archivo debe ser <b>.xlsx</b></small>
    </Col>
    <Col>
      {upload.state.type === "running" && 
        <Row className='text-secondary justify-content-center'>
        <h5><Spinner animation="grow" /> Encriptando Archivo</h5>
      </Row>
      }
      {props.state?.value && <Table size="sm">
        <tbody>
          <tr>
            <td>{props.state?.value?.name}</td>
            <td className='text-danger'>
              <Button
                size='sm'
                variant='outline-danger'
                onClick={props.state?.apply(it => none)}>
                Borrar
              </Button>
            </td>
          </tr>
        </tbody>
      </Table>}
      <br />
      <small
        style={{
          color: errors.length > 0 ? "red" : "black"
        }}
      >Cargar el Template de Oferta <b>completo</b></small>
    </Col>
  </Row>
}


ReactFileReader.defaultProps = {
  fileTypes: 'image/*',
  multipleFiles: false,
  base64: false,
  disabled: false,
}
