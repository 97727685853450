import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline"
import AttachFileIcon from '@material-ui/icons/AttachFile'
import React, { useState } from "react"
import { Col, Collapse, Row, Table } from "react-bootstrap"
import { useBidsList, useCompany, useSellerBidsList } from "../hooks/ConectoHooks"
import { BooleanCheckEditor } from "../components/editors/boolean"
import {
  dateToBidFormat,
  monthYearFormat,
  validaDateForBid,
  validaDateForCheck as checkInvalid
} from "../Utilities"
import { Bid, DecryptedBid, MaterOffer, ThermalOffer } from "../model/Bid"
import { Id } from "../model/Model"
import { ElectricityBuyRequest } from "../model/buyRequest/ElectricityBuyRequest"
import { List } from "functional/lib/List"
import { none } from "functional/lib/core"
import { Process } from "../model/Process"
import { RState } from "../functional/react/RState"
import { Maybe } from "functional/lib/Maybe"
import { useAppUser } from "../context/App"

export const MaterBidTable = (
  props: {

    buyRequest: ElectricityBuyRequest
    process: Process

    assignable: boolean

    selectedBidId?: RState<Maybe<Id>>
  }
) => {

  const user = useAppUser()

  const isBuyer = user?.roleList?.includes('buyer') ?? false
  const isSeller = user?.roleList?.includes('seller') ?? false

  const [bidList, loading, error] = 
    user?.roleList?.includes('buyer') ? 
      useBidsList(
        'electricity', 
        props.buyRequest.id ?? "", 
        props.process.id ?? ""
      ) : 
      useSellerBidsList(
        'electricity', 
        props.buyRequest.id ?? "", 
        props.process.id ?? "", 
        user?.training ?? false, 
        user?.companyIdList?.[0] ?? ""
      )
  
  return <>
    <Table className='text text-secondary text-center'>
      <thead>
        <tr>
          {isBuyer && <th align="center">Oferente</th>}
          <th align="center">Precio de Energía</th>
          <th align="center">Plazo de Contrato </th>
          <th align="center">Inicio de Suministro</th>
          <th align="center">Válida hasta</th>
          <th align="center">Archivos</th>
          <th align="center">Hora</th>
          {props.assignable ? <th align="center" className='text-success'>Seleccionar</th> : null}

        </tr>
      </thead>
      {error && <strong>Error: {JSON.stringify(error)}</strong>}
      {loading && <tbody><tr><td>Cargando...</td></tr></tbody>}
      <tbody>{
        bidList?.map(bid =>
          <MaterBidRow
            key={bid.id}
            buyRequest={props.buyRequest}
            process={props.process}
            bid={bid}
    
            assignable={props.assignable}
            assigned={bid.id === props.selectedBidId?.value}
            setAssigned={() => props.selectedBidId?.apply(() => bid.id)()}
            isBuyer={isBuyer}
          />
        )
      }</tbody>
    </Table>
      {user?.roleList?.includes('seller') && bidList && bidList.length == 0 && <Row>
        <small>Tus Ofertas enviadas recién estarán visibles cuando la Ronda sea desencriptada</small>
      </Row>}
      {user?.roleList?.includes('buyer') && bidList && bidList.length == 0 && <Row>
        <small>Por el momento esta Ronda no tiene Ofertas</small>
      </Row>}
    </>
}

const MaterBidRow = (
  props: {
    buyRequest: ElectricityBuyRequest
    process: Process
    bid: Bid

    assignable: boolean
    assigned: boolean
    setAssigned: (value: boolean) => void

    isBuyer: boolean
  }
) => {
  const bid = props.bid

  const [open, setOpen] = useState(false)
  const encryptedOffer = bid.encrypted && props.process.type === "tender"
  const decryptedBid = bid as DecryptedBid
  const offer = encryptedOffer ? null : decryptedBid.offer as MaterOffer
  const encryptedText = "Encriptado"

  const [company, loading, error] = useCompany(bid.encrypted ? none : decryptedBid.sellerCompanyId)

  return <>
    <tr className='text-center' key={bid.id}>
      {props.isBuyer && <td>{bid.encrypted ? encryptedText : company ? company.displayName : ""}</td>}
      <td>{encryptedOffer ? encryptedText : offer?.variableCost?.toFixed(2)}</td>
      <td>{encryptedOffer ? encryptedText : offer?.term}</td>
      <td>{encryptedOffer ? encryptedText : monthYearFormat(offer?.startSupplyDate)}</td>
      <td className={!bid.encrypted && checkInvalid(props.process.closeDate, offer?.validTermDays) ? 'text-danger' : none}>
        {encryptedOffer ? encryptedText : validaDateForBid(props.process.closeDate, offer?.validTermDays)}
      </td>
      <td>{encryptedOffer ? <AttachFileIcon className='text-secondary' /> : <a className='text-secondary'><AttachFileIcon fontSize='small' /><AddCircleOutlineIcon onClick={() => setOpen(!open)} /></a>}</td>
      <td>{dateToBidFormat(bid.date)}</td>
      {props.assignable ? 
        checkInvalid(props.process.closeDate, offer?.validTermDays) ? 
        <p className='text-danger'>Inválida</p> :
        <td>
          <BooleanCheckEditor
            type="checkbox"
            value={props.assigned}
            onValueChanged={value => props.setAssigned(value)}
          />
        </td> : null
      }
    </tr>
    <Collapse in={open}>
      <tr>
        <td colSpan={6}>
          <Row className='justify-content-center'>
            <Col xs={11}>
              <Table bordered>
                <tbody>
                  {offer?.decryptedXls && <tr>
                    <th>Template de Oferta</th>
                    <th><a target="_blank" href={offer.decryptedXls.link}>{offer.decryptedXls.name}</a></th>
                  </tr>}
                  {offer?.decryptedPdfFiles && <tr>
                    <th>Otros Archivos</th>
                    <th>{offer.decryptedPdfFiles.map((file, position) => { return <tr key={position}><a target="_blank" href={file.link}>{file.name}</a></tr> })}</th>
                  </tr>
                  }
                </tbody>
              </Table>
            </Col>
          </Row>
        </td>
      </tr>
    </Collapse>
  </>
}
