import React, { useState, Component } from "react"
import { Footer } from "../../widgets/footer"
import { Button, Col, Modal, Row } from "react-bootstrap"
import Iframe from "react-iframe";



export function TermCondition () {
  return (
    <div>
        <Row className={'justify-content-center'}>
        <iframe 
          src={"https://firebasestorage.googleapis.com/v0/b/conecto-energia.appspot.com/o/T%C3%A9rminos%20y%20Condiciones%20Generales%2FTerminos%20y%20Condiciones%20Generales%20(v2021-enero).pdf?alt=media&token=cc675480-c8f3-4cda-a4c2-e1ea1599784c"}
          height="700">
          </iframe>
      </Row>
      <Footer />
    </div>
  );
}