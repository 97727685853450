import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined"
import EditIcon from "@material-ui/icons/Edit"
import LocalGasStationIcon from "@material-ui/icons/LocalGasStation"
import OfflineBoltOutlinedIcon from "@material-ui/icons/OfflineBoltOutlined"
import WhatshotIcon from "@material-ui/icons/Whatshot"
import React, { useMemo, useState } from "react"
import {
  Alert,
  Button,
  ButtonGroup,
  Card,
  Col,
  Container,
  Dropdown, DropdownButton,
  Modal,
  Row,
  Stack,
  Table
} from "react-bootstrap"
import { useCompany, useCurrentUser, useDraftList } from "../../hooks/ConectoHooks"
import { dateToShortFormat, useCompanyName } from "../../Utilities"
import { BuyRequestDraft, Id, Product } from "../../model/Model"
import { TypeOfBuy } from "../../requestdetail/BuyRequestDetail"
import { initialElectricityDraft, initialGasDraft } from "../../buy-request-flow/BuyRequestCreateFlow"
import { Maybe, showIf } from "functional/lib/Maybe"
import { List } from "functional/lib/List"
import { BuyRequest, BuyRequestType } from "../../model/buyRequest/BuyRequest"
import { Unit, none, pipe } from "functional/lib/core"
import { IO } from "functional/lib/IO"
import { useAppAsynchronism } from "../../context/AppCall"
import { matchEnum, matchEnumPartial } from "functional/lib/match"
import { RState, useRState } from "../../functional/react/RState"
import { FlexCol, FlexRow } from "../../components/Flexbox"
import { buyRequestDraftDelete } from "../../client/buy-request"
import { useAppContext, useAppUser } from "../../context/App"
import { BuyRequestStatus } from "../../model/buyRequest/BuyRequestStatus"
import { User } from "../../model/User"
import { deviceSizes, useDeviceSize } from "../../hooks/responsive"
import { ConectoDialog, ConectoGrid } from "../admin/detail-components"


export const SandBoxMessage = () => {

  const ctx = useAppContext()
  const user = ctx.firebase.user

  const training = ctx.training

  if (user === none || !training) return none

  return (

    user.roleList?.includes('admin') ?
      <Alert 
        variant='info'
        style={{
          textAlign: 'center',
          marginBottom: 0
        }}
      >
        Modo <b>SANDBOX</b>
      </Alert> :

    user.roleList?.includes('buyer') ?
      <Alert 
        variant='info'
        style={{
          textAlign: 'center',
          marginBottom: 0
        }}
      >
        Bienvenido al <b>SANDBOX</b> de Conecto Energía
        <br />Tu Usuario está en etapa de <b>Entrenamiento</b>.
        <br />Aquí podrás cargar pedidos, publicarlos y recibir ofertas con las mismas funcionalidades de
        la plataforma operativa.
        <br />Tus pedidos serán vistos solamente por un conjunto de usuarios vendedores pertenecientes a
        productores, generadores y comercializadores de fantasía administrados por nuestro equipo.
        <br />Por cualquier duda o consulta podés comunicarte con nosotros al +54 (11) 3985-6212 o por
        email a <a href="mailto:info@conectoenergia.com">info@conectoenergia.com</a>
      </Alert> :

    user.roleList?.includes('seller') ?
      <Alert 
        variant='info'
        style={{
          textAlign: 'center'
        }}
      >
          Bienvenido al <b>SANDBOX</b> de Conecto Energía
          <br />Aquí podrás visualizar pedidos y ofertar a las licitaciones o subastas con las mismas
          funcionalidades de la plataforma operativa.
          <br />Los pedidos que veas son de empresas compradoras de fantasía administradas por nuestro
          equipo.
          <br />Por cualquier duda o consulta podés comunicarte con nosotros al +54 (11) 3985-6212 o por
          email a <a href="mailto:info@conectoenergia.com">info@conectoenergia.com</a>
        </Alert> :

    none
  )
}


export const ProductSelector = (
  props: {

    selectedProduct: RState<BuyRequestType>

    user: User
    
    statusFilter: RState<Maybe<BuyRequestStatus["type"]>>
    companyIdFilter: RState<Maybe<Id>>
    dateRangeFilter: RState<[Maybe<Date>, Maybe<Date>]>

    buyRequestList?: List<BuyRequest>
    
    onNewBuyRequest?: (draft: BuyRequestDraft) => IO<Unit>
  }
) => {
  const user = props.user

  const gasSelected = props.selectedProduct.value == 'gas'
  const electricitySelected = props.selectedProduct.value == 'electricity'

  const isAdmin = user?.roleList?.includes('admin') ?? false

  const authorizedProductList = user?.authorizedProductList ?? []

  const showNewRequestButton = (user?.roleList ?? []).includes("buyer") &&
    (
      (gasSelected && authorizedProductList.includes('gas')) || (
        electricitySelected && (
          authorizedProductList.includes('thermal') || 
          authorizedProductList.includes('onsite') || 
          authorizedProductList.includes('mater')
        )
      )
    )
  
  const liquidSelected = false //props.selectedProduct.value == 'liquid'

  const newRequestModalShow = useRState(() => false)


  const deviceSize = useDeviceSize()
  const small = deviceSize <= deviceSizes.lg

  return <Container fluid>
    <ConectoGrid
      spacing={1}
      cells={[
        [{ xs: 12, md: 6 }, <ButtonGroup style={{ flexGrow: 1 }}>
          <Button variant={gasSelected ? 'primary' : 'secondary'}
            onClick={props.selectedProduct.apply(() => 'gas')}
            disabled={!(isAdmin || (user && user.productList?.includes('gas')))}>
            <WhatshotIcon />{gasSelected && ' Gas Natural'}
          </Button>
          <Button variant={electricitySelected ? 'primary' : 'secondary'}
            onClick={props.selectedProduct.apply(() => 'electricity')}
            disabled={!(isAdmin || (user && user.productList?.includes('electricity')))}>
            <OfflineBoltOutlinedIcon />{electricitySelected && ' Energía Eléctrica'}
          </Button>
          <Button variant={liquidSelected ? 'primary' : 'secondary'}
            //onClick={props.selectedProduct.apply(() => 'liquid')}
            disabled={user && !user.productList?.includes('liquid')}>
            <LocalGasStationIcon />{liquidSelected && ' Combustibles Líquidos'}
          </Button>
        </ButtonGroup>],
        [{ xs: 12, md: 6 }, 
          showIf(showNewRequestButton)(
            <FlexCol
              style={{ flexGrow: 1 }}
              alignItems="stretch"
            >
              <Button 
                variant="warning" 
                style={{ flexGrow: 1 }}
                onClick={newRequestModalShow.apply(() => true)}
              >
                <FlexRow
                  alignItems='center'
                  justifyContent='center'
                  spacing={6}
                >
                  Nuevo Pedido 
                  {showIf(gasSelected)(<WhatshotIcon />)}
                  {showIf(electricitySelected)(<OfflineBoltOutlinedIcon />)}
                </FlexRow>
                {/**/}
              </Button>
              <Modal
                show={newRequestModalShow.value}
                onHide={newRequestModalShow.apply(() => false)}
                size="lg"
                centered
              >
                <NewRequestModal
                  user={props.user}
                  product={props.selectedProduct.value ?? "gas"}
                  onNewBuyRequest={props.onNewBuyRequest} 
                />
              </Modal>
            </FlexCol>
          )
        ]
      ]}
    />

    <br />
    <Stack
      direction={small ? "vertical" : "horizontal"}
      style={{
        justifyContent: 'space-between',
        alignItems: "flex-start"
      }}
    >
      <FlexCol>
        {electricitySelected && <h5 className='text text-secondary'>Pedidos de Energía Eléctrica</h5>}
        {gasSelected && <h5 className='text text-secondary'>Pedidos de Gas Natural</h5>}
      </FlexCol>
      <FlexRow
        gap={6}
      >
        <DateRangeEditor
          state={props.dateRangeFilter}
        />
        <CompanyEditor
          buyRequestList={props.buyRequestList}
          companyIdFilter={props.companyIdFilter}
        />
        <StatusEditor
          state={props.statusFilter}
        />
      </FlexRow>
    </Stack>
  </Container>
}



const CompanyEditor = (
  props: {
    companyIdFilter: RState<Maybe<Id>>
    buyRequestList?: List<BuyRequest>
  }
) => {

  const [user, loading, error] = useCurrentUser()

  const sellerCompaniesIdList =
    props.buyRequestList?.map(buyRequest => buyRequest.buyerCompanyId) ?? []
  
  const filteredSellerCompaniesIdList = 
    sellerCompaniesIdList.filter((item, index) => sellerCompaniesIdList.indexOf(item) === index)

  const hookTitle = useCompanyName(props.companyIdFilter.value ?? "")

  const companyButtonTitle = props.companyIdFilter.value !== none ? hookTitle : 'Empresa'
  const companyButtonColor = props.companyIdFilter.value ? 'secondary btn btn-sm' : 'outline-secondary btn btn-sm'

  return <DropdownButton 
    id='dropdown-empresa' 
    variant={companyButtonColor} 
    title={companyButtonTitle}
  >
    <Dropdown.Item 
      eventKey="6" 
      className='' 
      onClick={props.companyIdFilter.apply(() => none)}
    >
        Todas
    </Dropdown.Item>
    <Dropdown.Divider />
    {
      showIf(user?.roleList?.includes('buyer') ?? false)(
        user?.companyIdList?.map(id => 
          <CompanyDropdownItem 
            key={id} 
            id={id} 
            onClick={props.companyIdFilter.apply(() => id)} 
          />
        )
      )
    }
    {
      showIf(
        (user?.roleList?.includes('seller') ?? false) && 
        props.buyRequestList !== none
      )(
        filteredSellerCompaniesIdList.map(id => 
          <CompanyDropdownItem 
            key={id} 
            id={id} 
            onClick={props.companyIdFilter.apply(() => id)} 
          />
        )
      )
    }
  </DropdownButton>
}



const StatusEditor = (
  props: {
    state: RState<Maybe<BuyRequestStatus["type"]>>
  }
) => {

  const statusButtonTitle = statusToSpanish(props.state.value)
  const buttonColor = statusColor(props.state.value)

  return <DropdownButton 
    id='dropdown-estado' 
    variant={buttonColor} 
    title={statusButtonTitle}
  >
    <Dropdown.Item 
      eventKey="6" 
      className='' 
      onClick={props.state.apply(() => none)}
    >
      Todos
    </Dropdown.Item>
    <Dropdown.Divider />
    <Dropdown.Item 
      eventKey="1" 
      className='text-success' 
      onClick={props.state.apply(() => 'open')}
    >
      Abiertos
    </Dropdown.Item>
    <Dropdown.Item 
      eventKey="2" 
      className='text-warning' 
      onClick={props.state.apply(() => 'pending')}
    >
      Pendientes
    </Dropdown.Item>
    <Dropdown.Item 
      eventKey="3" 
      className='text-primary' 
      onClick={props.state.apply(() => 'waitingAnswer')}
    >
      En Análisis
    </Dropdown.Item>
    <Dropdown.Item 
      eventKey="4" 
      className='text-info' 
      onClick={props.state.apply(() => 'assigned')}
    >
      Adjudicados
    </Dropdown.Item>
    {/*{user?.roleList.includes('seller') && <Dropdown.Item eventKey="7" className='text-secondary' onClick={() => props.setStatusFilter(null)}>Adjudicados a Terceros</Dropdown.Item>}*/}
    <Dropdown.Item 
      eventKey="5" 
      className='text-danger' 
      onClick={props.state.apply(() => 'deserted')}
    >
      Desiertos
    </Dropdown.Item>
  </DropdownButton>
}

const DateRangeEditor = (
  props: {
    state: RState<[Maybe<Date>, Maybe<Date>]>
  }
) => {

  const yearRange = (year: number): [Date, Date] => 
    [new Date(year, 0, 1), new Date(year + 1, 0, 1)]

  const range = props.state.value

  const thisYear = useMemo(() => new Date().getFullYear(), [])

  const firstYear = 2020

  const years = List.new(thisYear - firstYear + 2)(i => firstYear + i)

  const color = range[0] ? 'secondary btn btn-sm' : 'outline-secondary btn btn-sm'

  return <DropdownButton 
    id='dropdown-estado'
    variant={color}
    title={
      range[0] === none ? "Año" :
      range[0].getFullYear()
    }
  >
    <Dropdown.Item 
      eventKey="6"
      onClick={props.state.apply(() => [none, none])}
    >
      Todos
    </Dropdown.Item>
    <Dropdown.Divider />
    {
      years.map(year =>
        <Dropdown.Item 
          key={year}
          eventKey={year}
          onClick={props.state.apply(() => yearRange(year))}
        >
          {year}
        </Dropdown.Item>
      )
    }
  </DropdownButton>
}

const CompanyDropdownItem = (
  props: {
    id: Maybe<Id>
    onClick: IO<Unit>
  }
) => {
  const [company, loading, error] = useCompany(props.id)
  return <Dropdown.Item 
    key={props.id} 
    onClick={props.onClick}
  >
    {company && company.businessName}
    {loading && "Cargando..."}
    {error && error.message}
  </Dropdown.Item>
}


export const NewRequestModal = (
  props: {
    user: User
    product: Product
    onNewBuyRequest?: (draft: BuyRequestDraft) => IO<Unit>
  }
) => {
  const isGas = props.product === 'gas'
  const isElectricity = props.product === 'electricity'
  
  const [drafts, draftsLoading, draftsError] = useDraftList(props.user?.companyIdList, props.product)
  
  const emptyDraft: BuyRequestDraft = (
    isGas ? initialGasDraft : 
    isElectricity ? initialElectricityDraft : 
    null
  ) as BuyRequestDraft
  return <>
    <Modal.Header 
      closeButton
    >
      <Modal.Title className='text-secondary'>Publicar un Pedido de Compra</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <br />
      <Card 
        border="primary" 
        onClick={props.onNewBuyRequest?.(emptyDraft)}
      >
        <Card.Body style={{ cursor: 'pointer' }}>
          <h4 className='text-center text-primary'>
            Pedido Nuevo
          </h4>
        </Card.Body>
      </Card>
      <br />
      <Card border='info'>
        <Card.Body>
          <h4 className='text-center text-info'>
            Pedidos Guardados
          </h4><br />
          <Table size="sm">
            <thead>
              <tr>
                <th className='text-secondary text-center'>Usuario</th>
                <th className='text-secondary text-center'>Tipo de Compra</th>
                <th className='text-secondary text-center'>Última Edición</th>
                <th className='text-secondary text-center' colSpan={2}>Acciones</th>
              </tr>
            </thead>
            <tbody>
              {(!drafts || (drafts && drafts.length == 0)) && <>
                <tr></tr>
                <tr>
                  <td className='text-center text-secondary' colSpan={4}>
                    No hay Pedidos de Compra de {isGas && 'Gas'}{isElectricity && 'Electricity'} guardados
                  </td>
                </tr>
              </>}
              {
                drafts && 
                drafts.length > 0 && 
                drafts.toSorted((a, b) =>
                  (a.lastEdit?.getTime() ?? 0) < (b.lastEdit?.getTime() ?? 0) ? 1 :
                  (a.lastEdit?.getTime() ?? 0) > (b.lastEdit?.getTime() ?? 0) ? -1 :
                  0
                ).map(draft => 
                  <DraftView
                    key={draft.id}
                    draft={draft}
                    onClick={props.onNewBuyRequest?.(draft) ?? IO.noOp}
                  />
                )
              }
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </Modal.Body>
  </>
}

const DraftView = (
  props: {
    draft: BuyRequestDraft
    onClick: IO<Unit>
  }
) => {
  const draft = props.draft

  const callDelete = useAppAsynchronism(buyRequestDraftDelete)

  return <tr>
  <td className='text-center text-secondary'>{
    pipe([draft.user?.firstName, draft.user?.lastName])(
      List.filterNotNone,
      it => it.join(" "),
    )
  }</td>
  <td className='text-center text-secondary'><TypeOfBuy buyRequest={draft} /></td>
  <td className='text-center text-secondary'>{Maybe.lift(dateToShortFormat)(draft.lastEdit)}</td>
  <td className='text-center' colSpan={2}>
    <Button size='sm' variant='outline-secondary' onClick={callDelete.run(draft.id ?? "")}>
      <DeleteOutlineOutlinedIcon
        style={{ fontSize: 17 }} 
      /> Borrar</Button>
    <Button 
      size='sm' 
      variant='outline-info' 
      onClick={props.onClick}
    >
      <EditIcon style={{ fontSize: 17 }} />Editar | Publicar
    </Button>
  </td>
</tr>
}


const statusToSpanish = (status: Maybe<BuyRequestStatus["type"]>) => 
  matchEnumPartial(status ?? "_")({
    pending: "Pendientes",
    open: "Abiertos",
    waitingAnswer: "En Análisis",
    assigned: "Adjudicados",
    deserted: "Desiertos",
    _: "Estados"
  })

const statusColor = (status: Maybe<BuyRequestStatus["type"]>) => 
  matchEnum(status ?? "_")({
    pending: "outline-warning btn btn-sm",
    waitingAnswer: "outline-primary btn btn-sm",
    open: "outline-success btn btn-sm",
    assigned: "outline-info btn btn-sm",
    deserted: "outline-danger btn btn-sm",
    _: "outline-secondary btn btn-sm"
  })
