import SearchRoundedIcon from '@material-ui/icons/SearchRounded'
import { IO } from 'functional/lib/IO'
import { List } from 'functional/lib/List'
import { Maybe, showIf } from 'functional/lib/Maybe'
import { Unit, none } from 'functional/lib/core'
import { match, matchEnum, matchEnumLazy } from 'functional/lib/match'
import React, { ReactNode } from "react"
import {
  Row,
  Spinner
} from "react-bootstrap"
import { FlexCol, FlexRow } from '../../components/Flexbox'
import { RState } from '../../functional/react/RState'
import {
  useCurrentUser
} from "../../hooks/ConectoHooks"
import { BuyRequestDraft, Id } from "../../model/Model"
import { Process } from '../../model/Process'
import { BuyRequest, BuyRequestType } from '../../model/buyRequest/BuyRequest'
import { BuyRequestColumn, BuyRequestListRow, buyRequestColumns } from './BuyRequestListRow'
import { ProductSelector } from "./MarketPlaceComponents"
import { BuyRequestStatus } from '../../model/buyRequest/BuyRequestStatus'
import { deviceSizes, useDeviceSize } from '../../hooks/responsive'
import { useNavigate } from 'react-router'
import { appPaths } from '../navigation'


export const BuyRequestListPanel = (
  props: {
    style?: React.CSSProperties

    type: RState<BuyRequestType>

    buyRequestList: List<BuyRequest>
    loading: boolean
    
    statusFilter: RState<Maybe<BuyRequestStatus["type"]>>
    companyIdFilter: RState<Maybe<string>>
    dateRangeFilter: RState<[Maybe<Date>, Maybe<Date>]>
    
    onNewBuyRequest?: (draft: BuyRequestDraft) => IO<Unit>

    selectedRequestId: RState<Maybe<Id>>
  }
) => {

  const [user, loadingUser, userError] = useCurrentUser()

  if (user === none) return <Spinner animation="border" />

  return <FlexCol 
    style={props.style}
  >
    <ProductSelector
      user={user}
      onNewBuyRequest={props.onNewBuyRequest}
      statusFilter={props.statusFilter}
      companyIdFilter={props.companyIdFilter}
      dateRangeFilter={props.dateRangeFilter}
      buyRequestList={props.buyRequestList}
      selectedProduct={props.type}
    />
    {showIf(props.buyRequestList?.length > 0)(
      <BuyRequestListPanelSections
        buyRequestType={props.type.value}
        selectedRequestId={props.selectedRequestId}
        buyRequestList={props.buyRequestList}
        loading={props.loading}
      />
    )}
    {
      showIf(props.buyRequestList?.length == 0)(
        <>
          <br /><br /><br /><br /><br /><br />
          <Row className='justify-content-center'>
            <h4 className='text-secondary'>
            <SearchRoundedIcon /> No hay Pedidos para estos filtros</h4>
          </Row>
        </>      
      )
    }
    <Row>
    <br />
    </Row>
  </FlexCol>
}


const BuyRequestListPanelSections = (
  props: {
    buyRequestType: BuyRequestType
    selectedRequestId: RState<Maybe<Id>>
    buyRequestList?: List<BuyRequest>
    loading: boolean
  }
) => {
  const [user, loading, error] = useCurrentUser()

  const userCompanyList = user?.companyIdList ?? []

  const role = user?.roleList?.includes('buyer') ? "buyer" : "seller"

  const requestList = props.buyRequestList
  if (!requestList || props.loading)
    return <><br /><br />Aún No Hay Pedidos</>

  const selectedRequestId = props.selectedRequestId

  const sections = buyRequestsGroupBySection(role)(userCompanyList)(requestList)

  const amountColumn: BuyRequestColumn = 
    matchEnum(props.buyRequestType)({
      gas: "cmd",
      electricity: "quantity",
    })

  const deviceSize = useDeviceSize()
  const small = deviceSize <= deviceSizes.lg

  return <FlexCol
    style={{
      width: '100%',
      paddingRight: small ? 2 : 6,
      paddingLeft: small ? 2 : 6,
    }}
    alignItems='stretch'
  >
    <BuyRequestListSection
      title={{
        text: "Pendientes",
        color: "text-warning"
      }}
      data={sections.pending}
      selectedId={selectedRequestId}
      columns={List.filterNotNone([
        "id", 
        "type", 
        small ? none : "point", 
        amountColumn, 
        small ? none : "openDate", 
        "round"
      ])}
    />
    <BuyRequestListSection
      title={{
        text: "Abiertos para Ofertar",
        color: "text-success"
      }}
      data={sections.open}
      selectedId={selectedRequestId}
      columns={List.filterNotNone([
        "id", 
        "type", 
        small ? none : "point", 
        amountColumn, 
        small ? none : "closeDate", 
        "round"
      ])}
    />
    <BuyRequestListSection
      title={{
        text: "En Análisis",
        color: "text-primary"
      }}
      data={sections.waiting}
      selectedId={selectedRequestId}
      columns={List.filterNotNone([
        "id", 
        "type", 
        small ? none : "point", 
        amountColumn, 
        small ? none : "definitionDate", 
        "round"
      ])}
    />
    {/* <BuyRequestListSection
      title={{
        text: "Pre-Adjudicados",
        color: "text-secondary"
      }}
      data={sections.preAssigned}
      selectedId={selectedRequestId}
      columns={List.filterNotNone([
        "id", 
        "type", 
        small ? none : "point", 
        amountColumn, 
        small ? none : "closeDate", 
        "round"
      ])}
    /> */}
    <BuyRequestListSection
      title={{
        text: "Adjudicados",
        color: "text-info"
      }}
      data={sections.assigned}
      selectedId={selectedRequestId}
      columns={List.filterNotNone([
        "id", 
        "type", 
        small ? none : "point", 
        amountColumn, 
        small ? none : "closeDate", 
        "round"
      ])}
    />
    <BuyRequestListSection
      title={{
        text: "Concluidos",
        color: "text-secondary"
      }}
      data={sections.concluded}
      selectedId={selectedRequestId}
      columns={List.filterNotNone([
        "id", 
        "type", 
        small ? none : "point", 
        amountColumn, 
        small ? none : "closeDate", 
        "round"
      ])}
    />
    <BuyRequestListSection
      title={{
        text: "Desiertos",
        color: "text-secondary"
      }}
      data={sections.deserted}
      selectedId={selectedRequestId}
      columns={List.filterNotNone([
        "id", 
        "type", 
        small ? none : "point", 
        amountColumn, 
        small ? none : "closeDate", 
        "round"
      ])}
    />
  </FlexCol>
}

const buyRequestsGroupBySection = 
  (role: "buyer" | "seller") => 
  (userCompanyList: List<string>) =>
  (requestList: List<BuyRequest>) => {

    const open = requestList.filter(request => request.status.type === "open")
    const pending = requestList.filter(request => request.status.type === "pending")
    const waiting = requestList.filter(request => request.status.type === "waitingAnswer")


    const concluded = matchEnumLazy(role)({
      seller: () => 
        requestList.filter(it => 
          it.status.type === "assigned" 
          || 
          it.status.type === "deserted" 
          // ?
          //   !userCompanyList.includes(it.status.assignedCompanyId ?? "") :
          // false
        ),
      buyer: () => [],
    })

    const unassigned = matchEnumLazy(role)({
      seller: () => 
        requestList.filter(it => 
          it.status.type === "assigned"
        ),
      buyer: () => [],
    })

    const assigned = matchEnumLazy(role)({
      seller: () => 
        requestList.filter(
          it => it.status.type === "assigned" && userCompanyList.includes(it.status.assignedCompanyId ?? "")
        ),
      buyer: () => requestList.filter(it => it.status.type === "assigned")
    })
    
    // const preAssigned = matchEnumLazy(role)({
    //   seller: 
    //     () => requestList.filter(
    //       it => 
    //         it.status.type === "preAssigned" && it.status.preAssignedCompanyIdList?.some(
    //           companyId => userCompanyList.includes(companyId)
    //         )          
    //     ),
    //   buyer: () => requestList.filter(it => it.status.type === "preAssigned")
    // })
      
    // const deserted =  requestList.filter(it => it.status.type === "deserted")

    const deserted = matchEnumLazy(role)({
      buyer: () => 
        requestList.filter(it => 
          it.status.type === "deserted"
        ),
      seller: () => [],
    })

    return {
      open,
      pending,
      waiting,
      unassigned,
      assigned,
      concluded,
      deserted,
    }
  }


const BuyRequestListSection = (
  props: {
    title: {
      text: string
      color: string
    }
    data: List<BuyRequest>
    columns: List<BuyRequestColumn>
    selectedId: RState<Maybe<Id>>
  }
) => {

  if (props.data.length === 0) return none

  return <FlexCol
    alignItems='stretch'
  >
    <h5
      className={props.title.color}
      style={{
        textAlign: 'center',
      }}
    >
      <br />{props.title.text}
    </h5>
    <TableCards
      style={{ 
        padding: 8 
      }}
      data={props.data}
      columns={props.columns.map(column => buyRequestColumns[column])}
      selectedId={props.selectedId}
    />

  </FlexCol>
}

const TableCards = (
  props: {
    style?: React.CSSProperties
    data: List<BuyRequest>
    columns: List<{
      width?: number
      header: string
      cell: (data: BuyRequest, lastProcess: Maybe<Process>) => ReactNode
    }>
    selectedId: RState<Maybe<Id>>
  }
) => {

  const deviceSize = useDeviceSize()
  const small = deviceSize <= deviceSizes.lg

  const navigate = useNavigate()

  return <FlexCol
    style={props.style}
    alignItems='stretch'
    gap={4}
  >
    <FlexRow
      style={{
        paddingLeft: 6,
        paddingRight: 6,
      }}
    >
      {
        props.columns.map(column =>
          <FlexCol 
            key={column.header}
            style={{
              width: 0,
              flexGrow: column.width ?? 1,
              alignItems: 'center',
              fontSize: 10,
            }}
          >
            <b>{column.header}</b>
          </FlexCol> 
        )
      }
    </FlexRow>
    {
      props.data.map(item =>
        <BuyRequestListRow
          key={item.id}
          buyRequest={item}
          onClick={
            small ? () => navigate(appPaths.marketplaceBuyRequest(item.type, item.id ?? "-")) :
            props.selectedId?.apply(() => item.id)
          }
          columns={props.columns}
          isSelected={props.selectedId.value === item.id}
        />
      )
    }
  </FlexCol>
}
