import { firestore } from "firebase"

import Timestamp = firestore.Timestamp
import DocumentReference = firestore.DocumentReference
import { GasBuyRequest } from "./buyRequest/GasBuyRequest"
import { ElectricityBuyRequest } from "./buyRequest/ElectricityBuyRequest"
import { BuyRequest } from "./buyRequest/BuyRequest"
import { User } from "./User"
import { StaticDecode, Type } from "@sinclair/typebox"
import { Draft } from "./utils"
import { Maybe } from "functional/lib/Maybe"

export const ProductT = Type.Union([
  Type.Literal("gas"),
  Type.Literal("electricity"),
  Type.Literal("liquid"),
  Type.Literal("mater"),
  Type.Literal("thermal"),
  Type.Literal("onsite"),
  Type.Literal("surplus"),
  Type.Literal("plus")

])
export type Product = StaticDecode<typeof ProductT>

export const availableProducts = ["gas", 'mater', 'thermal', 'onsite'] as const

export type Id = string

export type BuyRequestDraft = 
  | Draft<GasBuyRequest>
  | Draft<ElectricityBuyRequest>

export type Comment = {
  id: string
  comment: string
  creationDate: Date
  answer: string
  user: Maybe<User>
  answered: boolean
  training?: boolean
  parentParent?: DocumentReference<BuyRequest>
  message?: string
  product?: string
  buyRequestId?: Id

  oldAnswer?: boolean
}

export type Address = {
  street: string
  locality: string
  province: string
}

export type SupplyPointCategory = string

export type Distributor = {
  name: string
  identifier: string
}

export type SupplyPoint = {
  id: Id
  name: string
  shortName: string
  address: Address
  category: SupplyPointCategory
  distributor: Distributor
  product: Product
  multiple: boolean
  nemo?: string
  type?: string
}

export type SentMail = {
  id: Id,
  delivery?: {
    state: string
    startTime: Date
    error: string
  }
  to: Maybe<string>
  template: {
    name: string
    data?: Record<string, Maybe<string>>
  }
}

export type TermsAndConditions = {
  id: Id,
  acceptedUsersList: Id[],
  acceptedTime: {},
  date: Date,
  pdfLink: string
}
