import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';


// className "owl-theme" is optional

const EECarrousel = () => {
    return (
         <OwlCarousel 
              className='owl-theme' 
            //   loop 
              margin={30} 
              items={5}
              slideBy={5}
              autoplay={true}
              rewind={false}
              dots={false}
            //   nav
         >
        <div className='item'>
          <div className="clients-logo">
          <img className="img-fluid" src={require(`../assets/images/client/ypfLuz.png`)} alt="" />
          </div>
        </div>
        <div class='item'>
        <div className="clients-logo">
        <img className="img-fluid" src={require(`../assets/images/client/pampa.png`)} alt="" />
        </div>
        </div>
        <div class='item'>
        <img className="img-fluid" src={require(`../assets/images/client/genneia.png`)} alt="" />
        </div>
        <div class='item'>
        <img className="img-fluid" src={require(`../assets/images/client/cp.png`)} alt="" />
        </div>
        <div class='item'>
        <img className="img-fluid" src={require(`../assets/images/client/pcr.png`)} alt="" />
        </div>
        <div class='item'>
        <img className="img-fluid" src={require(`../assets/images/client/aes.png`)} alt="" />
        </div>
        <div class='item'>
        <img className="img-fluid" src={require(`../assets/images/client/msu.png`)} alt="" />
        </div>
        <div class='item'>
        <img className="img-fluid" src={require(`../assets/images/client/capex.png`)} alt="" />
        </div>
        <div class='item'>
        <img className="img-fluid" src={require(`../assets/images/client/360.png`)} alt="" />
        </div>
        <div class='item'>
        <img className="img-fluid" src={require(`../assets/images/client/diaser.png`)} alt="" />
        </div>
        <div class='item'>
        <img className="img-fluid" src={require(`../assets/images/client/harz.png`)} alt="" />
        </div>
        <div class='item'>
        <img className="img-fluid" src={require(`../assets/images/client/aconcagua.png`)} alt="" />
        </div>
        <div class='item'>
        <img className="img-fluid" src={require(`../assets/images/client/eoliasur.png`)} alt="" />
        </div>
        <div class='item'>
        <img className="img-fluid" src={require(`../assets/images/client/efesa.png`)} alt="" />
        </div>
        <div class='item'>
        <img className="img-fluid" src={require(`../assets/images/client/enel-x.png`)} alt="" />
        </div>
      </OwlCarousel>
    )
}

export default EECarrousel;
