import GetAppIcon from '@material-ui/icons/GetApp'
import React, { useState } from "react"
import { Button, ButtonGroup, Col, Dropdown, DropdownButton, Row } from "react-bootstrap"
import { useBidsList, useSellerBidsList } from "../hooks/ConectoHooks"
import { Id } from "../model/Model"
import { AnnualBidTable, AnnualModal } from "./AnnualBidTable"
import { MaterBidTable } from "./MaterBidTable"
import { MonthlyBidTable } from "./MonthlyBidTable"
import { SolarFVOnsiteBidTable } from "./SolarFVOnsiteBidTable"
import { SpotBidTable } from "./SpotBidTable"
import { SurplusBidTable } from "./SurplusBidTable"
import { PlusBidTable } from "./PlusBidTable"
import { ThermalBidTable } from "./ThermalBidTable"
import { none, throws } from 'functional/lib/core'
import { match } from 'functional/lib/match'
import { BuyRequest, BuyRequestType } from '../model/buyRequest/BuyRequest'
import { List } from 'functional/lib/List'
import { Maybe, showIf } from 'functional/lib/Maybe'
import { Process } from '../model/Process'
import { RState } from '../functional/react/RState'
import { useAppUser } from '../context/App'
import { FlexCol } from '../components/Flexbox'


export const ProcessDetail = (
  props: {
    buyRequest: BuyRequest
    processList: List<Process>

    assignable?: boolean
    preAssignable?: boolean
    
    selectedProcess: RState<Maybe<Process>>

    selectedBidId?: RState<Maybe<Id>>
    selectedToPreAssign?: RState<List<Id>>

  }
) => {

  const user = useAppUser() ?? throws("No user")

  const selectedProcess = props.selectedProcess.value
  const [bidList] = 
    user.roleList?.includes('seller') ? 
      useSellerBidsList(
        props.buyRequest.type, 
        props.buyRequest.id ?? "", 
        selectedProcess?.id ?? "", 
        user.training ?? false, 
        user.companyIdList?.[0] ?? ""
      ) :
      useBidsList(
        props.buyRequest.type, 
        props.buyRequest.id ?? "", 
        selectedProcess?.id ?? ""
      )

  const [showModal, setShowModal] = useState(false)

  return <>
    <Row className='text text-secondary justify-content-center'>
      <Col xs={12} md={2}>
        <DropdownButton
          as={ButtonGroup}
          size={'sm'}
          variant='secondary'
          id={'round-selector'}
          title={`Ronda  ${selectedProcess?.round ?? ""}`}>
          {props.processList && props.processList.map((process, index) =>
            <Dropdown.Item 
              key={index} 
              onClick={props.selectedProcess.apply(() => process)}
            >
              Ronda {process.round}
            </Dropdown.Item>
          )}
        </DropdownButton>
      </Col>
      <Col xs={12} md={5}>
        {selectedProcess && <h5>Cant. de Ofertas {user.roleList?.includes('seller') && 'Propias'}: {bidList && bidList.length}</h5>}
      </Col>
      <Col className={'text-right'}>
        {/*{selectedProcess.encrypted && <Button variant="outline-info" disabled size="sm"><GetAppIcon/> Excel/CSV</Button>}*/}
        {/*
          <Button 
            variant={'outline-info'} 
            size={'sm'} 
            onClick={() => setShowModal(true)}>
              <GetAppIcon /> Analizar/Exportar
          </Button>
        */}
      </Col>
    </Row>
    <br />
    {
      selectedProcess && props.buyRequest.type === "gas" ?
        <AnnualModal 
          buyRequest={props.buyRequest} 
          process={selectedProcess} 
          bidList={bidList ?? []} 
          show={showModal} 
          setShow={setShowModal} 
        /> :
        none
    }
    
    {
      selectedProcess !== none ?
        <Row
          style={{
            overflow: 'auto',
          }} 
          className='justify-content-center'
        >
          <FlexCol
            alignItems='stretch'
          >
            <BidTable

              buyRequest={props.buyRequest}
              process={selectedProcess}

              assignable={props.assignable ?? false}
              preAssignable={props.preAssignable ?? false}

              selectedBidId={props.selectedBidId}
              selectedToPreAssign={props.selectedToPreAssign}
            />
          </FlexCol>
        </Row> :
        none
    }
    
  </>
}

const BidTable = (
  props: {
    buyRequest: BuyRequest

    process: Process
    assignable: boolean
    preAssignable: boolean

    selectedBidId?: RState<Maybe<Id>>
    selectedToPreAssign?: RState<List<Id>>
  }
) => 
  match(props.buyRequest)({

    gas: buyRequest =>
      match(buyRequest.buyType)({

        annual: buyType =>
          <AnnualBidTable
            buyRequest={buyRequest}
            process={props.process}

            assignable={props.assignable}

            selectedBidId={props.selectedBidId}
          />,

        monthly: buyType =>
          <MonthlyBidTable
            buyRequest={buyRequest}
            process={props.process}
            assignable={props.assignable}
            preAssignable={props.preAssignable}

            selectedBidId={props.selectedBidId}
            selectedToPreAssign={props.selectedToPreAssign}
          />,

        spot: buyType =>
          <SpotBidTable
            buyRequest={buyRequest}
            process={props.process}
            assignable={props.assignable}
            preAssignable={props.preAssignable}

            selectedBidId={props.selectedBidId}
          />,

      }),
    
    electricity: buyRequest =>
      match(buyRequest.buyType)({

        thermal: buyType =>
          <ThermalBidTable
            buyRequest={buyRequest}
            process={props.process}
            assignable={props.assignable}
            selectedBidId={props.selectedBidId}
          />,

        mater: buyType =>
          <MaterBidTable
            buyRequest={buyRequest}
            process={props.process}
            assignable={props.assignable}
            selectedBidId={props.selectedBidId}
          />,

        onsite: buyType =>
          <SolarFVOnsiteBidTable
            buyRequest={buyRequest}
            process={props.process}
            assignable={props.assignable}
            selectedBidId={props.selectedBidId}
          />,

          plus: buyType =>
            <PlusBidTable
              buyRequest={buyRequest}
              process={props.process}
              assignable={props.assignable}
              selectedBidId={props.selectedBidId}
            />,

        surplus: buyType =>
          <SurplusBidTable
            buyRequest={buyRequest}
            process={props.process}
            assignable={props.assignable}
            selectedBidId={props.selectedBidId}
          />

      })

  })


// function Probando(
//     props: {
//         bidList: Bid[]
//         selectedProcess: Process
//         buyRequest: GasBuyRequest | ElectricityBuyRequest
//         product: Product
//     }
// )  {
//     console.log(props.bidList)
//     const companiesString = {TO3QLXgsBQFimm0veVKH:'Gas Marketing SAS',
//                             W17wrL5241gUFv9svSDb:'NS Energy S.R.L.',
//                             gTNhGVn3NxNwS2JnXnNB:'Nortegas S.R.L.',
//                             GltARzBk51UWB8VHbDpp:'San Atanasio Energia S.A.',
//                             J2Uke8yJKnGXohmLMWFs:'Energy Traders S.A.',
//                             KuowmWDISjgUXmhalYoi:'Pan American Energy LLC (Sucursal Argentina)',
//                             '1BF8YNPlYYA6xNvD6xOD':	'Delta Energía S.A.S.',
//                             "4aG6uHKfFBMdEiYIThMQ":'Enap Sipetrol Argentina S.A.',
//                             "5ulfEoE3a9iTh3vpyXsx":'Diaser s.a.',
//                             "94HA88WDnK7Y5p6U6NoQ":'Latin Energy Group S.R.L.',
//                             "9YjOXMGbPesDEwTDYfVU":'CP Renovables S.A.',
//                             M75rWUBD8ML8tUXHF3Gp:'Orazul Energy Southern Cone S.R.L.',
//                             MPUKrWGSktGz68p28GEO:'Rafael Albanesi S.A.',
//                             T2PhwjXooe0kqw3QJUHF:'Pluspetrol S.A.',
//                             T5prIz87RW7jA1Zj6suh:'Latinoamericana de Energía S.A.',
//                             UMRmK2iEBRMBEMCJhx2T:'Enersud Energy S.A.',
//                             VywgkOqbGxuWQxbc7SYE:'AMG Energía S.A.',
//                             W5z2Tmoq9YIfXDXhmE3u:'Pampa Energía S.A.',
//                             WDb9VQ8jSyacNLxK3o9Y:'Victorio Podestá y Cía. S.A.',
//                             Xs5GGOcfqFLo7oFlurnR:'Gas Patagonia S.A.',
//                             YIb2Um1t0Qu9BuIlv2RY:'Empresa Federal de Energía S.A.',
//                             ddnxkZUcFjz9bADcylW9: 'YPF S.A.',
//                             dxT3LMDz83OvPZFukbYc: 'GEA Energy S.A.',
//                             ehsp3wo2jCBiyLS0l0cN: 'Energy Consulting Services S.A.',
//                             j3QOFr3FMDi8Gf1aGi0W: 'Hive Energy Ltd',
//                             j79STpwAWXhIR5gl39YN: 'PCR S.A.',
//                             jgPzvol0sjm7Zqp6ZVpp: 'Gasmarket S.A.',
//                             jhjlaZsNHHheS2yBl4fi: 'Tecpetrol S.A.',
//                             jrZX6L2IPFcaCtASWP5Z: 'YPF Energía Eléctrica S.A.',
//                             l2435JO4FcUKwDspPKJF: 'Vientos Punta Alta S.A.',
//                             pJ9GC06IroBFzhkPaNiw: 'Aes Argentina Generacion S.A.',
//                             plYoOZYGFsYDIm8CQla4: 'Metroenergía S.A.',
//                             u0P3YNfWnvbYMNGXRaJo: 'YPF-EE COMERCIALIZADORA S.A.U.',
//                             uHY3KFCJDoM45nSCOVaS: 'Gas Meridional S.A.',
//                             ufu7svjTKCtV3YnptDGv: 'Compañia Inversora de Energía S.R.L.',
//                             w6HOnA9ekexEymAidEB5: 'Energía Federal S.A.S.',
//                             xO2hfYAT0BZVTWxAjbPm: 'Natural Energy S.A.',
//                             yh3EkrWOfLGOINR4g3vG: 'Trafigura Argentina S.A.',
//                             meUan3C1oNcgwtdgYN8a: 'Gasener S.R.L.',
//                             J2Uke8yJKnGXohmLMWFs:  'Energy Traders S.A.',
//                             KuowmWDISjgUXmhalYoi:	'Pan American Energy S.L.',
//                             M75rWUBD8ML8tUXHF3Gp:	'Orazul Energy Southern Cone S.R.L.',
//                             MPUKrWGSktGz68p28GEO:	'Rafael G. Albanesi S.A.',
//                             T2PhwjXooe0kqw3QJUHF:	'Pluspetrol S.A.',
//                             UMRmK2iEBRMBEMCJhx2T:	'Genneia S.A.',
//                             VywgkOqbGxuWQxbc7SYE:	'AMG Energía S.A.',
//                             iCqdGEKyH9pvzbtSDGce:	'Grupo La Horqueta S.R.L.',
//                             vERCUnzrQDr0BqVGpdfQ:	'ABC Energía S.A.'
//     }
//
//
//     const buyType = props.buyRequest.buyType
//
//     const fileName = 'Conecto-Energía[' + props.buyRequest.id + ']Ronda-' + props.selectedProcess.round
//     if (props.product === 'gas') {
//         switch (buyType.type) {
//             case 'annual':
//                 var title = [['Número de Oferta','Fecha','Oferente', 'Precio Invierno', 'Precio Verano','Precio Promedio', props.selectedProcess.acceptsDifferentPayTerm && 'Plazo de Pago']]
//
//                 props.bidList.map((bid: DecryptedBid,bidNumber) => {
//                     const offer = bid.offer as AnnualOffer
//                     offer && title.push( [
//                         bidNumber + 1,
//                         dateToBidFormat(bid.date),
//                         companiesString[bid.sellerCompanyId],
//                         offer.winterPrice.toFixed(2),
//                         offer.summerPrice.toFixed(2),
//                         computeAnnualWeightedAveragePrice(props.buyRequest, offer).toFixed(2),
//                         props.selectedProcess.acceptsDifferentPayTerm && bid.offer.payTermDays]
//                     )
//                 }
//                 )
//                 break;
//             case 'monthly':
//                 var title = [['Número de Oferta','Fecha', 'Oferente',"Precio Promedio",'Mes','Demanda Mensual','Precio Mensual', props.selectedProcess.acceptsDifferentPayTerm && 'Plazo de Pago']]
//                 props.bidList.map((bid: DecryptedBid,bidNumber) => {
//                         // const [company,loadingCompany] = useCompany(bid.sellerCompanyId)
//                         // const businessName = company && company.businessName
//                         // console.log(businessName)
//                     const offer = bid.offer as MonthlyOffer
//                     mapMonthlyCurveWithName(
//                         props.buyRequest.supplyStartDate,
//                         offer.priceCurve,
//                         (name, value, index) =>
//                             title.push([
//                                 bidNumber + 1,
//                                 bid.date.toString().substring(16, 24),
//                                 companiesString[bid.sellerCompanyId],
//                                 computeMonthlyWeightedAveragePrice(props.buyRequest, offer).toFixed(2),
//                                 name,
//                                 offer.monthlyCurve[index],
//                                 value,
//                                 props.selectedProcess.acceptsDifferentPayTerm && bid.offer.payTermDays
//                             ])
//                     )
//                     }
//                 )
//                 break;
//             case 'spot':
//                 var title = [['Número de Oferta','Fecha', 'Oferente', 'Precio', props.selectedProcess.acceptsDifferentPayTerm && 'Plazo de Pago']]
//                 props.bidList.map((bid: DecryptedBid,bidNumber) => {
//                         // const [company,loadingCompany] = useCompany(bid.sellerCompanyId)
//                         // const businessName = company && company.businessName
//                         // console.log(businessName)
//                     const offer = bid.offer as SpotOffer
//                     title.push([
//                             bidNumber + 1,
//                             bid.date.toString().substring(16, 24),
//                             companiesString[bid.sellerCompanyId],
//                             offer.price.toFixed(2),
//                             props.selectedProcess.acceptsDifferentPayTerm && bid.offer.payTermDays
//                         ]
//                     )
//                     }
//                 )
//                 break;
//         }
//     } else if (props.product === 'electricity') {
//         switch (props.buyRequest.buyType.type) {
//             case 'thermal':
//                 var title = [['Número de Oferta','Fecha', 'Oferente', 'Costo de O&M (US$/MWh)', 'Costo Mensual de Potencia (US$/MW-mes)', 'Plazo (Años)', 'Mes de Inicio de Suministro']]
//                 props.bidList.map((bid: DecryptedBid,bidNumber) => {
//
//                     const offer = bid.offer as ThermalOffer
//                     offer && title.push([
//                                 bidNumber + 1,
//                                 bid.date.toString().substring(16, 24),
//                                 companiesString[bid.sellerCompanyId],
//                                 offer.fixedCost.toFixed(2),
//                                 offer.variableCost.toFixed(2),
//                                 offer.term,
//                                 offer.startSupplyMonth
//                             ]
//                         )
//                     }
//                 )
//                 break;
//             case ('mater' || 'onsite'):
//                 var title = [['Número de Oferta','Fecha', 'Oferente', 'Precio de Energía (US$/MWh)', 'Plazo (Años)', 'Mes de Inicio de Suministro']]
//                 props.bidList.map((bid: DecryptedBid,bidNumber) => {
//
//                         const offer = bid.offer as ThermalOffer
//                         offer && title.push([
//                                 bidNumber + 1,
//                                 bid.date.toString().substring(16, 24),
//                                 companiesString[bid.sellerCompanyId],
//                                 offer.variableCost.toFixed(2),
//                                 offer.term,
//                                 offer.startSupplyMonth
//                             ]
//                         )
//                     }
//                 )
//                 break;
//         }
//     }
//
//     return title && <CSVLink
//         data={title}
//         filename={fileName}
//         target="_blank"
//     ><Button variant="outline-info" size="sm"><GetAppIcon/> Excel/CSV</Button>
//     </CSVLink>
// }
