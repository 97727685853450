import { Maybe, showIf } from "functional/lib/Maybe"
import { none } from "functional/lib/core"
import React, { useState } from "react"
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom"
import { Admin } from "./pages/admin/Admin"
import { ConectoNavBar } from "./ConectoNavBar"
import { LoginPage } from "./Login"
import { MarketData } from "./MarketData"
import { Index } from "./landing"
import { SignUpForm } from "./landing/pages/account/signup/signup"
import { Buyers } from "./landing/pages/buyers/index.jsx"
import { TermCondition } from "./landing/pages/term-condition"
import { Consultants } from "./landing/pages/consultant/index.jsx"
import { Sellers } from "./landing/pages/sellers/index.jsx"
import { BuyRequestDraft } from "./model/Model"
import { AccountPage } from "./pages/Account"
import { MarketPlacePage } from "./pages/marketplace/MarketPlacePage"
import { BuyRequestCreateFlow } from "./buy-request-flow/BuyRequestCreateFlow"
import { User } from "./model/User"
import { RState } from "./functional/react/RState"
import { UserDetailPage } from "./pages/admin/user/detail"
import { CompanyDetailPage } from "./pages/admin/company/detail"
import { BuyRequestDetailPage } from "./pages/admin/buy-request/detail"
import { ProcessDetailPage } from "./pages/admin/process/detail"
import { BidDetailPage } from "./pages/admin/bid/detail"
import { CompanEditPage, CompanyCreatePage } from "./pages/admin/company/company-form"
import { MarketplaceBuyRequest } from "./pages/marketplace/MarketplaceBuyRequest"
import { appPaths } from "./pages/navigation"



export const ConectoRouting = (
  props: {
    user: Maybe<User>
    training: RState<boolean>
  }
) => {

  const currentUser = props.user
  const [requestDraft, setRequestDraft] = useState<Maybe<BuyRequestDraft>>(none)

  const isAdmin = currentUser?.roleList?.includes('admin') ?? false

  return <BrowserRouter>
    <React.Fragment />
    <ConectoNavBar 
      training={props.training}
    />
    <Routes>

      {
      showIf(isAdmin)(
        <>
          <Route 
            path={appPaths.admin}
            element={<Admin />}
          />
          <Route
            path={appPaths.user(":id")}
            element={<UserDetailPage/>}
          />
          <Route
            path={appPaths.company(":id").edit}
            element={<CompanEditPage/>}
          />
          <Route
            path={appPaths.companyNew}
            element={<CompanyCreatePage/>}
          />
          <Route
            path={appPaths.company(":id").detail}
            element={<CompanyDetailPage/>}
          />
          <Route
            path={appPaths.buyRequest(":type",":id").detail}
            element={<BuyRequestDetailPage/>}
          />
          <Route
            path={appPaths.buyRequest(":type", ":buyRequestId").process(":id").detail}
            element={<ProcessDetailPage/>}
          />
          <Route
            path={appPaths.buyRequest(":type", ":buyRequestId").process(":processId").bid(":id")}
            element={<BidDetailPage/>}
          />
          
        </>
      ) 
      }

      <Route 
        path={appPaths.marketplace}
        element={
          currentUser ?
            <MarketPlacePage
              currentUser={currentUser}
              setRequestDraft={setRequestDraft}
            /> :
           <Navigate to={appPaths.login} replace />
        }
      />

      <Route 
        path={appPaths.marketplaceBuyRequest(":type", ":id")}
        element={
          <MarketplaceBuyRequest/>
        }
      />

      <Route 
        path={appPaths.buyRequestForm}
        element={
          currentUser ?
            <BuyRequestCreateFlow draft={requestDraft} /> :
            <Navigate to={appPaths.login} replace />
        }
      />

      <Route 
        path={appPaths.login}
        element={
          currentUser ?
            <Navigate to="/marketplace" replace /> :
            <LoginPage />
        }
      />

      <Route 
        // root or /home
        path="/"
        element={<Index/>} 
      />

      <Route
        path={appPaths.home}
        element={<Navigate to="/" replace/>}
      />

      <Route 
        path={appPaths.buyers}
        element={<Buyers/>} 
      />

      <Route 
        path={appPaths.termCondition}
        element={<TermCondition/>} 
      />

      <Route 
        path={appPaths.sellers}
        element={<Sellers/>} 
      />
      <Route 
        path={appPaths.consultants}
        element={<Consultants/>} 
      />
      {
          Maybe.map(currentUser)(user =>
            <>
              <Route 
                path={appPaths.marketData}
                element={<MarketData currentUser={user} />}
              />
              <Route 
                path={appPaths.account}
                element={<AccountPage currentUser={user}/>} 
              />
            </>  
          )
      }
      
      <Route 
        path={appPaths.signUp}
        element={<SignUpForm/>} 
      />
    </Routes>
  </BrowserRouter>
}

