import firebase from "firebase"


// function decrypter(file, fileName, position, rsaKey, fileType, product, buyRequestId, processId, docId){
//     const key = [ 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16 ];
//     const decryptedFile = decryptFile(file,key)
// }

import { IsString } from "@sinclair/typebox/value/guard"
import { Async } from "functional/lib/Async"
import { Unit, none, throws } from "functional/lib/core"
import { buildPathForStorage } from "../Utilities"
import { AppCall, appCallTraining } from "../context/AppCall"
import { Id } from "../model/Model"
import { User } from "../model/User"
import { FileData } from "../model/common"
import { conectoSymetric, conectoSymetricKeyGenerate, hashSha256 } from "./encryption"
import { userDataUpdate } from "./user"

export const isValidFileType = 
  (fileType: string): boolean =>
  fileType.match(
    [
      "image/.*",
      "application/pdf",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/acad",
      "image/vnd.dwg"
    ].join('|')
  ) !== null

export const fileUploadToCloudFirestore = (
  args: {
    file: File, 
    pathname: string, 
    user: User, 
    saveAs: string
  }
) =>
  AppCall.do(async _ => {

    if (!isValidFileType(args.file.type)) {
      console.error('Invalid file type')
      throw new Error('Invalid file type')
    }

    const storageRef = firebase.storage().ref()

    const uploadTask = storageRef.child(`userUploadedLogos/${args.pathname}`).put(args.file);

    // Register three observers:
    // 1. 'state_changed' observer, called any time the state changes
    // 2. Error observer, called on failure
    // 3. Completion observer, called on successful completion
    uploadTask.on(
      'state_changed', 
      snapshot => {
        // Observe state change events such as progress, pause, and resume
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log('Upload is ' + progress + '% done');
        switch (snapshot.state) {
          case firebase.storage.TaskState.PAUSED: // or 'paused'
            console.log('Upload is paused')
            break
          case firebase.storage.TaskState.RUNNING: // or 'running'
            console.log('Upload is running')
            break
        }
      }
    )

    await uploadTask

    const downloadURL = await uploadTask.snapshot.ref.getDownloadURL()
    
    await _(
      userDataUpdate({
        userId: args.user.id, 
        newData: { 
          desiredCompanyData: { 
            ...args.user.desiredCompanyData, 
            [args.saveAs]: { 
              name: args.pathname, 
              link: downloadURL, 
              status: "pending" 
            } 
          } 
        }
      })
    )

  })



export const fileEncryptAndUpload = 
  (
    file: File,
    remotePath: string,
    progress: (encryption: number, upload: number) => void = () => {}
  ): Async<FileData> => 
  async () => {

    if (!isValidFileType(file.type)) {
      console.error('Invalid file type')
      throw new Error('Invalid file type')
    }

    const content = await fileReadBytes(file)()

    const sha256 = await hashSha256(content)()

    const encrypted = await fileEncrypt(
      file.name, 
      content,
      (fraction) => progress(fraction, 0.0)
    )()

    const remoteUrl = await fileUploadToFirestore(
      remotePath,
      encrypted.content,
      fraction => progress(1.0, fraction)
    )()

    return {
      name: encrypted.name,
      link: remoteUrl,
      aesEncryptionKey: encrypted.key,
      sha256: sha256
    }
  }


export const fileUploadToFirestore = (
  path: string,
  content: ArrayBuffer,
  onProgress: (fraction: number) => void = () => {}
): Async<string> => 
  async () => {
    const uploadTask = 
      firebase
        .storage()
        .ref(path)
        .put(
          content, 
          {
            contentType: 'application/octet-stream'
          }
        )

    uploadTask.on(
      'state_changed',
      snapshot => {
        onProgress(snapshot.bytesTransferred / snapshot.totalBytes)
      }
    )

    await uploadTask

    return await uploadTask.snapshot.ref.getDownloadURL()
  }

export const downloadBytes = 
  (url: string) => 
  async () => {
    const response = await fetch(url)
    const bytes = await response.arrayBuffer()
    return bytes
  }

export const fileDownloadAndDecrypt = (
  fileData: FileData,
  buyRequestId: Id,
) => 
  AppCall.do<FileData>(async _ => {
  
    const bytes = await downloadBytes(fileData.link)()

    const rawKey = fileData.aesEncryptionKey ?? throws("No key")
    const expectedSha256 = fileData.sha256

    const key = 
      IsString(rawKey) ? rawKey :
      Buffer.from(rawKey).toString('base64') 

    const symetric = await conectoSymetric(key)()

    const decrypted = await symetric.decrypt(bytes)()
  
    if (expectedSha256 !== none) {
      const hash = await hashSha256(decrypted)()
      if (hash !== expectedSha256) {
        console.error(`SHA256 does not match for file ${fileData.name}`)
      }
    }

    const url = await _(
      fileUploadDecrypted(
        fileData.name, 
        decrypted, 
        buyRequestId
      )
    )

    return {
      link: url,
      name: fileData.name
    }
  })

export const fileUploadDecrypted = (
  fileName: string,
  data: ArrayBuffer, 
  buyRequestId: Id,
) => 
  AppCall.do<string>(async _ => {

    const training = await _(appCallTraining)

    const path = buildPathForStorage(
      training, 
      "Electricity", 
      buyRequestId, 
      'Decrypted', 
      fileName
    )()
    
    const uploadTask = firebase.storage().ref(path).put(data)

    // remove?
    uploadTask.on(
      'state_changed', 
      snapshot => {
        switch (snapshot.state) {
          case firebase.storage.TaskState.PAUSED: // or 'paused'
            break;
          case firebase.storage.TaskState.RUNNING: // or 'running'
            break;
        }
      }
    )  

    await uploadTask

    return await uploadTask.snapshot.ref.getDownloadURL()
  })
    
    

export type DecryptedFile = {
  name: string
  content: ArrayBuffer
}

export type EncryptedFile = {
  name: string
  key: string
  content: ArrayBuffer
}

export const fileEncrypt = 
  (
    fileName: string,
    data: ArrayBuffer,
    onProgress: (fraction: number) => void = () => {},
  ): Async<EncryptedFile> => 
  async () => {

    const key = await conectoSymetricKeyGenerate()

    const symetric = await conectoSymetric(key)()

    const encrypted = await symetric.encrypt(data)()

    return {
      name: fileName,
      key: key,
      content: encrypted
    }
  }


export const fileReadBytes = (
  file: File
): Async<ArrayBuffer> => 
  async () => {
  return new Promise(
      (resolve, reject) => {
        try {
          const reader = new FileReader()
          reader.onload = () => {

            const result = reader.result

            const array = 
              result === null ? new Uint8Array(0) :
              IsString(result) ? Buffer.from(result, "utf8") : 
              result

            resolve(array)
          }
          reader.readAsArrayBuffer(file)
        } catch (e) {
          reject(e)
        }
      }
    )
  }