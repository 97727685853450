import { Skeleton } from "@material-ui/lab"
import { Maybe } from "functional/lib/Maybe"
import { matchEnum } from "functional/lib/match"
import { FlexCol, FlexRow } from "../../../components/Flexbox"
import { StringChoiceEditor } from "../../../components/editors/string"
import { RState } from "../../../functional/react/RState"
import { useProcessList, useSupplyPoint } from "../../../hooks/ConectoHooks"
import { Id } from "../../../model/Model"
import { BuyRequestType } from "../../../model/buyRequest/BuyRequest"
import { BuyRequestStatus } from "../../../model/buyRequest/BuyRequestStatus"
import { ConectoBadge } from "../components"


export const BuyRequestStatusView = (
  props: {
    status: BuyRequestStatus
  }
) => {

  return <ConectoBadge
    color={
      matchEnum(props.status?.type ?? "pending")({
        pending: "#abab1a",
        open: "green",
        waitingAnswer: "#00b0ff",
        assigned: "blue",
        deserted: "red",
      })
    }
    children={
      matchEnum(props.status?.type ?? "pending")({
        assigned: "Asignada",
        deserted: "Desierta",
        open: "Abierta",
        pending: "Pendiente",
        // preAssigned: "Pre-Asignada",
        waitingAnswer: "Esperando Respuesta"
      })
    }
  />
}


export const SupplyPointView = (
  props: {
    companyId: Id
    id: Id
  }
) => {

  const [point, loading, error] = useSupplyPoint(props.companyId, props.id)

  return <FlexRow
    justifyContent="flex-start"
  >
    {
      loading ? <Skeleton width={100}/> :
      point?.name ?? "-"
    }
  </FlexRow>
}

export const RoundView = (
  props: {
    type: BuyRequestType
    id: Id
  }
) => {

  const [list, loading, error] = useProcessList(props.id, props.type)

  return <FlexRow
    justifyContent="flex-start"
  >
    {list?.length ?? <Skeleton width={30}/>}
  </FlexRow>
}

export const BuyRequestStatusFilter = (
  props: {
    label: string
    state: RState<Maybe<BuyRequestStatus["type"] | "all">>
  }
) => {

  return <FlexCol
    alignItems="flex-start"
    style={{
      width: 200
    }}
  >
    <FlexRow
      paddingX={8}
      paddingY={4}
    >
      {props.label}
    </FlexRow>
    <StringChoiceEditor
      options={[
          ["all", "Todos"],
          ["pending", "Pendiente"],
          ["open", "Abierta"],
          ["waitingAnswer", "Esperando Respuesta"],
          ["assigned", "Asignada"],
          // ["preAssigned", "Pre-Asignada"],
          ["deserted", "Desierta"]
      ]}
      state={props.state}
    />
  </FlexCol>
}
