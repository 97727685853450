import { Id } from "../model/Model"
import { BuyRequestType } from "../model/buyRequest/BuyRequest"


export const appPaths = {

  marketplace: "/marketplace",

  marketplaceBuyRequest: (type: string, id: Id) => `/marketplace/${type}/${id}`,

  buyRequestForm: "/requestForm",
  login: "/login",
  home: "/home",
  buyers: "/compradores",
  sellers: "/vendedores",
  consultants: "/consultores",
  marketData: "/informes",
  account: "/account",
  signUp: "/form",
  termCondition: "/terminos-y-condiciones",
  admin: "/admin",

  user: (id: Id) => `/user/${id}`,

  company: (id: Id) => ({
    detail: `/company/${id}`,
    edit: `/company/${id}/edit`
  }),

  companyNew: "/company-new",

  buyRequest: (type: string, buyRequestId: Id) => {
    const prefix = `/buy-request/${type}/${buyRequestId}`
    return {
      detail: prefix,
      process: (id: Id) => {
        const processPrefix = `${prefix}/process/${id}`
        return ({
          detail: processPrefix,
          bid: (bidId: Id) => `${processPrefix}/bid/${bidId}`
        })
      },

    }
  },

}
