import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';


// className "owl-theme" is optional

const GasCarrousel = () => {
    return (
         <OwlCarousel 
              className='owl-theme' 
            //   loop 
              margin={30} 
              items={5}
              slideBy={5}
              autoplay={true}
              rewind={true}
              dots={false}
            //   nav
         >
        <div className='item'>
          <div className="clients-logo">
          <img className="img-fluid" src={require(`../assets/images/client/pae.png`)} alt="" />
          </div>
        </div>
        <div class='item'>
        <div className="clients-logo">
        <img size="30" className="img-fluid" src={require(`../assets/images/client/tecpetrol.png`)} alt="" />
        </div>
        </div>
        <div class='item'>
        <img className="img-fluid" src={require(`../assets/images/client/pampa.png`)} alt="" />
        </div>
        <div class='item'>
        <img className="img-fluid" src={require(`../assets/images/client/pluspe.png`)} alt="" />
        </div>
        <div class='item'>
        <img className="img-fluid" src={require(`../assets/images/client/total.png`)} alt="" />
        </div>
        <div class='item'>
        <img className="img-fluid" src={require(`../assets/images/client/vista.png`)} alt="" />
        </div>
        <div class='item'>
        <img className="img-fluid" src={require(`../assets/images/client/enap.png`)} alt="" />
        </div>
        <div class='item'>
        <img className="img-fluid" src={require(`../assets/images/client/pcr.png`)} alt="" />
        </div>
        <div class='item'>
        <img className="img-fluid" src={require(`../assets/images/client/exxon.png`)} alt="" />
        </div>
        <div class='item'>
        <img className="img-fluid" src={require(`../assets/images/client/roch.png`)} alt="" />
        </div>
        <div class='item'>
        <img className="img-fluid" src={require(`../assets/images/client/aconcagua.png`)} alt="" />
        </div>
        <div class='item'>
        <img className="img-fluid" src={require(`../assets/images/client/cgc.png`)} alt="" />
        </div>
        <div class='item'>
        <img className="img-fluid" src={require(`../assets/images/client/metroenergia.png`)} alt="" />
        </div>
        <div class='item'>
        <img className="img-fluid" src={require(`../assets/images/client/naturgy.png`)} alt="" />
        </div>
        <div class='item'>
        <img className="img-fluid" src={require(`../assets/images/client/saesa.png`)} alt="" />
        </div>
        <div class='item'>
        <img className="img-fluid" src={require(`../assets/images/client/alpes.png`)} alt="" />
        </div>
        <div class='item'>
        <img className="img-fluid" src={require(`../assets/images/client/albanesi.png`)} alt="" />
        </div>
        <div class='item'>
        <img className="img-fluid" src={require(`../assets/images/client/corocotta.png`)} alt="" />
        </div>
        <div class='item'>
        <img className="img-fluid" src={require(`../assets/images/client/apahie.png`)} alt="" />
        </div>
        <div class='item'>
        <img className="img-fluid" src={require(`../assets/images/client/gasMeridional.png`)} alt="" />
        </div>
        <div class='item'>
        <img className="img-fluid" src={require(`../assets/images/client/cinergia.png`)} alt="" />
        </div>
        <div class='item'>
        <img className="img-fluid" src={require(`../assets/images/client/trafigura.png`)} alt="" />
        </div>
        <div class='item'>
        <img className="img-fluid" src={require(`../assets/images/client/energyTraders.png`)} alt="" />
        </div>
        <div class='item'>
        <img className="img-fluid" src={require(`../assets/images/client/enel.png`)} alt="" />
        </div>
        <div class='item'>
        <img className="img-fluid" src={require(`../assets/images/client/victorio.png`)} alt="" />
        </div>
        <div class='item'>
        <img className="img-fluid" src={require(`../assets/images/client/e-nova.png`)} alt="" />
        </div>
        <div class='item'>
        <img className="img-fluid" src={require(`../assets/images/client/amg.png`)} alt="" />
        </div>
        <div class='item'>
        <img className="img-fluid" src={require(`../assets/images/client/lahorqueta.png`)} alt="" />
        </div>
        <div class='item'>
        <img className="img-fluid" src={require(`../assets/images/client/gasPatagonia.png`)} alt="" />
        </div>
        <div class='item'>
        <img className="img-fluid" src={require(`../assets/images/client/tranangas.png`)} alt="" />
        </div>
        <div class='item'>
        <img className="img-fluid" src={require(`../assets/images/client/delta.png`)} alt="" />
        </div>
        <div class='item'>
        <img className="img-fluid" src={require(`../assets/images/client/genneia.png`)} alt="" />
        </div>
        <div class='item'>
        <img className="img-fluid" src={require(`../assets/images/client/gasmarket.png`)} alt="" />
        </div>
        <div class='item'>
        <img className="img-fluid" src={require(`../assets/images/client/ECS.png`)} alt="" />
        </div>
        <div class='item'>
        <img className="img-fluid" src={require(`../assets/images/client/ambar.png`)} alt="" />
        </div>
        <div class='item'>
        <img className="img-fluid" src={require(`../assets/images/client/fenix.png`)} alt="" />
        </div>
        <div class='item'>
        <img className="img-fluid" src={require(`../assets/images/client/gea.png`)} alt="" />
        </div>
        <div class='item'>
        <img className="img-fluid" src={require(`../assets/images/client/gasener.png`)} alt="" />
        </div>
        <div class='item'>
        <img className="img-fluid" src={require(`../assets/images/client/gea.png`)} alt="" />
        </div>
        <div class='item'>
        <img className="img-fluid" src={require(`../assets/images/client/we.png`)} alt="" />
        </div>
      </OwlCarousel>
    )
}

export default GasCarrousel;
