import Table from '@material-ui/core/Table'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import { makeStyles } from '@material-ui/core/styles'
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline"
import AttachFileIcon from "@material-ui/icons/AttachFile"
import EditIcon from "@material-ui/icons/Edit"
import GetAppIcon from '@material-ui/icons/GetApp'
import PrintIcon from "@material-ui/icons/Print"
import SaveIcon from "@material-ui/icons/Save"
import React, { useState } from "react"
import { Button, Card, Col, Collapse, Modal, Row, Spinner } from "react-bootstrap"
import { useBidsList, useCompany, useSellerBidsList } from "../hooks/ConectoHooks"
import { BooleanCheckEditor } from "../components/editors/boolean"
import {
  computeAnnualWeightedAveragePrice,
  dateToBidFormat,
  validaDateForBid,
  validaDateForCheck
} from "../Utilities"
import EnhancedTable from "../components/NewOfferTable"
import { AnnualOffer, Bid, DecryptedBid } from "../model/Bid"
import { Id } from "../model/Model"
import { GasBuyRequest } from '../model/buyRequest/GasBuyRequest'
import { List } from 'functional/lib/List'
import { Unit, none } from 'functional/lib/core'
import { Process } from '../model/Process'
import { BuyRequest, BuyRequestType } from '../model/buyRequest/BuyRequest'
import { RState } from '../functional/react/RState'
import { Maybe } from 'functional/lib/Maybe'
import { IO } from 'functional/lib/IO'
import { useAppUser } from '../context/App'
import { computeAnnualContractValue, computeContractValueAndFee, computeFee, computeContractValue } from '../utils/fee'
import { DownloadIcon, NewIcon, formatNumber, formatUsd } from '../pages/admin/detail-components'
import { FlexRow } from '../components/Flexbox'
import { dataToCsv, downloadTextFile } from '../utils/csv'
import { matchPartial } from 'functional/lib/match'
import { BidCsvColumn, DownloadBidCsvButton, bidCsvColumns, downloadBidCsv } from './csv'
import { buyRequestColumns } from '../pages/marketplace/BuyRequestListRow'
import { useAppAsynchronism } from '../context/AppCall'

export const AnnualBidTable = (
  props: {
    buyRequest: GasBuyRequest
    process: Process

    assignable: boolean
    // preAssignable: boolean

    selectedBidId?: RState<Maybe<Id>>
    // selectedToPreAssign?: RState<List<Id>>
  }
) => {

  const user = useAppUser()

  const [bidList, loading, error] = 
    user?.roleList?.includes('buyer') ? 
      useBidsList(
        props.buyRequest.type, 
        props.buyRequest.id ?? "", 
        props.process.id ?? ""
      ) : 
      useSellerBidsList(
        props.buyRequest.type, 
        props.buyRequest.id ?? "", 
        props.process.id ?? "", 
        user?.training ?? false, 
        user?.companyIdList?.[0] ?? ""
      )
      
  const bidTable = bidList?.map(bid =>
    <AnnualBidRow
      key={bid.id}
      buyRequest={props.buyRequest}
      process={props.process}
      bid={bid}

      assignable={props.assignable}

      assigned={bid.id === props.selectedBidId?.value}
      onAssigned={props.selectedBidId?.apply(() => bid.id) ?? IO.noOp}

      // preAssignable={props.preAssignable}

      // selectedToPreAssign={props.selectedToPreAssign}
    />
  )

  return <>
    <Table className='text text-secondary text-center'>
      <thead>
        <tr>
          <th align="center">Fecha</th>
          <th align="center">Oferente</th>
          <th align="center">Invierno</th>
          <th align="center">Verano</th>
          <th align="center">Promedio Anual</th>
          <th align="center">Valor de Contrato</th>
          {props.process.type == "tender" && props.process.acceptsDifferentPayTerm ?
            <th align="center">Plazo de Pago</th> : null}
          <th align="center">Válida hasta</th>
          <th align="center"><AttachFileIcon /></th>
          {props.assignable ? <th align="center" className='text-success'>Seleccionar</th> : null}
          {/* {props.preAssignable ? <th align="center" className='text-info'>Seleccionar</th> : null} */}
        </tr>
      </thead>
      {error && <strong>Error: {JSON.stringify(error)}</strong>}
      {loading && <tbody>
        <tr>
          <td>Cargando...</td>
        </tr>
      </tbody>}
      {bidList && <tbody>{bidTable}</tbody>}
    </Table>
    {user?.roleList?.includes('seller') && bidList && bidList.length == 0 && <Row><small>Tus Ofertas enviadas recién estarán visibles cuando la Ronda sea desencriptada</small></Row>}
    {user?.roleList?.includes('buyer') && bidList && bidList.length == 0 && <Row><small>Por el momento esta Ronda no tiene Ofertas</small></Row>}
    <FlexRow
      justifyContent="flex-end"
      padding={8}
    >
      <DownloadBidCsvButton
        buyRequest={props.buyRequest}
        process={props.process}
        bidList={bidList ?? []}
        columns={[
          bidCsvColumns.id,
          bidCsvColumns.date,
          bidCsvColumns.sellerCompany,
          bidCsvColumns.summerPrice,
          bidCsvColumns.winterPrice,
          bidCsvColumns.annualAverage,
          bidCsvColumns.contractValue,
          bidCsvColumns.payTermDays,
        ]}
      />
    </FlexRow>
    
  </>
}

  

const AnnualBidRow = (
  props: {
    buyRequest: GasBuyRequest
    process: Process
    bid: Bid

    assignable: boolean

    assigned: boolean
    onAssigned: IO<Unit>

    editRepeated?: boolean
    repeated?: Id[]
    setRepeated?: ([]) => void
  }
) => {
  const [open, setOpen] = useState(false)
  const bid = props.bid
  const encryptedOffer = bid.encrypted && props.process.type === "tender"
  const decryptedBid = bid as DecryptedBid
  const offer = encryptedOffer ? none : decryptedBid.offer as AnnualOffer
  const encryptedText = "Encriptado"

  const [company, loading, error] = useCompany(bid.encrypted ? none : decryptedBid.sellerCompanyId)

  const { contractValue, fee } = computeContractValueAndFee(props.buyRequest, offer)

  return (
    !props.editRepeated && props.repeated?.includes(bid.id)) ? <></> :
    <>
    <tr style={{ backgroundColor: props.repeated?.includes(bid.id) ? '#e1e1e1' : none }} key={bid.id}>
      {props.editRepeated && <TableCell align={'center'}>
        <BooleanCheckEditor
          type="checkbox"
          value={props.repeated?.includes(bid.id) ?? false}
          onValueChanged={(value) => {

            const repeated = props.repeated ?? []

            if (value && !repeated.includes(bid.id)) { 
              props.setRepeated?.(repeated.concat(bid.id)) 
            }

            else if (!value && repeated.includes(bid.id)) { 
              props.setRepeated?.(repeated.filter(bidId => bidId !== bid.id)) 
            }
          }
          } />
      </TableCell>}
      <TableCell align={'center'}>{dateToBidFormat(bid.date)}</TableCell>
      <TableCell align={'center'}>{bid.encrypted ? encryptedText : company ? company.displayName : ""}</TableCell>
      <TableCell align={'center'}>{encryptedOffer ? encryptedText : offer?.winterPrice.toFixed(2)}</TableCell>
      <TableCell align={'center'}>{encryptedOffer ? encryptedText : offer?.summerPrice.toFixed(2)}</TableCell>
      <TableCell align={'center'}>{encryptedOffer ? encryptedText : computeAnnualWeightedAveragePrice(props.buyRequest, offer ?? none).toFixed(2)}</TableCell>
      <TableCell align={'center'}>{encryptedOffer ? encryptedText : formatUsd(contractValue)}</TableCell>
      {props.process.type == "tender" && props.process.acceptsDifferentPayTerm ? 
        <TableCell align={'center'}>{encryptedOffer ? encryptedText : offer?.payTermDays}</TableCell> : 
        null
      }
      <TableCell
        align={'center'}
        className={!bid.encrypted && validaDateForCheck(props.process.closeDate, offer?.validTermDays) ? 'text-danger' : none}>
        {encryptedOffer ? encryptedText : validaDateForBid(props.process.closeDate, offer?.validTermDays)}
      </TableCell>
      
      <TableCell align={'center'}><AddCircleOutlineIcon onClick={() => setOpen(!open)} /></TableCell>
      {props.assignable ? <TableCell align={'center'}>
        <BooleanCheckEditor
          type="checkbox"
          disabled={validaDateForCheck(props.process.closeDate, offer?.validTermDays)}
          onValueChanged={value => props.onAssigned()}
          value={props.assigned}
        /></TableCell> : null}
      {/* {props.preAssignable ? 
        <TableCell>
          <BooleanCheckEditor
            type="checkbox"
            value={props.selectedToPreAssign?.value?.includes(bid.id) ?? false}
            disabled={validaDateForCheck(props.process.closeDate, offer?.validTermDays)}
            onValueChanged={(value) => {
              props.selectedToPreAssign?.apply(
                previous =>
                  previous.includes(bid.id) ?
                    previous.filter(bidId => bidId !== bid.id) :
                    previous.concat(bid.id)
              )?.()
            }}
          />
        </TableCell> : null
      } */}
    </tr>
    <Collapse in={open}>
      <TableRow>
        <TableCell colSpan={4}>
          <Card>
            <Table className='text-center text-secondary' size="small">
              <thead>
                {offer?.decryptedPdfFiles ? <TableRow>
                  <td colSpan={5}>
                    <Table size='small'>
                      <thead>
                        <TableRow>
                          <TableCell>PDF's</TableCell>
                        </TableRow>
                      </thead>
                      <tbody>
                        {offer.decryptedPdfFiles.map(file => <TableRow key={file.link}><a target="_blank" href={file.link}>{file.name}</a></TableRow>)}
                      </tbody>
                    </Table>
                  </td>
                </TableRow> : (offer?.encryptedPdfFiles ? <tr className='text-info'><td>Información Encriptada</td></tr>
                  : <tr className='text-secondary'><td>No se adjuntaron Archivos</td></tr>)}
              </thead>
            </Table>
          </Card>
        </TableCell>
      </TableRow>
    </Collapse>
  </>
}


const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
})

export const AnnualModal = (
  props: {
    buyRequest: GasBuyRequest
    process: Process
    bidList: List<Bid>
    show: boolean
    setShow: (value: boolean) => void
  }
) => {
  const classes = useStyles()
  const [editRepeated, setEditRepeated] = useState(false)
  const [repeated, setRepeated] = useState([])
  const handleClose = () => props.setShow(false)

  return <Modal show={props.show} size={'xl'} onHide={handleClose}>
    <Row className={'justify-content-center'}>
      <Col className={'text-center'}>
        <h3 className={'text-secondary'}>Ronda {props.process.round}</h3>
      </Col>
    </Row>
    <Row className={'justify-content-center'}>
      <Col>
        <Row className={'justify-content-center'}>
          <Col className={'text-center'}>
            {!editRepeated && <Button variant={'outline-secondary'} onClick={() => setEditRepeated(!editRepeated)}><EditIcon style={{ fontSize: 17 }} /> {repeated.length === 0 ? 'Quitar' : 'Editar'} repetidas [{repeated.length}]</Button>}
            {editRepeated && <Button variant={'outline-success'} onClick={() => setEditRepeated(!editRepeated)}><SaveIcon style={{ fontSize: 17 }} /> Guardar repetidas [{repeated.length}]</Button>}
          </Col>
          {/*<Col className={'text-center'}>*/}
          {/*    <h5> <b> </b> Ofertas repetidas</h5>*/}
          {/*</Col>*/}
        </Row>
      </Col>
      <Col>
        <Row className={'justify-content-center'}>
          <Button variant={'outline-secondary'}><PrintIcon />Imprimir</Button>
          <Button variant={'outline-secondary'}><GetAppIcon />Descargar PDF</Button>
        </Row>
      </Col>
    </Row>
    <br />
    <EnhancedTable buyRequest={props.buyRequest} process={props.process} bidList={props.bidList} />
    {/*<TableContainer component={Paper}>*/}
    {/*    <Table className={classes.table} size="small" aria-label="a dense table">*/}
    {/*        <TableHead>*/}
    {/*            <TableRow>*/}
    {/*                {editRepeated && <TableCell align="center" className={'text-success'}><EditIcon style={{fontSize: 17}}/> </TableCell>}*/}
    {/*                <TableCell align="center">*/}
    {/*                    <TableSortLabel*/}
    {/*                        active={true}*/}
    {/*                        direction={'asc'}*/}
    {/*                    >Oferente*/}
    {/*                    </TableSortLabel>*/}
    {/*                </TableCell>*/}
    {/*                <TableCell align="center">Invierno</TableCell>*/}
    {/*                <TableCell align="center">Verano</TableCell>*/}
    {/*                <TableCell align="center">Promedio Anual</TableCell>*/}
    {/*                <TableCell align="center">Válida hasta</TableCell>*/}
    {/*                <TableCell align="center">Hora</TableCell>*/}
    {/*                <TableCell align="center"><AttachFileIcon/></TableCell>*/}
    {/*            </TableRow>*/}
    {/*        </TableHead>*/}
    {/*        <TableBody>*/}
    {/*            {props.bidList?.map(bid => <AnnualBidRow buyRequest={props.buyRequest} process={props.process} bid={bid} editRepeated={editRepeated} repeated={repeated} setRepeated={setRepeated}*/}
    {/*                                              // assignable={} assigned={} setAssigned={} preAssignable={} selectedToPreAssign={} setSelectedToPreAssign={}*/}
    {/*            />)}*/}
    {/*        </TableBody>*/}
    {/*    </Table>*/}
    {/*</TableContainer>*/}
  </Modal>
}


