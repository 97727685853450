import firebase, { firestore } from "firebase"
import { Unit, id, none, pipe, throws } from "functional/lib/core"
import { AppCall, appCallBuyRequestCollection, appCallProcessCollection, appCallTraining } from "../context/AppCall"
import { BuyRequestDraft, Id, Product, SentMail } from "../model/Model"
import { Company } from "../model/Company"
import { Process, ProcessT } from "../model/Process"
import { BuyRequest, BuyRequestT, BuyRequestType } from "../model/buyRequest/BuyRequest"
import { validateRequestAndProcess } from "../Validation"
import { Value } from "@sinclair/typebox/value"
import { RSA_KEY_LENGTH_BITS, normalizeForFirebase } from "./client"
import { dbDraftCollection, dbUserCollection } from "../hooks/ConectoHooks"
import { dateToMailFormat } from "../Utilities"
import { List } from "functional/lib/List"
import { Maybe } from "functional/lib/Maybe"
import { matchEnum } from "functional/lib/match"
import { conectoAsymetricDecrypt } from "./encryption"
import { BuyRequestStatus } from "../model/buyRequest/BuyRequestStatus"
import { User } from "../model/User"
import { Async } from "functional/lib/Async"
import { userList } from "./user"
import { processGetInvitedUserListFromBuyRequest } from "./process"
import { mailSendBatch } from "./mail"

export const buyRequestGet = 
  (product: "gas" | "electricity") =>
  (id: Id): AppCall<Maybe<BuyRequest>> =>
  AppCall.do(async _ => {
    const collection = await _(appCallBuyRequestCollection(product))
    const data = await collection.doc(id).get()
    return data.exists ? data.data() : none
  })


export const buyRequestProcessLastGet = 
  (product: "gas" | "electricity") =>
  (buyRequestId: Id): AppCall<{ id: Id, data: Process }> =>
  AppCall.do(async _ => {
    const collection = await _(appCallProcessCollection(product, buyRequestId))
    const result = await collection.orderBy("round", "desc").limit(1).get()
    const data = result.docs.at(0) ?? throws("No process found")
    return {
      id: data.id,
      data: data.data()
    }
  })

export const buyRequestCreate = (
  args: {
    placerId: Id
    training: boolean
    company: Maybe<Company>
    buyRequest: BuyRequest
    process: Process
    password: string
    product: BuyRequestType
  }
): AppCall<Unit> => 
  AppCall.do(async _ => {

    // refresh UI
    await Async.delayMillis(1)()

    const company = args.company ?? throws("No company found")

    const rsaKey = await conectoAsymetricDecrypt(args.password)()

    args.buyRequest.publicKey = rsaKey.publicKey
    args.buyRequest.creationDate = new Date()
    args.buyRequest.placerId = args.placerId
    args.buyRequest.companyImageUrl = company.imageUrl
    args.buyRequest.currentGuestIdList = args.process.guestIdList
    args.buyRequest.onceInvited = args.process.guestIdList
    args.buyRequest.businessName = company.businessName
    args.buyRequest.feeConditions = company.feeConditions

    args.process.creationDate = new Date()

    const collection = await _(appCallBuyRequestCollection(args.product))

    if (validateRequestAndProcess(args.buyRequest, args.process)) {

      // Check encodes
      Value.Encode(BuyRequestT, args.buyRequest)
      Value.Encode(ProcessT, args.process)

      const buyRequestId = await _(buyRequestGenerateId(company, args.product))

      await collection.doc(buyRequestId).set(
        normalizeForFirebase(args.buyRequest)
      )

      const processCollection = await _(
        appCallProcessCollection(args.product, buyRequestId)
      )

      await processCollection.add(
        normalizeForFirebase(args.process)
      )

      const invitedUsers = await _(
        processGetInvitedUserListFromBuyRequest({
          buyRequest: args.buyRequest,
          data: args.process
        })
      )

      const training = await _(appCallTraining)

      const alwaysInvited = 
        training ?
          [
            "info@conectoenergia.com"
          ] :
          [
            "pedro.kocourek@conectoenergia.com",
            "tomas.donovan@conectoenergia.com",
            "info@conectoenergia.com"
          ]

      const emailList =
        pipe(invitedUsers)(
          List.filter(it => (it.mailSettings?.newBuyRequest ?? true)),
          List.lift(it => it.email),
          List.filterNotNone,
          it => [...it, ...alwaysInvited],
          List.unique
        )


      if(emailList.length === 0) return

      const buyType = args.buyRequest.buyType.type

      const newMailsToSend: List<SentMail> = emailList.map(email =>
        ({
          id: "",
          to: email,
          template: {
            name: 'newBuyRequest',
            data: {
              businessName: args.buyRequest.businessName,
              companyImageUrl: args.buyRequest.companyImageUrl,
              id: args.buyRequest.id,
              product: 
                ["annual", "monthly", "spot"].includes(buyType) ? "Gas Natural" :
                buyType === "mater" ? "PPA MATER" :
                buyType === "thermal" ? "Térmico OnSite" :
                buyType === "onsite" ? "PPA Solar FV OnSite" :
                buyType === "plus" ? "Energía Plus" :
                "",
              closeDate: dateToMailFormat(args.process.closeDate)
            }
          }
        })
      )

      await mailSendBatch(newMailsToSend)()

    } else {
      console.log(args.buyRequest)
      console.log(args.process)
      throw new Error("Invalid Request or Process")
    }
  })


export const buyRequestUpdate =
(
  args: {
    buyRequest: {
      type: BuyRequestType
      id: Id
    }
    data: Partial<BuyRequest>
  }
): AppCall<Unit> => 
  AppCall.do(async _ => {
    const collection = await _(appCallBuyRequestCollection(args.buyRequest.type))
    return await collection.doc(args.buyRequest.id).update(args.data)
  })

export const buyRequestStatusUpdate =
  (
    args: {
      product: BuyRequestType,
      buyRequestId: Id, 
      status: BuyRequestStatus
    }
  ): AppCall<Unit> => 
  AppCall.do(async _ => {
    const collection = await _(appCallBuyRequestCollection(args.product))
    return await collection.doc(args.buyRequestId).update({ status: args.status })
  })

export const buyRequestSetOpen = (
  args: {
    product: BuyRequestType
    buyRequestId: Id
  }
): AppCall<Unit> => 
  buyRequestStatusUpdate({
    product: args.product,
    buyRequestId: args.buyRequestId,
    status: {
      type: "open"
    }
  })

export const buyRequestSetWaitingAnswer = (
  args: {
    product: BuyRequestType
    buyRequestId: Id
  }
): AppCall<Unit> => 
  buyRequestStatusUpdate({
    product: args.product,
    buyRequestId: args.buyRequestId,
    status: {
      type: "waitingAnswer"
    }
  })

export const buyRequestSetDeserted = (
  args: {
    product: BuyRequestType
    buyRequestId: Id,
    comment: string
  }
): AppCall<Unit> => 
  buyRequestStatusUpdate({
    product: args.product,
    buyRequestId: args.buyRequestId,
    status: {
      type: "deserted",
      comment: args.comment
    }
  })

// export const buyRequestSetPreAssigned = (
//   args: {
//     product: BuyRequestType
//     buyRequestId: Id
//     processId: Id
//     assignedCompanyId: Id
//     bidIdList: List<Id>
//     comment: string
//   }
// ): AppCall<Unit> => 
//   buyRequestStatusUpdate({
//     product: args.product,
//     buyRequestId: args.buyRequestId,
//     status: {
//       type: "preAssigned",
//         processId: args.processId,
//         preAssignedCompanyIdList: [args.assignedCompanyId],
//         bidIdList: List.asArray(args.bidIdList),
//         comment: args.comment
//     }
//   })

export const buyRequestSetAssigned = (
  args: {
    product: BuyRequestType
    buyRequestId: Id
    processId: Id
    assignedCompanyId: Id
    bidId: Id
    comment: string
  }
): AppCall<Unit> => 
  buyRequestStatusUpdate({
    product: args.product,
    buyRequestId: args.buyRequestId,
    status: {
      type: "assigned",
      processId: args.processId,
      assignedCompanyId: args.assignedCompanyId,
      bidId: args.bidId,
      comment: args.comment
    }
  })


const buyRequestGenerateId = (
  company: Company,
  product: BuyRequestType
): AppCall<string> => 
  AppCall.do(async _ => {

    const collection = await _(appCallBuyRequestCollection(product === "gas" ? "gas" : "electricity"))

    const query = await collection.where("buyerCompanyId", "==", company?.id).get()

    return [
      company.shortName,
      matchEnum(product)({
        gas: 'GN',
        electricity: 'EE',
      }),
      (query.size + 1).toString().padStart(3, '0')
    ].join("-")
  })




export const buyRequestDraftSave = (
  draft: BuyRequestDraft, 
  user: User
) => 
  AppCall.do(async _ => {

    const normalized = normalizeForFirebase({
      ...draft,
      lastEdit: new Date(),
      user: {
        firstName: user.firstName,
        lastName: user.lastName
      },
      productType: draft.type,
    }) as any

    if (draft.id !== none && draft.id !== "") {
      await dbDraftCollection().doc(draft.id).update(normalized)
    } else {
      await dbDraftCollection().add(normalized)
    }

  })

export const buyRequestDraftDelete = (id: Id): AppCall<Unit> => 
  AppCall.do(async _ => {
    await dbDraftCollection().doc(id).delete()
  })

