import { match, matchEnum, matchEnumPartial } from "functional/lib/match"
import { zeroPad } from "react-countdown"
import { Process } from "../model/Process"
import { BuyRequest, BuyRequestType } from "../model/buyRequest/BuyRequest"
import { AnnualBidForm } from "./gas/AnnualBidForm"
import { MaterBidForm } from "./electricity/MaterBidForm"
import { MonthlyBidForm } from "./gas/MonthlyBidForm"
import { SolarFVOnsiteBidForm } from "./electricity/SolarFVOnsiteBidForm"
import { SpotBidForm } from "./gas/SpotBidForm"
import { ThermalBidForm } from "./electricity/ThermalBidForm"
import { PlusBidForm } from "./electricity/PlusBidForm"

export const BidForm = (
  props: {
    buyRequest: BuyRequest
    process: Process
  }
) => 
  <>{
    match(props.buyRequest)({

      gas: buyRequest =>
        matchEnum(buyRequest.buyType.type)({
          annual: <AnnualBidForm
            buyRequest={buyRequest}
            process={props.process}
          />,
          spot: <SpotBidForm
            buyRequest={buyRequest}
            process={props.process}
          />,
          monthly: <MonthlyBidForm
            buyRequest={buyRequest}
            process={props.process}
          />
        }),

      electricity: buyRequest =>
        matchEnumPartial(buyRequest.buyType.type)({
          thermal: <ThermalBidForm
            buyRequest={buyRequest}
            process={props.process}
          />,
          mater: <MaterBidForm
            buyRequest={buyRequest}
            process={props.process}
          />,
          onsite: <SolarFVOnsiteBidForm
            buyRequest={buyRequest}
            process={props.process}
          />,
          plus: <PlusBidForm
            buyRequest={buyRequest}
            process={props.process}
          />
        })

    })

  }<br />
  </>

export const countDownRenderer = (
  { 
    days, 
    hours, 
    minutes, 
    seconds, 
    completed 
  }: {
    days: number
    hours: number
    minutes: number
    seconds: number
    completed: boolean
  }
) => {
  if (!completed) {
    const dias = days == 1 ? `1 día` : (days > 1 ? days + ` días` : null)
    return <span>{dias} {zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}</span>
  } else return null
}
