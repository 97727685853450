import AddToPhotosIcon from '@material-ui/icons/AddToPhotos'
import AssignmentRoundedIcon from '@material-ui/icons/AssignmentRounded'
import AddIcon from '@material-ui/icons/AddRounded';
import FireplaceRoundedIcon from '@material-ui/icons/FireplaceRounded'
import LockIcon from '@material-ui/icons/Lock'
import WbSunnyRoundedIcon from '@material-ui/icons/WbSunnyRounded'
import { IO } from 'functional/lib/IO'
import { List } from 'functional/lib/List'
import { Maybe } from 'functional/lib/Maybe'
import { Unit, id, none } from 'functional/lib/core'
import { match, matchEnumPartial, matchPartial } from 'functional/lib/match'
import { ReactNode, useMemo } from "react"
import {
  Badge,
  Card,
  Col,
  OverlayTrigger,
  Tooltip
} from "react-bootstrap"
import { OverlayInjectedProps } from 'react-bootstrap/esm/Overlay'
import {
  bootstrapColorForStatus,
  computeMonthlyCmdAverage,
  dateToShortFormat,
  processDefinitionDate
} from "../../Utilities"
import { RequestIcon } from "../../Views"
import { FlexCol, FlexRow } from '../../components/Flexbox'
import { useAppUser } from '../../context/App'
import {
  useCurrentUser,
  useProcessList,
  useSupplyPoint
} from "../../hooks/ConectoHooks"
import { Process } from '../../model/Process'
import { BuyRequest } from '../../model/buyRequest/BuyRequest'
import { deviceSizes, useDeviceSize } from '../../hooks/responsive'

export const BuyRequestListRow = (
  props: {
    buyRequest: BuyRequest
    isSelected: boolean
    columns: List<{
      width?: number
      cell: (data: BuyRequest, lastProcess: Maybe<Process>) => ReactNode
    }>
    onClick?: IO<Unit>
  }
) => {

  const deviceSize = useDeviceSize()
  const small = deviceSize <= deviceSizes.lg

  const [processList, processLoading, processError] = useProcessList(props.buyRequest.id, props.buyRequest.type)
  const lastProcess = processList?.at(-1)

  if (lastProcess === none) return null

  return <Card
    className="shadow"
    border={props.isSelected ? "primary" : "secondary"}
    style={{ 
      paddingTop: small ? 16 : 6,
      paddingBottom: small ? 16 : 6,
      paddingLeft: 6,
      paddingRight: 6,
      width: '100%', 
      cursor: "pointer" 
    }}
    onClick={props.onClick}
  >
    <FlexRow
      style={{ fontSize: '11px' }}
    >
      {
        props.columns.map(column =>
          <FlexCol 
            style={{
              width: 0,
              flexGrow: column.width ?? 1,
              textAlign: 'center',
            }}
          >
            {column.cell(props.buyRequest, lastProcess)}
          </FlexCol>
        )
      }
    </FlexRow>
  </Card>
}

const column = id<{
  width?: number
  header: string
  cell: (data: BuyRequest, lastProcess: Maybe<Process>) => ReactNode
}>

export type BuyRequestColumn = keyof typeof buyRequestColumns

export const buyRequestColumns = {

  id: column({
    header: "ID",
    cell: it => it.id
  }),
  
  type: column({
    header: "Tipo",
    width: 0.5,
    cell: it => <TypeCell buyType={it.buyType} />
  }),

  point: column({
    header: "Punto",
    cell: it => <SupplyPointCell buyRequest={it} />
  }),

  quantity: column({
    header: "Cantidad",
    cell: it => <FlexRow 
      alignItems='flex-end' 
      gap={2}
    >
      {
        match(it)<React.ReactNode>({
          electricity: it => 
            <>
              {it.totalAmount}
              <small>
                {
                  match(it.buyType)({
                    mater: () => "MWh/a",
                    surplus: () => "MWh/a",
                    thermal: () => "MW",
                    onsite: () => "MW",
                    plus: () => "MW"
                  })
                }
              </small>
            </>,

          gas: request => "-"
        })
      }

    </FlexRow>
  }),

  cmd: column({
    header: "CMD",
    cell: it => <FlexRow 
      alignItems='flex-end' 
      gap={2}
    >
      {
        match(it)<React.ReactNode>({
          electricity: it => 
            <>
              <small>-
              </small>
            </>,

          gas: request => {

            const max = request.maximumDailyAmount ?? 0

            const average = 
              max > 0 ? max :  
              matchPartial(request.buyType)({
                spot: it => (it.amount / it.termDays).toFixed(2),
              }) ??  
              computeMonthlyCmdAverage(request.buyType?.cmdCurve ?? [])

            return `${average} Mm³/d`

          }
        })
      }

    </FlexRow>
  }),

  openDate: column({
    header: "Abre",
    cell: (_, process) => 
      <FlexRow 
        alignItems='flex-end' 
        gap={2}
      >
        {dateToShortFormat(process?.openDate)}
      </FlexRow>
  }),

  closeDate: column({
    header: "Cierre",
    cell: (_, process) => 
      <FlexRow 
        alignItems='flex-end' 
        gap={2}
      >
        {dateToShortFormat(process?.closeDate)}
      </FlexRow>
  }),

  definitionDate: column({
    header: "Define",
    cell: (_, process) => 
      <Col className='text-center'>
        {Maybe.do(_ => 
          dateToShortFormat(processDefinitionDate(_(process)))
        )}
      </Col>
  }),

  round: column({
    header: "Ronda",
    cell: (request, process) => 
      <RoundDescription
        buyRequest={request}
        process={process}
      />
  })

} 



const RoundDescription = (
  props: {
    buyRequest: BuyRequest
    process: Maybe<Process>
  }
) => {

  const user = useAppUser()

  const role = user?.roleList?.includes('buyer') ? "buyer" : "seller"

  const invited = props.buyRequest.currentGuestIdList?.includes(user?.companyIdList?.at(0) ?? "") ?? false

  if (role === "seller" && !invited) {
    return <Col className='text-center'><small>No invitado</small></Col>
  }

  const textColor = 'text-' + bootstrapColorForStatus(props.buyRequest)

  return <FlexRow
    gap={2}
  >
    <RequestIcon
      color={textColor}
      processType={props.process?.type ?? "-"}
      size={14}
    />
    <Badge
      bg={bootstrapColorForStatus(props.buyRequest)}
    >
      Ronda {props.process?.round ?? "-"} 
    </Badge>
    <EncryptedStateView
      buyRequest={props.buyRequest}
      process={props.process}
    />
  </FlexRow>

}

const OpenAndCloseDates = (
  props: {
    buyRequest: BuyRequest
    lastProcess: Maybe<Process>
  }
) => {

  const lastProcess = props.lastProcess
  if (lastProcess === none) return none

  const date = matchEnumPartial(props.buyRequest.status.type)({
    open: lastProcess.closeDate,
    pending: lastProcess.openDate,
    deserted: lastProcess.closeDate,
    assigned: lastProcess.closeDate,
  })

  return Maybe.map(date)(date =>
    <Col className='text-center'>{dateToShortFormat(date)}</Col>  
  )
}


const Ranking = (
  props: {
    buyRequest: BuyRequest
  }
) => {

  const [currentUser, loadingUser, errorUser] = useCurrentUser()

  return (props.buyRequest.status.type == 'assigned' && currentUser?.roleList?.includes('seller')
    && !(props.buyRequest.status.assignedCompanyId == currentUser?.companyIdList?.[0])) ?
    <Col
      className='text-center'
    >
      {
        props.buyRequest.status?.ranking?.[currentUser?.companyIdList?.[0] ?? ""] ??
        <small>En elaboración</small>
      }
    </Col> : null

}

const EncryptedStateView = (
  props: {
    buyRequest: BuyRequest
    process?: Process
  }
) => 
  props.buyRequest.status.type === "waitingAnswer" && props.process ?
    <LockIcon 
      className={props.process?.encrypted ? 'text text-danger': 'text text-success'}
      style={{ fontSize: 14 }} 
    /> :
    null



const TypeCell = (
  props: {
    buyType: BuyRequest["buyType"]
  }
) => {

  const Icon = 
    matchEnumPartial(props.buyType.type)({
      mater: AssignmentRoundedIcon,
      surplus: AddToPhotosIcon,
      thermal: FireplaceRoundedIcon,
      onsite: WbSunnyRoundedIcon,
      plus: AddIcon,
    }) ?? none

  const overlayText = matchEnumPartial(props.buyType.type)({
    mater: "PPA Mater",
    surplus: "PPA Mater Excedentes",
    plus: "Energía Plus",
    thermal: "Térmico Onsite",
    onsite: "Solar FV Onsite",
    annual: "Anual",
    monthly: "Mensual",
    spot: "Spot",
  }) ?? "-"


  const overlay = useMemo(
    () => 
      (props: OverlayInjectedProps) => <Tooltip id="button-tooltip" {...props}>{overlayText}</Tooltip>,
    [overlayText]
  )

  return Icon === none ?  
    <a>{overlayText}</a> : 
    <OverlayTrigger
      placement="right"
      delay={{ show: 250, hide: 400 }}
      overlay={overlay}
    >
      <Icon fontSize={"small"} />
    </OverlayTrigger>
}

const materTooltip = (props: OverlayInjectedProps) => <Tooltip id="button-tooltip" {...props}>PPA Mater</Tooltip>
const materSurplusTooltip = (props: OverlayInjectedProps) => <Tooltip id="button-tooltip" {...props}>PPA Mater Excedentes</Tooltip>
const thermalTooltip = (props: OverlayInjectedProps) => <Tooltip id="button-tooltip" {...props}>Térmico Onsite</Tooltip>
const onsiteTooltip = (props: OverlayInjectedProps) => <Tooltip id="button-tooltip" {...props}>Solar FV Onsite</Tooltip>
const plusTooltip = (props: OverlayInjectedProps) => <Tooltip id="button-tooltip" {...props}>Energía Plus</Tooltip>


const SupplyPointCell = (
  props: {
    buyRequest: BuyRequest
  }
) => {

  const [supplyPoint, loading, error] = useSupplyPoint(
    props.buyRequest.buyerCompanyId, 
    props.buyRequest.supplyPointId
  )

  return <>{supplyPoint?.name ?? "-"}</>
}
