import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline"
import AttachFileIcon from "@material-ui/icons/AttachFile"
import React, { useState } from "react"
import { Alert, Card, Col, Collapse, Row, Table } from "react-bootstrap"
import { useBid, useCompany } from "./hooks/ConectoHooks"
import {
  computeAnnualWeightedAveragePrice,
  computeMonthlyWeightedAveragePrice, dateToBidFormat,
  dateToNiceFormat,
  mapMonthlyCurveWithName,
  monthYearFormat
} from "./Utilities"
import { DecryptedBid } from "./model/Bid"
import { Product } from "./model/Model"
import { User } from "./model/User"
import { none } from "functional/lib/core"
import { showIf } from "functional/lib/Maybe"
import { match, matchEnum, matchPartial } from "functional/lib/match"
import { BuyRequest } from "./model/buyRequest/BuyRequest"
import { GasBuyRequest, GasBuyTypeMonthly } from "./model/buyRequest/GasBuyRequest"
import { ElectricityBuyRequest } from "./model/buyRequest/ElectricityBuyRequest"
import { Process } from "./model/Process"
import { useAppUser } from "./context/App"
import { computeContractValueAndFee } from "./utils/fee"
import { formatUsd } from "./pages/admin/detail-components"


export const WinnerBidCard = (
  props: {
    buyRequest: BuyRequest
    lastProcess: Process
  }
) => {

  const user = useAppUser()

  const isSeller = user?.roleList?.[0] === 'seller'

  const bidStatus = props.buyRequest.status
  const buyType = props.buyRequest.buyType

  const [winnerBid, loadingwinnerBid, errorWinnerBid] = useBid(
    props.buyRequest.type, 
    props.buyRequest.id,
    props.lastProcess.id, 
    bidStatus.type == "assigned" ? bidStatus.bidId : none
  )

  const [assignedCompanyId] = useCompany(bidStatus.type == "assigned" ? bidStatus.assignedCompanyId : none)
  const [buyerCompany] = useCompany(props.buyRequest.buyerCompanyId)

  if (winnerBid === none || winnerBid?.encrypted === true) return <></>

  const { fee } = computeContractValueAndFee(props.buyRequest, winnerBid?.offer)

  return <>
    {showIf(isSeller)( 
      <>{buyerCompany ? buyerCompany.businessName : null} les ha adjudicado el Pedido de Compra <b>{props.buyRequest.id}</b></>
    )}
    {loadingwinnerBid && "Cargando..."}
    {

      match(props.buyRequest)({

        electricity: buyRequest =>
          match(buyRequest.buyType)({

            thermal: buyType =>
            <ThermalWinnerBidCard
              buyRequest={buyRequest}
              lastProcess={props.lastProcess}
              winnerBid={winnerBid}
            />,
            mater: buyType =>
              <MaterWinnerBidCard
                buyRequest={buyRequest}
                lastProcess={props.lastProcess}
                winnerBid={winnerBid}
              />,
            onsite: buyType =>
              <OnsiteWinnerBidCard
                buyRequest={buyRequest}
                lastProcess={props.lastProcess}
                winnerBid={winnerBid}
              />,
            
            plus: buyType =>
              <PlusWinnerBidCard
                buyRequest={buyRequest}
                lastProcess={props.lastProcess}
                winnerBid={winnerBid}
              />,

            surplus: buyType => <></>

          }),

        gas: buyRequest => 
          match(buyRequest.buyType)({
            monthly: buyType => 
              <MonthlyWinnerBidCard
                buyRequest={buyRequest}
                lastProcess={props.lastProcess}
                buyType={buyType}
                winnerBid={winnerBid}
              />,
            annual: buyType =>
              <AnnualWinnerBidCard
                buyRequest={buyRequest}
                lastProcess={props.lastProcess}
                winnerBid={winnerBid}
              />,
            spot: buyType =>
              <SpotWinnerBidCard
                buyRequest={buyRequest}
                lastProcess={props.lastProcess}
                winnerBid={winnerBid}
              />,
          
        })

      })

     
    }

    {
      showIf(isSeller && fee !== none)(
        <Alert
          variant="info"
        >
          Comisión de Conecto Energía: <b>{formatUsd(fee)}</b>
        </Alert>
      )
    }

  </>
}

const MonthlyWinnerBidCard = (
  props: {
    buyRequest: GasBuyRequest
    lastProcess: Process
    buyType: GasBuyTypeMonthly
    winnerBid: DecryptedBid
  }
) => {

  const offer = props.winnerBid?.offer?.type === "monthly" ? props.winnerBid.offer : none

  if (offer === none) return <></>

  const { contractValue, fee } = computeContractValueAndFee(props.buyRequest, offer)

  const [open, setOpen] = useState(false)
  return <Alert variant='info'>
    <br />
    <Card className='shadow'>
      <Card.Body>
        <h5>Oferta Ganadora</h5>
        <Table className='text text-secondary text-center'>
          <thead>
            <tr>
              <th align="center">Precio Promedio</th>
              <th align="center">Valor de Contrato</th>
              {props.lastProcess.acceptsDifferentPayTerm && <th align="center">Plazo de Pago</th>}
              <th align="center">Hora</th>
              <th align="center">Ver Más</th>
            </tr>
          </thead>
          <tbody>
            <tr className='text-center'>
              <td>{computeMonthlyWeightedAveragePrice(props.buyRequest, offer).toFixed(2)}</td>
              <td>{formatUsd(contractValue)}</td>
              {props.lastProcess.acceptsDifferentPayTerm ?
                <td>{offer.payTermDays}</td> : null}
              <td>{dateToNiceFormat(props.winnerBid.date)}</td>
              <td><AddCircleOutlineIcon onClick={() => setOpen(!open)} /></td>
            </tr>
            <Collapse in={open}>
              <tr>
                <td colSpan={4}>
                  <Card>
                    <Table className='text-center text-secondary' size="sm">
                      <thead>
                        <tr>
                          <th>Mes</th>
                          <th>Precio Ofertado</th>
                          {props.buyRequest.acceptsLessVolume ? <th>Volumen Ofertado</th> : null}
                          <th>Volumen Demandado</th>
                        </tr>
                      </thead>
                      <tbody>
                        {mapMonthlyCurveWithName(
                          props.buyRequest.supplyStartDate,
                          offer.priceCurve,
                          (name, value, index) =>
                            <tr key={name}>
                              <td>{name}</td>
                              <td>{value.toFixed(2)}</td>
                              {props.buyRequest.acceptsLessVolume ?
                                <td>{offer.monthlyCurve?.[index]}</td> : null}
                              <td>{props.buyType.demandCurve[index]}</td>
                            </tr>
                        )
                        }
                      </tbody>
                    </Table>
                  </Card>
                </td>
              </tr>
            </Collapse>
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  </Alert>
}

const AnnualWinnerBidCard = (
  props: {
    buyRequest: GasBuyRequest
    lastProcess: Process
    winnerBid: DecryptedBid
  }
) => {

  const offer = props.winnerBid?.offer?.type === "annual" ? props.winnerBid.offer : none

  if (offer === none) return <></>

  const { contractValue, fee } = computeContractValueAndFee(props.buyRequest, offer)

  return <Alert variant='info'>
    <br />
    <Card className='shadow'>
      <Card.Body>
        <h5>Oferta Ganadora</h5>
        <Table className='text text-secondary text-center'>
          <thead>
            <tr>
              <th align="center">Invierno</th>
              <th align="center">Verano</th>
              <th align="center">Promedio Anual</th>
              <th align="center">Valor de Contrato</th>
              {props.lastProcess.type == "tender" && props.lastProcess.acceptsDifferentPayTerm ? <th align="center">Plazo de Pago</th> : null}
              <th align="center">Hora</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{offer.winterPrice.toFixed(2)}</td>
              <td>{offer.summerPrice.toFixed(2)}</td>
              <td>{computeAnnualWeightedAveragePrice(props.buyRequest, offer).toFixed(2)}</td>
              <td>{formatUsd(contractValue)}</td>
              {props.lastProcess.type == "tender" && props.lastProcess.acceptsDifferentPayTerm && <td>{offer.payTermDays}</td>}
              <td>{dateToNiceFormat(props.winnerBid.date)}</td>
            </tr>
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  </Alert>
}

const SpotWinnerBidCard = (
  props: {
    buyRequest: GasBuyRequest
    lastProcess: Process
    winnerBid: DecryptedBid
  }
) => {

  const offer = props.winnerBid?.offer?.type === "spot" ? props.winnerBid.offer : none

  if (offer === none) return <></>

  const { contractValue, fee } = computeContractValueAndFee(props.buyRequest, offer)

  return <Alert variant='info'>
    <br />
    <Card className='shadow'>
      <Card.Body>
        <h5>Oferta Ganadora</h5>
        <Table className='text text-secondary text-center'>
          <thead>
            <tr>
              <th align="center">Precio</th>
              <th align="center">Valor de Contrato</th>
              {props.buyRequest.acceptsLessVolume && <th align="center">Cantidad Ofertada</th>}
              {props.lastProcess.acceptsDifferentPayTerm && <th align="center">Plazo de Pago</th>}
              <th align="center">Hora</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{offer.price.toFixed(2)}</td>
              <td>{formatUsd(contractValue)}</td>
              {props.buyRequest.acceptsLessVolume && <td>{offer.amount}</td>}
              {props.lastProcess.acceptsDifferentPayTerm && <td>{offer.payTermDays}</td>}
              <td>{dateToNiceFormat(props.winnerBid.date)}</td>
            </tr>
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  </Alert>
}

const MaterWinnerBidCard = (
  props: {
    buyRequest: ElectricityBuyRequest
    lastProcess: Process
    winnerBid: DecryptedBid
  }
) => {

  const offer = props.winnerBid.offer?.type === "mater" ? props.winnerBid.offer : none

  if (offer === none) return <></>

  const [open, setOpen] = useState(false)
  return <Alert variant='info'>
    <br />
    <Card className='shadow'>
      <Card.Body>
        <h5>Oferta Ganadora</h5>
        <Table className='text text-secondary text-center'>
          <thead>
            <tr>
              <th align="center">Precio de Energía</th>
              <th align="center">Plazo de Contrato</th>
              <th align="center">Inicio de Suministro</th>
              <th align="center">Archivos</th>
              <th align="center">Hora</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{offer.variableCost?.toFixed(2) ?? ""}</td>
              <td>{offer.term}</td>
              <td>{monthYearFormat(offer.startSupplyDate)}</td>
              <td>
                <a className='text-secondary'>
                  <AttachFileIcon fontSize='small' />
                  <AddCircleOutlineIcon onClick={() => setOpen(!open)} />
                </a>
              </td>
              <td>{dateToBidFormat(props.winnerBid.date)}</td>
            </tr>
            <Collapse in={open}>
              <tr>
                <td colSpan={6}>
                  <Row className='justify-content-center'>
                    <Col xs={11}>
                      <Table bordered>
                        <tbody>
                          {offer.decryptedXls && <tr>
                            <th>Template de Oferta</th>
                            <th><a target="_blank" href={offer.decryptedXls.link}>{offer.decryptedXls.name}</a></th>
                          </tr>}
                          {offer.decryptedPdfFiles && <tr>
                            <th>Otros Archivos</th>
                            <th>
                              {offer.decryptedPdfFiles.map((file, position) => 
                                <tr key={position}><a target="_blank" href={file.link}>{file.name}</a></tr>
                              )}
                            </th>
                          </tr>
                          }
                        </tbody>
                      </Table>
                    </Col>
                  </Row>
                </td>
              </tr>
            </Collapse>
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  </Alert>
}


const ThermalWinnerBidCard = (
  props: {
    buyRequest: ElectricityBuyRequest
    lastProcess: Process
    winnerBid: DecryptedBid
  }
) => {

  const offer = props.winnerBid.offer.type === "thermal" ? props.winnerBid.offer : none

  if (offer === none) return <></>

  const [open, setOpen] = useState(false)
  return <Alert variant='info'>
    <br />
    <Card className='shadow'>
      <Card.Body>
        <h5>Oferta Ganadora</h5>
        <Table className='text text-secondary text-center'>
          <thead>
            <tr>
              <th align="center">Costo de O&M</th>
              <th align="center">Costo Mensual de Potencia</th>
              <th align="center">Plazo de Contrato</th>
              <th align="center">Plazo de Inicio de Suministro</th>
              <th align="center">Archivos</th>
              <th align="center">Hora</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{offer.fixedCost.toFixed(2)}</td>
              <td>{offer.variableCost.toFixed(2)}</td>
              <td>{offer.term}</td>
              <td>{offer.startSupplyDays}</td>
              <td>
                <a className='text-secondary'>
                  <AttachFileIcon fontSize='small' />
                  <AddCircleOutlineIcon onClick={() => setOpen(!open)} />
                </a>
              </td>
              <td>{dateToBidFormat(props.winnerBid.date)}</td>
            </tr>
            <Collapse in={open}>
              <tr>
                <td colSpan={6}>
                  <Row className='justify-content-center'>
                    <Col xs={11}>
                      <Table bordered>
                        <tbody>
                          {offer.decryptedXls && <tr>
                            <th>Template de Oferta</th>
                            <th><a target="_blank" href={offer.decryptedXls.link}>{offer.decryptedXls.name}</a></th>
                          </tr>}
                          {offer.decryptedPdfFiles && <tr>
                            <th>Otros Archivos</th>
                            <th>
                              {offer.decryptedPdfFiles.map((file, position) => 
                                <tr key={position}><a target="_blank" href={file.link}>{file.name}</a></tr>
                              )}
                            </th>
                          </tr>
                          }
                        </tbody>
                      </Table>
                    </Col>
                  </Row>
                </td>
              </tr>
            </Collapse>
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  </Alert>
}


const OnsiteWinnerBidCard = (
  props: {
    buyRequest: ElectricityBuyRequest
    lastProcess: Process
    winnerBid: DecryptedBid
  }
) => {

  const offer = props.winnerBid.offer.type === "onsite" ? props.winnerBid.offer : none

  if (offer === none) return <></>

  const [open, setOpen] = useState(false)
  return <Alert variant='info'>
    <br />
    <Card className='shadow'>
      <Card.Body>
        <h5>Oferta Ganadora</h5>
        <Table className='text text-secondary text-center'>
          <thead>
            <tr>
              <th align="center">Precio de Energía</th>
              <th align="center">Plazo de Contrato</th>
              <th align="center">Plazo de inicio de Suministro</th>
              <th align="center">Archivos</th>
              <th align="center">Hora</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{offer.variableCost.toFixed(2)}</td>
              <td>{offer.term}</td>
              <td>{offer.startSupplyDays}</td>
              <td>
                <a className='text-secondary'>
                  <AttachFileIcon fontSize='small' />
                  <AddCircleOutlineIcon onClick={() => setOpen(!open)} />
                </a>
              </td>
              <td>{dateToBidFormat(props.winnerBid.date)}</td>
            </tr>
            <Collapse in={open}>
              <tr>
                <td colSpan={6}>
                  <Row className='justify-content-center'>
                    <Col xs={11}>
                      <Table bordered>
                        <tbody>
                          {offer.decryptedXls && <tr>
                            <th>Template de Oferta</th>
                            <th><a target="_blank" href={offer.decryptedXls.link}>{offer.decryptedXls.name}</a></th>
                          </tr>}
                          {offer.decryptedPdfFiles && <tr>
                            <th>Otros Archivos</th>
                            <th>
                              {offer.decryptedPdfFiles.map((file, position) => 
                                <tr key={position}><a target="_blank" href={file.link}>{file.name}</a></tr>
                              )}
                            </th>
                          </tr>
                          }
                        </tbody>
                      </Table>
                    </Col>
                  </Row>
                </td>
              </tr>
            </Collapse>
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  </Alert>
}









const PlusWinnerBidCard = (
  props: {
    buyRequest: ElectricityBuyRequest
    lastProcess: Process
    winnerBid: DecryptedBid
  }
) => {

  const offer = props.winnerBid.offer?.type === "mater" ? props.winnerBid.offer : none

  if (offer === none) return <></>

  const [open, setOpen] = useState(false)
  return <Alert variant='info'>
    <br />
    <Card className='shadow'>
      <Card.Body>
        <h5>Oferta Ganadora</h5>
        <Table className='text text-secondary text-center'>
          <thead>
            <tr>
              <th align="center">Precio de Energía</th>
              <th align="center">Plazo de Contrato</th>
              <th align="center">Inicio de Suministro</th>
              <th align="center">Archivos</th>
              <th align="center">Hora</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{offer.variableCost?.toFixed(2) ?? ""}</td>
              <td>{offer.term}</td>
              <td>{monthYearFormat(offer.startSupplyDate)}</td>
              <td>
                <a className='text-secondary'>
                  <AttachFileIcon fontSize='small' />
                  <AddCircleOutlineIcon onClick={() => setOpen(!open)} />
                </a>
              </td>
              <td>{dateToBidFormat(props.winnerBid.date)}</td>
            </tr>
            <Collapse in={open}>
              <tr>
                <td colSpan={6}>
                  <Row className='justify-content-center'>
                    <Col xs={11}>
                      <Table bordered>
                        <tbody>
                          {offer.decryptedXls && <tr>
                            <th>Template de Oferta</th>
                            <th><a target="_blank" href={offer.decryptedXls.link}>{offer.decryptedXls.name}</a></th>
                          </tr>}
                          {offer.decryptedPdfFiles && <tr>
                            <th>Otros Archivos</th>
                            <th>
                              {offer.decryptedPdfFiles.map((file, position) => 
                                <tr key={position}><a target="_blank" href={file.link}>{file.name}</a></tr>
                              )}
                            </th>
                          </tr>
                          }
                        </tbody>
                      </Table>
                    </Col>
                  </Row>
                </td>
              </tr>
            </Collapse>
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  </Alert>
}

