import ArrowDropDownCircleOutlinedIcon from '@material-ui/icons/ArrowDropDownCircleOutlined'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import MoreVert from "@material-ui/icons/MoreVert"
import React, { useEffect, useState } from "react"
import { Alert, Button, Card, Col, Collapse, Form, Nav, NavDropdown, OverlayTrigger, Row, Spinner, Table, Tooltip } from "react-bootstrap"
import {
  bootstrapColorForStatus,
  dateToMonthStartSupplyFormat,
  dateToNiceFormat,
  dateToShortFormat,
  dateToStartSupplyFormat,
  mapMonthlyCurveWithName
} from "../Utilities"
import { RequestIcon } from "../Views"
import { BidForm } from "../bidform/BidForm"
import { useBid, useComments, useCompany, useProcessList, useSupplyPoint } from "../hooks/ConectoHooks"

import { IconButton } from '@material-ui/core'
import AttachFileIcon from "@material-ui/icons/AttachFile"
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined"
import MailOutlineIcon from "@material-ui/icons/MailOutline"
import { IO } from 'functional/lib/IO'
import { List } from 'functional/lib/List'
import { Maybe, showIf, someIf } from 'functional/lib/Maybe'
import { none, pipe } from 'functional/lib/core'
import { match, matchEnum } from 'functional/lib/match'
import Countdown, { zeroPad } from "react-countdown"
import { ActionPanel } from '../ActionPanel'
import { 
  // PreAssignedCard,
   WinnerBidCard } from "../WinnerBidCard"
import { ConsumptionChart } from "../buy-request-flow/BuyRequestForm/BuyRequestCreateFormStep"
import { buyRequestSetAssigned, buyRequestSetDeserted, buyRequestSetOpen, 
  // buyRequestSetPreAssigned, 
  buyRequestSetWaitingAnswer } from '../client/buy-request'
import { commentCreate } from '../client/comment'
import { FlexCol, FlexRow } from '../components/Flexbox'
import { useAppUser } from '../context/App'
import { useAppAsynchronism } from '../context/AppCall'
import { RState, useRState } from '../functional/react/RState'
import { deviceSizes, useDeviceSize } from '../hooks/responsive'
import { BuyRequestDraft, Id, Product } from "../model/Model"
import { Process } from '../model/Process'
import { User } from '../model/User'
import { BuyRequest, BuyRequestType } from '../model/buyRequest/BuyRequest'
import { ElectricityBuyRequest } from '../model/buyRequest/ElectricityBuyRequest'
import { GasBuyRequest, GasBuyTypeAnnual, GasBuyTypeMonthly } from '../model/buyRequest/GasBuyRequest'
import { FileData } from '../model/common'
import { Draft } from '../model/utils'
import { FileView } from '../pages/admin/detail-components'
import { useNow } from '../utils/interval'
import { ProcessDetail } from "./ProcessDetail"

export const BuyRequestDetail = (
  props: {
    style?: React.CSSProperties
    buyRequest: BuyRequest
  }
): React.ReactNode => {

  const product = props.buyRequest.type

  const user = useAppUser()

  const buyRequest = props.buyRequest
  const status = buyRequest.status

  const isGas = product === 'gas'
  const isElectricity = product === 'electricity'
  const isBuyer = user?.roleList?.includes("buyer") ?? false
  const isSeller = user?.roleList?.includes("seller") ?? false

  const authorizedForThisProduct = (
    isElectricity && user?.authorizedProductList?.some(it => it === buyRequest.buyType?.type)
    || isGas && user?.authorizedProductList?.includes(buyRequest.type)
  )

  const buyerCompanyIsInvited = props.buyRequest.currentGuestIdList?.includes(user?.companyIdList?.[0] ?? "") ?? false

  const [processList] = useProcessList(buyRequest?.id ?? "", product)

  const lastProcess = processList?.at(-1)
  const selectedProcessState = useRState(() => lastProcess)
  const selectedProcess = selectedProcessState.value
  

  useEffect(
    processList !== none ?
      selectedProcessState.apply(() => processList?.at(-1)) :
      IO.noOp,
    [processList !== none]
  )

  const [openAssignableWinner, setAssignableWinner] = useState(false)

  const assignableWinner = openAssignableWinner && status.type === "waitingAnswer"

  const showBuyerTable = isBuyer //&& user.authorized
  const showSellerTable = user?.roleList?.includes('seller') ?? false
  const showBidForm = isSeller && authorizedForThisProduct && status.type === "open" && buyerCompanyIsInvited

  const showActionPanel = isBuyer && authorizedForThisProduct && status.type === "waitingAnswer"
  const showMessagePanel = isBuyer && authorizedForThisProduct && status.type === "assigned"

  const showInfoPanel = isSeller //&& user.authorized

  if (user === none || selectedProcess == none) return <Spinner/>

  return <BuyRequestDetailView
    style={props.style}
    buyRequest={buyRequest}
    processList={processList}
    extraSections={
      <>
        {showActionPanel && lastProcess &&  
            <ActionPanel
              lastProcess={lastProcess}
              buyRequest={buyRequest}
              assignable={assignableWinner}
              setAssignable={setAssignableWinner}
            />
        }

        {showMessagePanel && <MessagePanel
          buyRequest={buyRequest}
          product={product}
          lastProcess={selectedProcess}
        />
        }

        {showBuyerTable && processList && 
          <BuyerTable
            buyRequest={buyRequest}
            processList={processList}
            selectedProcess={selectedProcessState}
            openAssignableWinner={openAssignableWinner}
            // openPreAssignableWinner={openPreAssignableWinner}
          />
        }

        {(status.type == 'pending' || status.type == 'open') && isSeller && !authorizedForThisProduct &&
          <Alert variant='warning'>Para Ofertar tu Usuario debe estar <b>Autorizado</b> para este producto.<br />Si querés ser Usuario Autorizado para este producto comunicate con nosotros</Alert>
        }
        {(status.type == 'pending' || status.type == 'open') && isBuyer && !authorizedForThisProduct &&
          <Alert variant='warning'>Para operar sobre este Pedido tu Usuario debe estar <b>Autorizado</b> para este producto.<br />Si querés ser Usuario Autorizado para este producto comunicate con nosotros</Alert>
        }

        {showInfoPanel && lastProcess && <InfoPanel
          lastProcess={lastProcess}
          buyRequest={buyRequest}
        />
        }

        {showBidForm && lastProcess && <BidForm
          buyRequest={props.buyRequest}
          process={lastProcess} 
        />
        }

        {showSellerTable && processList && 
          <SellerTable
            buyRequest={buyRequest}
            processList={processList}
            selectedProcess={selectedProcessState}
          />
        }
      </>
    }
  />
}


export const BuyRequestDetailView = (
  props: {
    style?: React.CSSProperties
    buyRequest: BuyRequest
    processList: Maybe<List<Process>>
    extraSections?: React.ReactNode
  }
) => {

  const user = useAppUser()

  const product = props.buyRequest.type

  const buyRequest = props.buyRequest
  const status = buyRequest.status

  const processList = props.processList
  const lastProcess = processList?.at(-1)

  const deviceSize = useDeviceSize()
  const small = deviceSize < deviceSizes.lg

  return <Card 
    style={{
      maxWidth: 1000,
      ...props.style
    }}
    className="shadow text-center"
    border={"secondary"}
  >
    <Header
      buyRequest={buyRequest}
    />
    {/*//New ProcessCard*/}
    <Card.Body
      style={{
        padding: small ? 0 : none,
      }}
    >
      <FlexCol
        alignItems='stretch'
        gap={8}
      >
        <ActiveRoundCard
          buyRequest={buyRequest}
          process={lastProcess}
        />

        {props.extraSections}

        {
          buyRequest.type === "electricity" && 
          buyRequest.requireCA && 
          !buyRequest.acceptedCAList?.includes(user?.companyIdList?.[0] ?? "") && 
          <ConfidencialityAgreementCard />
        }

        {
          match(buyRequest)({

            gas: buyRequest => 
              showIf((buyRequest.fileList?.length ?? 0) > 0)(
                <GasFilesCard
                  buyRequest={buyRequest}
                />
              ),

            electricity: buyRequest => 
              showIf(
                (buyRequest.requireCA && buyRequest.acceptedCAList?.includes(user?.companyIdList?.[0] ?? "") ||
                !buyRequest.requireCA)
              )(
                <ElectricityFiles
                  buyRequest={buyRequest}
                />
              ),

          })
        }

        {/*{showNotInvited && <Alert variant='secondary'>No fuiste invitado a esta ronda</Alert>}*/}

        {
          user?.roleList?.includes('seller') && 
          status.type == "assigned" && 
          !(status.assignedCompanyId === user.companyIdList?.[0]) &&
            <Row className='justify-content-center'>
              <Col xs={10}>
                <Alert variant='secondary'>
                  <Row className='justify-content-center'>
                    El comprador ha adjudicado este pedido a otro proveedor.
                  </Row>
                  <br />
                  <Row className='justify-content-center'>
                    <h6>
                      <b>Ranking de tu mejor Oferta:</b> {status.ranking ? status.ranking[user?.companyIdList?.[0] ?? ""] : 'en elaboración'}
                    </h6>
                  </Row>
                </Alert>
              </Col>
            </Row>
        }

        {processList !== none &&
          <RoundMessagePanel 
            buyRequest={buyRequest} 
            processList={processList} 
          />
        }

        {
          buyRequest.type == 'gas' && 
          buyRequest.buyType.type !== "spot" && 
          <DemandTable buyRequest={buyRequest} />
        }

        {buyRequest.type == 'gas' && <BuyRequestTable buyRequest={buyRequest} showTerms={true} />}

        {
          showIf(buyRequest.id !== none && buyRequest.id !== "")(
            <CommentPanel
              buyRequest={buyRequest}
              lastProcess={lastProcess}
            />
          )
        }
        
      </FlexCol>
    </Card.Body>
  </Card>
}


const Header = (
  props: {
    buyRequest: BuyRequest
  }
) => {

  const user = useAppUser()

  const [company, loadingCompany, errorCompany] = useCompany(props.buyRequest?.buyerCompanyId)

  const textColored = `text-${bootstrapColorForStatus(props.buyRequest)}`

  return <Row
    style={{
      position: "relative",
      alignSelf: "center",
      width: "100%",
    }}
  >
    <Col md={4}>
      <img
        src={props.buyRequest.companyImageUrl ?? company?.imageUrl ?? "https://imgur.com/NhW6Q5T.png"}
        width={"200"}
        height="106"
        alt=""
      />
    </Col>

    <Col md={4}>
      <br />
      <Row className='justify-content-center'>
        <b>{
          props.buyRequest.type === "gas" && (props.buyRequest.jointPurchase ?? false) ? 
            "Compra Conjunta de Empresas" :
            company?.businessName ?? "-"
        }</b>
      </Row>
      <SupplyPointDetail
        buyRequest={props.buyRequest}
      />
      
    </Col>
    <Col md={4}>
      <br />
      ID Pedido: {props.buyRequest.id}
      <br />
      <a className="text text-center text">
        <b>
          <TypeOfBuy buyRequest={props.buyRequest} />
        </b>
      </a>
      <br />
      {
        match(props.buyRequest.status)({
          pending: () => <b className={textColored}>Pendiente</b>,
          waitingAnswer: () => <b className={textColored}>En Análisis</b>,
          deserted: () => <b className={textColored}>Desierto</b>,
          open: () => <b className={textColored}>Abierto</b>,
          assigned: status => (
              status.assignedCompanyId === user?.companyIdList?.[0] || 
              user?.companyIdList?.includes(props.buyRequest.buyerCompanyId)
            ) ?
              <b className={textColored}>Adjudicado</b> : 
              <b className='text-secondary'>Adjudicado a un tercero</b>
        })
      }
    </Col>

    <Col
      style={{
        position: "absolute",
        right: "0px",
        top: "0px",
        width: "min-content",
      }}
    >
      <MenuAdvanced
        buyRequest={props.buyRequest}
      />
    </Col>
    
  </Row>
}


const ActiveRoundCard = (
  props: {
    buyRequest: BuyRequest
    process?: Process
  }
) => {

  const user = useAppUser()

  const isBuyer = user?.roleList?.includes("buyer") ?? false

  const buyRequest = props.buyRequest
  const process = props.process
  const status = buyRequest.status

  const [showTimeChange, setShowTimeChange] = useState(false)
  
  const availableForTimeChange = false && (status.type == 'pending' || status.type == 'open') && isBuyer

  if (user === none || process === none) return <Spinner/>

  return <Card
    // @ts-ignore
    border={bootstrapColorForStatus(props.buyRequest, user)}
    className='shadow p-3 mb-5 bg-white rounded'
  >
    <FlexCol
      alignItems='stretch'
    >

      <FlexRow 
        justifyContent='space-evenly'
      >
        <TypeOfProcess
          buyRequest={buyRequest}
          process={process}
          user={user}
          published={true}
        />
        {availableForTimeChange && 
          <Col xs={4}>
            <Button 
              size={'sm'} 
              variant={'outline-info'} 
              onClick={() => setShowTimeChange(!showTimeChange)}
            >
              Modificar horarios
            </Button>
          </Col>}

        <FlexCol>
          <h6>{process.openDate ? `Abre el: ${dateToNiceFormat(process.openDate)}` : "Agregá un Horario de Apertura"}</h6>
          <h6>{process.closeDate ? `Cierra  el: ${dateToNiceFormat(process.closeDate)}` : "Agregá un Horario de Cierre"}</h6>
        </FlexCol>

      </FlexRow>

      {showTimeChange && <>
        <hr />
        {/* <ProcessTimeEditor
          processDraft={process}
          modifyProcessDraft={() => { }}
          showOpenDate={props.buyRequest.status.type == 'pending'}
        /> */}
        <br />
        <Row className='justify-content-center'>
          <Button 
            variant={'outline-danger'} 
            size={'sm'} 
            onClick={() => setShowTimeChange(false)}
          >
            Cancelar
          </Button>
          <Button 
            variant={'outline-success'} 
            size={'sm'} 
            onClick={() => alert('Funcionalidad no disponible')}
          >
            Guardar cambios y avisar a los invitados <MailOutlineIcon />
          </Button>
        </Row>
        <hr /></>}

      {buyRequest.type === "gas" && <GasConditions process={process} buyRequest={buyRequest} />}

      {(process.maxVariableCost || process.maxFixedCost) && <hr />}
      {
        process.maxVariableCost && 
        process.maxVariableCost !== null && 
        (buyRequest.buyType.type === 'mater' || buyRequest.buyType.type === 'onsite') &&
        <Row className='justify-content-center'>
          <a className='text-secondary'>Precio Máximo de Energía: <b>{process.maxVariableCost.toFixed(2)}</b> US$/MWh</a>
        </Row>
      }
      {process.maxVariableCost && process.maxVariableCost !== null && buyRequest.buyType.type === 'thermal' &&
        <Row className='justify-content-center'>
          <a className='text-secondary'>Costo de O&M Máximo: <b>{process.maxVariableCost.toFixed(2)}</b> US$/MWh</a>
        </Row>
      }
      {process.maxFixedCost && process.maxFixedCost !== null && buyRequest.buyType.type === 'thermal' &&
        <Row className='justify-content-center'>
          <a className='text-secondary'>Costo Mensual de Potencia Máximo: <b>{process.maxFixedCost.toFixed(2)}</b> US$/MW-mes</a>
        </Row>
      }


      {status.type == "open" && <CountDown process={process} />}

    </FlexCol>
  </Card>
}


const MenuAdvanced = (
  props: {
    buyRequest: BuyRequest
  }
) => {

  const status = props.buyRequest.status.type

  const user = useAppUser()

  const training = user?.training ?? false

  const isBuyer = user?.roleList?.includes('buyer') ?? false
  const isAdmin = user?.roleList?.includes('admin') ?? false

  const isAllowed = isAdmin || (isBuyer && training)

  const open = useAppAsynchronism(buyRequestSetOpen)
  const waitingAnswer = useAppAsynchronism(buyRequestSetWaitingAnswer)
  const deserted = useAppAsynchronism(buyRequestSetDeserted)

  const buttons = List.filterNotNone([
    someIf(isAllowed && status === "pending")({
      text: "Abrir Ahora",
      loading: open.state.type === "running",
      action: open.run({
        product: props.buyRequest.type,
        buyRequestId: props.buyRequest.id ?? ""
      })
    }),
    someIf(isAllowed && status === "open")({
      text: "Cerrar Ahora",
      loading: waitingAnswer.state.type === "running",
      action: waitingAnswer.run({
        product: props.buyRequest.type,
        buyRequestId: props.buyRequest.id ?? ""
      })
    }),
    someIf(isAllowed && status === "waitingAnswer")({
      text: "Marcar como desierto",
      loading: deserted.state.type === "running",
      action: deserted.run({
        product: props.buyRequest.type,
        buyRequestId: props.buyRequest.id ?? "",
        comment: ""
      })
    }),
  ])

  if (buttons.length === 0 || props.buyRequest.id === "") return none

  return <Nav>
    <NavDropdown
      title={<MoreVert/>}
    >
      {
        buttons.map(it => 
          <NavDropdown.Item
            key={it.text}
            onClick={it.action}
            disabled={it.loading}
          >
            {it.text}{it.loading && <Spinner/>}
          </NavDropdown.Item>
        )
      }
      
    </NavDropdown>
  </Nav>
}


const BuyerTable = (
  props: {
    buyRequest: BuyRequest
    processList: List<Process>
    selectedProcess: RState<Maybe<Process>>
    openAssignableWinner: boolean
  }
) => {

  const status = props.buyRequest.status

  const selectedBid = useRState<Maybe<Id>>(() => none)

  const comment = useRState(() => "")

  const setAssigned = useAppAsynchronism(buyRequestSetAssigned)

  const [assignedBid, loadingAssignedBid, errorAssignedBid] = useBid(
    props.buyRequest.type,
    props.buyRequest?.id,
    props.selectedProcess?.value?.id,
    selectedBid.value
  )

  const assignToCompanyId = assignedBid && assignedBid.encrypted == false ? assignedBid.sellerCompanyId : none

  const assignableWinner = props.openAssignableWinner && status.type === "waitingAnswer"

  return <ExpandableCard
    title="Ofertas Recibidas"
    initialState={true}
  >
    <ProcessDetail
      buyRequest={props.buyRequest}
      processList={props.processList}

      assignable={props.buyRequest.status.type === "waitingAnswer" && assignableWinner}
      
      selectedProcess={props.selectedProcess}

      selectedBidId={selectedBid}
    />
    <Collapse in={assignableWinner}>
      <div><br />
        {assignableWinner && <h6 className='text-success'>
          Seleccioná un Pedido y aclará la razón para adjudicarlo
        </h6>}
      
        <hr />
        <Form.Group controlId="exampleForm.ControlTextarea1">
          <Form.Control
            as="textarea"
            rows={3}
            value={comment.value}
            onChange={event => comment.apply(() => (event.target as any).value)?.()}
          />
        </Form.Group>

        <br/>

        {assignableWinner && <Button
          onClick={setAssigned.run({
            product: props.buyRequest.type,
            buyRequestId: props.buyRequest.id ?? "",
            processId: props.selectedProcess.value?.id ?? "",
            assignedCompanyId: assignToCompanyId ?? "",
            bidId: selectedBid?.value ?? "",
            comment: comment.value
          })}
          variant="outline-success"
          disabled={selectedBid.value === none}
        >
          Adjudicar
        </Button>}
        
      </div>
    </Collapse>

    <BuyRequestGuestList
      buyRequest={props.buyRequest}
      process={props.selectedProcess.value} 
    />
  </ExpandableCard>
}

const SellerTable = (
  props: {
    buyRequest: BuyRequest
    processList: List<Process>
    selectedProcess: RState<Maybe<Process>>
  }
) => {

  const selectedBid = useRState<Maybe<Id>>(() => none)

  return <ExpandableCard
    title="Detalle de tus Ofertas"
  >
    <ProcessDetail
      buyRequest={props.buyRequest}
      processList={props.processList}
      selectedProcess={props.selectedProcess}
      selectedBidId={selectedBid}
    />
  </ExpandableCard>
}

export const SupplyPointDetail = (
  props: {
    buyRequest: BuyRequestDraft
  }
) => {

  const buyRequest = props.buyRequest

  const [open, setOpen] = useState(false);

  const [supplyPoint, loading, error] = useSupplyPoint(props.buyRequest.buyerCompanyId, props.buyRequest.supplyPointId)

  return supplyPoint ? <Col>
    <FlexRow
      gap={6}
    >
      {buyRequest.type === "gas" && buyRequest?.jointPurchase ? "Múltiples Puntos de Suministro" : supplyPoint.name}
      {buyRequest.type === "gas" && buyRequest?.jointPurchase ? 
        <p className={'text-secondary'}>Ver detalle debajo</p> : 
        <ArrowDropDownCircleOutlinedIcon
          onClick={() => setOpen(!open)} 
        />
      }
    </FlexRow>
    <Collapse in={open}>
      <Row className='justify-content-center'>
        {buyRequest.type === "gas" && !buyRequest?.jointPurchase && <>
          {supplyPoint.address.street} <br />
          {supplyPoint.address.locality} {supplyPoint.address.province} <br />
          <b>{supplyPoint.distributor.name}</b>
        </>}

        {buyRequest.type == 'electricity' && <Col>{supplyPoint?.address?.street} <br />
          {supplyPoint?.address?.locality} {supplyPoint?.address?.province} <br />
          {supplyPoint.category} <b>{supplyPoint.nemo}</b></Col>}
      </Row>
    </Collapse>
  </Col> : null
}

const MappedCompanyImage = (
  props: {
    value: Id
  }
) => {
  const [company, loading, error] = useCompany(props.value)
  return <img 
    src={company && company.imageUrl} 
    height={44}
    style={{
      objectFit: 'contain'
    }} 
  />
}

export const BuyRequestGuestList = (
  props: {
    buyRequest: BuyRequestDraft
    process?: Partial<Process>
  }
) => {
  return <>
    <hr />
    <Row className='justify-content-center'>
      <p className='text-secondary'><b><u>Proveedores Invitados</u></b></p>
    </Row>
    <FlexRow
      gap={14}
      flexWrap='wrap'
    >
      {props.process?.guestIdList?.map((value, index) => <MappedCompanyImage key={index} value={value} />)}
    </FlexRow>
  </>
}

export const BuyRequestTable = (
  props: {
    buyRequest: Draft<GasBuyRequest>
    showTerms: boolean
  }
) => {
  const buyRequest = props.buyRequest

  const buyType = buyRequest.buyType

  const termText = 
    match(buyType)({
      annual: () => "1 año",
      monthly: it => `${it.demandCurve.length} meses`,
      spot: it => `${it.termDays} días`
    })

  const mix = buyRequest.mix
  // TODO CONSIDERAR CASO MIX NULL Y PONER NO APLICA
  const mixText = 
    match(mix ?? { type: "_" as const })<React.ReactNode>({

      free: mix => {
        const regions = [
          'NOA - Sistema TGN',
          'NOA - Gas Atacama',
          "Neuquina",
          "Chubut",
          "Santa Cruz",
          "Tierra del Fuego"
        ]

        return <ul>
          {/*{mix.distribution.Noroeste && <li><Col*/}
          {/*    className='text-left'>Noroeste: {mix.distribution.Noroeste ? mix.distribution.Noroeste : "--"}%</Col>*/}
          {/*</li>}*/}
          {mix.distribution?.map((number, region) => <li>
            <Col className='text-left'>{regions[region]}: {(number ?? 0) > 0 ? number : "--"}%</Col>
          </li>)}
          {/*<li><Col*/}
          {/*className='text-left'>NOA - Gas Atacama: {mix.distribution.NOAAtacama ? mix.distribution.NOAAtacama : "--"}%</Col>*/}
          {/*</li>*/}
          {/*<li><Col*/}
          {/*    className='text-left'>Neuquina: {mix.distribution.Neuquina ? mix.distribution.Neuquina : "--"}%</Col>*/}
          {/*</li>*/}
          {/*<li><Col*/}
          {/*    className='text-left'>Chubut: {mix.distribution.Chubut ? mix.distribution.Chubut : "--"}%</Col>*/}
          {/*</li>*/}
          {/*<li><Col className='text-left'>Santa*/}
          {/*    Cruz: {mix.distribution.SantaCruz ? mix.distribution.SantaCruz : "--"}%</Col></li>*/}
          {/*<li><Col className='text-left'>Tierra Del*/}
          {/*    Fuego: {mix.distribution.TierraDelFuego ? mix.distribution.TierraDelFuego : "--"}%</Col></li>*/}
        </ul>

      },

      distributor: mix => mix.distributor?.name,

      _: () => "No Aplica"

    })

  const productText = 
    matchEnum(buyRequest.product)({
      plant: "Gas en Planta",
      wellhead: "Gas en Boca de Pozo",
      cityGate: "Gas en City Gate"
    })

  return <ExpandableCard
    title="Condiciones Comerciales"
  >
    <Row className="justify-content-md-center">
      <Col sm={10} md={12} lg={10}>
        <Table hover size="sm" className='text-center text-secondary'>
          <tbody className='text-secondary'>
            <tr>
              <td><b>Producto</b></td>
              <td>{productText}</td>
            </tr>
            <tr>
              <td><b>Plazo del Contrato</b></td>
              <td>{termText}</td>
            </tr>
            <tr>
              <td>
                <b>Inicio de Suministro</b>
              </td>
              <td>{
                buyRequest.supplyStartDate ? (
                  buyRequest.buyType.type === "spot" ?
                    dateToStartSupplyFormat(buyRequest.supplyStartDate) :
                    dateToMonthStartSupplyFormat(buyRequest.supplyStartDate)
                ) :
                "Agregá una fecha de inicio de suministro"}
              </td>
            </tr>
            <tr>
              <td><b>Cantidad Total</b></td>
              <td>
                {buyRequest.buyType && buyRequest.buyType.type == "spot" && buyRequest.buyType.amount}
                {buyRequest.buyType && buyRequest.buyType.type == "monthly" && buyRequest.buyType.demandCurve.reduce((a, b) => (a ?? 0) + (b ?? 0), 0)}
                {buyRequest.buyType && buyRequest.buyType.type == "annual" && buyRequest.buyType.demandCurve.reduce((a, b) => (a ?? 0) + (b ?? 0), 0)} [dam3]
              </td>
            </tr>
            {(buyRequest.maximumDailyAmount ?? 0) > 0 && <tr>
              <td><b>CMD</b></td>
              <td>{buyRequest.maximumDailyAmount} [dam3/día]</td>
            </tr>}
            <tr>
              <td><b>Calidad</b></td>
              <td>{buyRequest.quality} [kcal/m3]</td>
            </tr>
            <tr>
              <td><b>Mix de Cuenca</b></td>
              <td>{mixText}</td>
            </tr>
            <tr>
              <td><b>Tipo de Suministro</b></td>
              <td>{
                buyRequest.supplyType == "firm" ?
                  "Firme" :
                  "Interrumpible"
              }</td>
            </tr>
            <tr>
              <td><b>Exclusividad <OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={<Tooltip show={true}>Exclusividad en el suministro a favor del Vendedor por el plazo y hasta los volúmenes de este Pedido de Compra</Tooltip>}
              >
                <InfoOutlinedIcon />
              </OverlayTrigger>
              </b></td>
              <td>{buyRequest.exclusivity ? 'Si' : 'No'}</td>
            </tr>
            <tr>
              <td><b>Take or Pay</b></td>
              <td>{(buyRequest.takeOrPay ?? 0).toFixed(0)} %</td>
            </tr>
            <tr>
              <td><b>Deliver or Pay</b></td>
              <td>{(buyRequest.deliverOrPay ?? 0).toFixed(0)} %</td>
            </tr>
            <tr>
              <td><b>Plazo de pago</b></td>
              <td>{buyRequest.referencePayTermDays} días</td>
            </tr>
            <tr>
              <td><b>Tasa de Cambio</b></td>
              <td>{
                buyRequest.exchangeRate == "BNA billete" ?
                  "BNA Vendedor Billete" :
                  buyRequest.exchangeRate == "BNA divisa" ?
                    "BNA Vendedor Divisa" :
                    buyRequest.exchangeRate == "CAMMESA" ?
                      "BNA Vendedor Divisa" :
                      ""
              }</td>
            </tr>
            <tr>
              <td><b>Comisión Conecto Energía</b></td>
              <td>{
                buyRequest.feeType == "none" ?
                  "Sin Comisión" :
                  buyRequest.feeType == "buyer" ?
                    "A cargo del Comprador" :
                    buyRequest.feeType == "seller" ?
                      "A cargo del Vendedor" :
                      buyRequest.feeType == "split" ?
                        "50% y 50%" :
                        ""
              }</td>
            </tr>
          </tbody>
        </Table>
      </Col>
    </Row>
    {props.showTerms && <Row className="justify-content-center">
      <a target="_blank"
        href="https://firebasestorage.googleapis.com/v0/b/conecto-energia.appspot.com/o/TYC%20Particulares%2FTyC%20Particulares%20-%20Pedido%20de%20Compra%20de%20Gas%20Natural%20(v1-10-2023).docx.pdf?alt=media&token=1f451eb0-141b-49e8-9295-18d438b93c31&_gl=1*jhwffw*_ga*Mjk0NjQ0MTA3LjE2NzY2NDg4NTM.*_ga_CW55HF8NVT*MTY5NzAyODI1MS42NC4xLjE2OTcwMjgzNjcuNDEuMC4w">
        Términos y Condiciones Particulares del Pedio de Compra
      </a>
    </Row>}
  </ExpandableCard>
}

const GasFilesCard = (
  props: {
    buyRequest: Draft<GasBuyRequest>
  }
) => {

  return <ExpandableCard
    title="Documentación Adicional"
  >
    <FlexRow
      gap={8}
      flexWrap='wrap'
    >
      {props.buyRequest.fileList?.map((file, number) => 
        <FileView key={file.link} file={file} />
      )}
    </FlexRow>
    
  </ExpandableCard>
}

export const ElectricityFiles = (
  props: {
    buyRequest: Draft<ElectricityBuyRequest>
  }
) => {

  const fileList = props.buyRequest?.fileList

  const orderedDocuments = fileList?.documents?.toSorted(
      (lhs, rhs) => 
        lhs.name > rhs.name ? 1 :
        lhs.name < rhs.name ? -1 :
        0
  )
  const orderedComplimentaryInfo = fileList?.complimentaryInfo?.toSorted(
    (lhs, rhs) => 
      lhs.name > rhs.name ? 1 :
      lhs.name < rhs.name ? -1 :
      0
  )

  const parts = pipe([
    someIf((fileList?.anex?.length ?? 0) > 0)(<>
      <Row className="justify-content-center">
        <h6>Anexos</h6>
      </Row>
      <Row className="justify-content-center">
        <Col xs={10}>
          {props.buyRequest.fileList?.anex?.map((file, number) => 
            <OfferFileCard key={file.link} file={file} keyValue={number} />
          )}
        </Col>
      </Row>
      <hr />
    </>),

    someIf((fileList?.documents?.length ?? 0) > 0)(<>
      <Row className="justify-content-center">
        <h6>Documentación del Pedido</h6>
      </Row>
      <Row className="justify-content-center">
        <Col xs={10} className='text-center'>
          {orderedDocuments?.map((file, number) => 
            <OfferFileCard key={file.link} file={file} keyValue={number} />
          )}
        </Col>
      </Row>
      <hr />
    </>),

    someIf((fileList?.complimentaryInfo?.length ?? 0) > 0)(
      <>
        <Row className="justify-content-center">
          <h6>Información Complementaria</h6>
        </Row>
        <Row className="justify-content-center text-center">
          <Col xs={10} className='text-center'>
            {orderedComplimentaryInfo?.map((file, number) => 
              <OfferFileCard key={file.link} file={file} keyValue={number} />
            )}
          </Col>
        </Row>
        <hr />
      </>
    ),

    Maybe.map(fileList?.offerTemplate ?? none)(template =>
      <>
        <Row className="justify-content-center text-center">
          <h6>Template de Oferta</h6>
        </Row>
        <Row className="justify-content-center">
          <Col xs={10} className='text-center'>
            <OfferFileCard keyValue={'template'} file={template} />
          </Col>
        </Row>
        <hr />
      </>
    ),

    Maybe.map(fileList?.termsAndConditions)(terms =>
      <>
        <Row className="justify-content-center text-center">
          <h6>Términos y Condiciones</h6>
        </Row>
        <Row className="justify-content-center">
          <Col xs={10} className='text-center'>
            <OfferFileCard 
              keyValue={'termsAndConditions'} 
              file={terms} 
            />
          </Col>
        </Row>
      </>
    )

  ])(
    List.filterNotNone
  )


  if (parts.length === 0) return <></>

  return <ExpandableCard
    title="Información del Pedido"
  >
    {parts}
  </ExpandableCard>
}


export const DemandTable = (
  props: {
    buyRequest: BuyRequestDraft
  }
) => {

  const buyRequest = props.buyRequest

  const buyType = props.buyRequest ? props.buyRequest.buyType as (GasBuyTypeAnnual | GasBuyTypeMonthly) : null

  // const startMonthIndex = props.buyRequest.supplyStartDate.getMonth()
  // const startYear = props.buyRequest.supplyStartDate.getFullYear()

  return <ExpandableCard
    title={
      buyRequest.type === "gas" && buyRequest?.multipleSupplyPointsList ? 
        "Detalle de puntos de suministro - Demanda" : 
        "Curva de Demanda"
    }
  >
    {buyRequest.type === "gas" && buyRequest?.jointPurchase && <>
    <Row className='justify-content-center'>
      <a href={buyRequest?.multiPointInfoLink}><AttachFileIcon /> Descargar Archivo con detalle de Demanda por Empresa</a>
    </Row>
    <Row className='justify-content-center'>
      <Card>
        <Table className='text text-secondary text-center'>
          <thead>
            <tr>
              <td>
                <b>Razón Social</b>
              </td>
              <td>
                <b>Dirección</b>
              </td>
              <td>
                <b>Localidad</b>
              </td>
              <td>
                <b>Provincia</b>
              </td>
              <td>
                <b>Distribuidora</b>
              </td>
            </tr>
          </thead>
          <tbody>
            {buyRequest.multipleSupplyPointsList?.map(supplyPoint =>
              <tr>
                <td>
                  {supplyPoint?.name}
                </td>
                <td>
                  {supplyPoint?.address?.street}
                </td>
                <td>
                  {supplyPoint?.address?.locality}
                </td>
                <td>
                  {supplyPoint?.address?.province}
                </td>
                <td>
                  {supplyPoint?.distributor?.name}
                </td>
              </tr>)}
          </tbody>
        </Table>
      </Card>
    </Row>
    <br />
    <Row className="justify-content-center">
      <h5><b>Curva - Suma de Demandas Compras Conjuntas</b></h5>
    </Row></>}
    <Row className="justify-content-center">
      <Col>
        <Table size="sm" className="text-secondary text-center">
          <thead>
            <tr>
              <th>Mes</th>
              <th>Consumo [dam3/mes]</th>
              {buyType?.cmdCurve && <th>CMD [dam3/día]</th>}
            </tr>
          </thead>
          <tbody>
            {buyType?.demandCurve &&
              mapMonthlyCurveWithName(
                props.buyRequest.type === "gas" ? props.buyRequest.supplyStartDate : none,
                buyType?.demandCurve ?? [],
                (name, value, index) =>
                  <tr key={index}>
                    <td>{name}</td>
                    <td>{value}</td>
                    {buyType?.cmdCurve && <td>{buyType?.cmdCurve?.[index]}</td>}
                  </tr>
              )
            }
          </tbody>
        </Table>
      </Col>
    </Row>

    { buyRequest.type === "gas" && 
      (buyRequest?.buyType?.type === 'monthly' || buyRequest?.buyType?.type === 'annual') && 
      buyRequest?.supplyStartDate &&
      <ConsumptionChart 
        supplyStartDate={buyRequest?.supplyStartDate} 
        demandCurve={buyRequest?.buyType?.demandCurve ?? []}
        cmdCurve={buyRequest?.buyType?.cmdCurve ?? []}
        showBigGraphic={true} 
      />}

  </ExpandableCard>
}

export const TypeOfBuy = (
  props: {
    buyRequest: Draft<BuyRequest>
  }
) =>
  matchEnum(props.buyRequest.buyType.type)({
    annual: 'Compra de Gas Anual',
    monthly: 'Compra de Gas Mensual',
    spot: 'Compra de Gas Spot',
    thermal: 'Térmico Onsite',
    mater: 'PPA MATER',
    onsite: 'PPA Solar FV Onsite',
    surplus: 'PPA MATER Excedentes',
    plus: 'Energía Plus'
  })


export const TypeOfProcess = (
  props: {
    style?: React.CSSProperties
    buyRequest: BuyRequestDraft
    process: Partial<Process>
    user: Maybe<User>
    published: boolean
  }
) => {
  const textColor = 'text-' + bootstrapColorForStatus(props.buyRequest, props.user)

  return <FlexCol
    style={{
      ...props.style
    }}
  >
    <h6 className={textColor}><b>{`Ronda ${props.process.round}`}</b></h6>

    <h6 className={textColor}>
      <b>{props.process.type === "auction" ? "Subasta " : "Licitación "}</b>
      <RequestIcon
        color={textColor}
        processType={props.process.type ?? "auction"}
        size={24}
      />
    </h6>
    </FlexCol>
}


const InfoPanel = (
  props: {
    buyRequest: BuyRequest
    lastProcess: Process
  }
) => {
  
  const user = useAppUser()

  return match(props.buyRequest.status)({

    pending: status => 
      <Alert variant={bootstrapColorForStatus(props.buyRequest)}>
        Has sido invitado a Ofertar a partir del <b>{dateToShortFormat(props.lastProcess.openDate)}</b>
      </Alert>,

    waitingAnswer: status =>
      <Alert variant={bootstrapColorForStatus(props.buyRequest)}>
        {props.lastProcess.encrypted ? <p>Aguardando desencriptación del Comprador</p> :
          <p>El Pedido de Compra fue desencriptado y se está aguardando una acción del Comprador</p>}
      </Alert>,

    deserted: status =>
      <Alert variant={bootstrapColorForStatus(props.buyRequest)}>
        El Pedido de Compra ha sido declarado Desierto por el Comprador
      </Alert>,

    open: status => null,

    assigned: status =>
      user?.companyIdList?.[0] === status.assignedCompanyId && <WinnerBidCard
        buyRequest={props.buyRequest}
        lastProcess={props.lastProcess}
      />

  })
}

const MessagePanel = (
  props: {
    product: Product
    lastProcess: Process
    buyRequest: BuyRequest
  }
) => {

  const [assignedCompany] = useCompany(
    props.buyRequest.status.type == "assigned" ? props.buyRequest.status.assignedCompanyId : none
  )

  return <>
    <Alert variant='info'>El Pedido de Compra ha sido adjudicado a {assignedCompany && <b>{assignedCompany.businessName}</b>}</Alert>
    <WinnerBidCard
      buyRequest={props.buyRequest}
      lastProcess={props.lastProcess}
    />
  </>
}



const CommentPanel = (
  props: {
    buyRequest: BuyRequest
    lastProcess?: Process
  }
) => {

  return <ExpandableCard
    title={'Consultas'}
  >

    <FlexCol
      alignItems='stretch'
      gap={16}
    >

      <CommentListView
        product={props.buyRequest.type}
        buyRequestId={props.buyRequest.id}
      />

      <CommentForm
        buyRequest={props.buyRequest}
        lastProcess={props.lastProcess}
      />

    </FlexCol>
    
  </ExpandableCard>
}


const CommentListView = (
  props: {
    product: BuyRequestType
    buyRequestId: Maybe<Id>
  }
) => {

  const [comments, loading, error] = useComments(
    props.buyRequestId, 
    props.product
  )

  return <FlexCol
    alignItems='stretch'
  >

    {loading && <Spinner />}

    {comments && comments.length == 0 && "No hay Consultas aún"}

    {comments && comments.map(doc =>
      <Card key={doc.id}>
        <Card.Body>
          <p className='text-left'>
            <b>
              {doc.comment}
            </b>
          </p>
          <p className='text-right'><s>{doc.oldAnswer && doc.oldAnswer}</s></p>
          <p className='text-right'>{doc.answer}</p>
          <p className='text-right text-muted small'>Respondido por el Administrador</p>
        </Card.Body>
      </Card>)
    }

  </FlexCol>
}


const CommentForm = (
  props: {
    buyRequest: BuyRequest
    lastProcess?: Process
  }
) => {

  const user = useAppUser()

  const isSeller = user?.roleList?.includes('seller') ?? false

  const buyRequest = props.buyRequest

  const commentCreateAsync = useAppAsynchronism(commentCreate)
  const comment = useRState(() => "")

  const onSubmit = 
    user === none ? IO.noOp :
    () => {
      commentCreateAsync.run({
        product: props.buyRequest.type ?? "gas",
        buyRequestId: props.buyRequest.id ?? "",
        user: user,
        comment: comment.value
      })()
      comment.apply(() => '')()
    }


  const now = useNow()

  const closesOn = props.lastProcess?.closeConsultsDate

  const openForComments = closesOn === none || now < closesOn

  const commentsEnabled = 
    isSeller &&
    !(buyRequest.status?.type == 'deserted' || buyRequest.status?.type == 'assigned') && 
    openForComments

  return <FlexCol
    alignItems='stretch'
  >

    {showIf(!openForComments)(
      <Alert variant='warning'>
        No se pueden realizar más consultas
      </Alert>
    )}

    {
      showIf(commentsEnabled)(
        <FlexCol
          alignItems='stretch'
          gap={8}
        >
          <h6>Realizar Consulta</h6>
          {showIf(closesOn !== none)(
            <Alert variant='info'>
              Se pueden realizar consultas hasta el {dateToNiceFormat(closesOn)}
          </Alert>
          )}
          <Form.Control
            placeholder='Hacer una consulta'
            as="textarea"
            rows={3}
            value={comment.value}
            onChange={event => comment.apply(() => (event.target as any).value)()}
          />
          <Button 
            style={{
              alignSelf: 'flex-end'
            }}
            disabled={comment.value === ""}
            onClick={onSubmit}
          >
            Enviar Consulta
          </Button>
        </FlexCol>
      )
    }

  </FlexCol>
}


const CountDown = (
  props: {
    process: Process
  }
) => {

  const renderer = (
    { days, hours, minutes, seconds, completed }: {
      days: number
      hours: number
      minutes: number
      seconds: number
      completed: boolean
    }
  ) => {
    if (!completed) {
      const dias = days == 1 ? `1 día` : (days > 1 ? days + ` días` : null)
      const goRed = (!days && hours == 0 && minutes < 5) ? 'danger' : 'success'
      return <Alert variant={goRed}>
        <Alert.Heading>
          <span>{dias} {zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}</span>
        </Alert.Heading>
      </Alert>
    } else {
      return <Alert variant={"danger"}>
        <Alert.Heading>
          <span>La Ronda Ha Cerrado</span>
        </Alert.Heading>
      </Alert>
    }
  }

  return <>
    <hr />
    <Row className='justify-content-center'>
      <Col sm={6}>
        <Countdown date={props.process.closeDate} renderer={renderer} />
      </Col>
    </Row>
  </>
}


const FileCard = (
  props: {
    file: FileData
  }
) => 
  <Col sm={3}>
    <a target="_blank" href={props.file.link}>
      <Card className="justify-content-center text-center">
        <Card.Img variant="top" src={props.file.image}
          style={{ maxHeight: 120, width: 120, margin: '0 auto', float: 'none' }} />
        <Card.Body>
          <Row className='justify-content-center'>{props.file.name}</Row>
        </Card.Body>
      </Card>
    </a>
  </Col>


const OfferFileCard = (
  props: {
    file: FileData
    keyValue: string | number
  }
) => 
  <Row className='justify-content-center'>
    <a target="_blank" href={props.file.link} key={props.keyValue}>{props.file.name}</a>
  </Row>


const GasConditions = (
  props: {
    process: Process
    buyRequest: GasBuyRequest
  }
) => {
  const process = props.process
  return <FlexCol>
    {
      process.defineMaxPrice &&
        <FlexRow
          gap={6}
        >
          <a className='text-secondary'>
            Precio máximo: <b>{process.maximumPrice?.toFixed(2)}
            </b> USD/MMBTU
          </a>
        </FlexRow>
    }
    {
      process.type == "tender" && process.acceptsDifferentPayTerm &&
        <FlexRow
          gap={6}
        >
          <a className='text-secondary'>Se aceptan Ofertas con distinto plazo de pago</a>
          <a className='text-success'> <CheckCircleOutlineIcon /></a>
        </FlexRow>
    }
    {
      process.defineMinPayTerm &&
        <FlexRow
          gap={6}
        >
          <a className='text-secondary'>Plazo de pago mínimo: <b>{process.minimumPayTermDays}</b> días</a>
        </FlexRow>
    }
    {
      process.type == "tender" && props.buyRequest.acceptsLessVolume &&
        <FlexRow
          gap={6}
        >
          <a className='text-secondary'>Se Acepta reducción de Volumen </a>
          <a className='text-success'> <CheckCircleOutlineIcon /></a>
        </FlexRow>
    }
  </FlexCol>
}


export const RoundMessagePanel = (
  props: {
    buyRequest: BuyRequestDraft
    processList: List<Partial<Process>>
  }
) => {

  const buyRequest = props.buyRequest

  const parts: List<React.ReactNode> = pipe([
    buyRequest.type === "gas" && (buyRequest?.multiPointMessage ?? false) ? (
      <>
      <Row className='justify-content-center'>
        <Col className={'text-left'} xs={11}>
          <ul>
            <li>Pedido realizado en forma conjunta por {buyRequest?.multiplePointBusinessNameText}</li>
            <li>Se deberá presentar una única Oferta para la sumatoria de las demandas. Ver debajo el detalle de cada punto de suministro y curva de demanda por Comprador.</li>
            <li> El oferente adjudicado negociará con cada Comprador la suscripción de su correspondiente contrato de abastecimiento.</li>
          </ul>
        </Col>
      </Row>
      <hr />
      </>
    ) : none,
    ...props.processList.map(process => 
      showIf(process.message !== none)(
        <React.Fragment key={process.id} >
          <Row className='justify-content-center'>
            <Col xs={4} md={2}>
              Ronda {process.round}:
            </Col>
            <Col>
              {process.message}
            </Col>
          </Row>
          <hr />
        </React.Fragment>
      )
    )
  ])(
    List.filterNotNone
  )

  if (parts.length === 0) return null

  return <ExpandableCard
    title='Comentarios e información adicional del Pedido'
  >
    {parts}
  </ExpandableCard>
}


const ExpandableCard = (
  props: {
    title?: string
    initialState?: boolean
    children?: React.ReactNode
  }
) => {

  const open = useRState(() => props.initialState ?? false)

  const CollapseIcon =
    open.value ? ExpandLess : ExpandMore

  return <Card className='shadow text-secondary'>

    <FlexRow 
      justifyContent='space-between'
      alignItems='center'
      padding={16}
    >
      <div
        style={{
          width: 48
        }}
      />
      <h5
        style={{ alignSelf: "center" }}
      >
        {props.title}
      </h5>
      <IconButton
        style={{
          width: 48
        }}
        onClick={open.apply(it => !it)}
      >
        <CollapseIcon/>
      </IconButton>
      
    </FlexRow>

    <Collapse in={open.value}>
      <Card.Body
        style={{
          overflow: 'auto'
        }}
      >
        {props.children}
      </Card.Body>
    </Collapse>
  </Card>
}

const ConfidencialityAgreementCard = () => 
  <>
    <Card>
      <br />
      <Row className='justify-content-center'>
        <h4 className='text-secondary'>Acuerdo de Confidencialidad Pendiente</h4>
        <h6 style={{ width: '75%' }} className='text-secondary'>Para acceder a los Documentos del Pedido de Compra se requiere la firma de un Acuerdo de Confidencialidad.</h6>
        <hr style={{ width: '80%' }} />
      </Row>
      <Row className='justify-content-center'>
        <h6 className='text-secondary'>¿No recibiste el mail con el Acuerdo?</h6>
      </Row>
      <Row className='justify-content-center'>
        <h6 className='text-secondary'>Escribinos a <a href="mailto:info@conectoenergia.com">info@conectoenergia.com</a></h6>
      </Row>
    </Card>
    <br />
  </>